import request from "../composables/request";
/**
 * 注册
 * @param host  当前ip
 * @param username  邮箱号
 * @param phonenumber  手机号
 * @param code  邮箱收取的验证码
 * @param uuid 获取邮箱验证码时返回的uuid
 * @param nickName  页面上的displayName
 * @param firstName  
 * @param lastName  
 * @returns 
 */
export const register = (params: { 
    host?: string,
    username: string,
    phonenumber?: string,
    code: string,
    uuid: string,
    nickName: string,
    firstName: string,
    lastName: string
 }) => {
    return  request.post('/register',params);
  }