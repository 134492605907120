import React, { useState, useEffect } from 'react';
import { Select, Spin } from 'antd';
import type { SelectProps } from 'antd';

interface UserValue {
    label: string;
    value: string;
}

export interface BasicSelectProps<ValueType = any>
    extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
    fetchOptions: (search: string) => Promise<ValueType[]>;
    onSelectionChange?: (value: ValueType | ValueType[]) => void;
    resetTrigger?: boolean; // 添加 resetTrigger 属性
    value?: ValueType | ValueType[]; // 添加 value 属性
}

const BasicSelect: React.FC<BasicSelectProps<UserValue>> = ({ fetchOptions, onSelectionChange, resetTrigger, value, ...props }) => {
    const [options, setOptions] = useState<UserValue[]>([]);
    const [fetching, setFetching] = useState(false);

    // 当 resetTrigger 变化时，重置选项和选择的值
    useEffect(() => {
        setOptions([]);
    }, [resetTrigger]);

    const handleSearch = async (value: string) => {
        setFetching(true);
        try {
            const results = await fetchOptions(value);
            setOptions(results);
        } catch (error) {
            setOptions([]);
            console.error('Error fetching options:', error);
        } finally {
            setFetching(false);
        }
    };
    //选择数据
    const handleChange = (value: UserValue | UserValue[]) => {
        if (onSelectionChange) {
            onSelectionChange(value);
        }
    };

    return (
        <Select
            showSearch
            labelInValue
            filterOption={false}
            onSearch={handleSearch}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            onChange={handleChange}
            {...props}
            options={options}
            value={value} // 使用外部传入的 value
        />
    );
};

export default BasicSelect;
