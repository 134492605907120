// 路由配置
import { Navigate } from "react-router-dom";
import Home from "../views/home";
import Login from "../views/login";
import Register from "../views/register";
import EventsActivities from "../views/index/eventsActivities"
import EventsActivitiesAddOrEdit from "../views/index/eventsActivities/addOrEdit"
import EventsActivitiesHome from "../views/index/eventsActivities/home"
import EventsActivitiesDetails from "../views/index/eventsActivities/details"
import ConversationalHub from "../views/index/conversationalHub"
import ConversationalHubHome from "../views/index/conversationalHub/home";
import Notification from "../views/index/notification"
import Index from "../views/index"
import HealthOnline from "../views/index/healthOnline";
import HealthOnlineHome from "../views/index/healthOnline/home";
import HealthOnlineAddOrEdit from "../views/index/healthOnline/addOrEdit";
import HealthOnlineDetails from "../views/index/healthOnline/details"
import MySubmissions from "../views/index/healthOnline/mySubmissions"
import Gaming from "../views/index/gaming";
import GamingHome from "../views/index/gaming/home";
import GamingDetails from "../views/index/gaming/details"
import GamingAddOrEdit from '../views/index/gaming/addOrEdit'
import PersonalCenter from '../views/index/personalCenter'


const routes = [
  {
    path: '/home',
    element: <Navigate to="/" />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/register',
    element: <Register />
  },
  {
    path: '/',
    element: <Home />
  },
  {
    // path: '/index',
    element: <Index />,
    children: [
      {
        path: 'healthOnline',
        element: <HealthOnline />,
        children: [
          {
            index: true,
            element: <Navigate to="home" />
          },
          {
            path: 'home',
            element: <HealthOnlineHome />
          },
          {
            path: 'details',
            element: <HealthOnlineDetails />
          },
          {
            path: 'addOrEdit',
            element: <HealthOnlineAddOrEdit />
          },
          {
            path: 'mySubmissions',
            element: <MySubmissions />
          }
        ]
      },
      {
        path: 'gaming',
        element: <Gaming />,
        children: [
          {
            index: true,
            element: <Navigate to="home" />
          },
          {
            path: 'home',
            element: <GamingHome />
          },
          {
            path: 'details',
            element: <GamingDetails />
          },
          {
            path: 'addOrEdit',
            element: <GamingAddOrEdit />
          }
        ]
      },
      {
        path: 'eventsActivities',
        element: <EventsActivities />,
        children: [
          {
            index: true,
            element: <Navigate to="home" />
          },
          {
            path: 'home',
            element: <EventsActivitiesHome />,
          },
          {
            path: 'details',
            element: <EventsActivitiesDetails />,
          },
          {
            path: 'addOrEdit',
            element: <EventsActivitiesAddOrEdit />,
          }
        ]
      },
      {
        path: 'conversationalHub',
        element: <ConversationalHub />,
        children: [
          {
            index: true,
            element: <Navigate to="home" />
          },
          {
            path: 'home',
            element: <ConversationalHubHome />
          },
        ]
      },
      {
        path: 'notification',
        element: <Notification />
      },
      {
        path: 'personalCenter',
        element: <PersonalCenter />
      },
    ]
  },
];

export default routes;
