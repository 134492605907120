import ReactDOM from 'react-dom/client';
import { useEffect } from 'react';
import './index.css';
import "./assets/style/global.scss";
import "./assets/style/tailwind.scss";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import App from './App';
import { setupInterceptors } from './composables/request';
import userStore from "./store/user"
import { initializeWebSocket } from './utils/websocketManager';
import { onmessage } from './utils/websocketManager'
// 创建根渲染器
const root = ReactDOM.createRoot(document.getElementById('root')!);

// 在页面加载时设置拦截器的组件
function SetupInterceptorsOnLoad() {
  const navigate = useNavigate();

  useEffect(() => {
    // 在组件加载时执行一次，设置请求拦截器
    setupInterceptors(navigate);
  }, [navigate]); // 仅在 navigate 变化时重新设置拦截器
  const { token } = userStore.state;
  useEffect(() => {
    if (token) {
      initializeWebSocket(); // 初始化 WebSocket 连接
    }
    // 注册消息处理回调函数
    onmessage(()=>{})
  }, [token]);
  return null; // SetupInterceptorsOnLoad 组件不需要渲染任何内容，只需执行副作用

}
// 使用根渲染器渲染应用
root.render(
  <BrowserRouter>
    <SetupInterceptorsOnLoad /> {/* 在 BrowserRouter 内部使用拦截器设置组件 */}
    <App /> {/* 渲染主应用组件 */}
  </BrowserRouter>
);

// 报告 Web Vitals（性能指标）
reportWebVitals();
