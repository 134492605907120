import { useRoutes } from 'react-router-dom';
import './App.css';
import routes from './router';
import { observer } from 'mobx-react-lite';
// 定义组件
const App = observer(() => {
  const outlet = useRoutes(routes);
  return (
    <div className="App">
      {outlet}
    </div>
  );
});

export default App;
