import { useState, useEffect, useCallback } from 'react';
import { Button, Modal } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import All from '../../../components/HealthOnline/All';
import HeadNavigation from '../../../store/HeadNavigation';
import dictStore from '../../../store/dict';
import { observer } from 'mobx-react-lite';
import indexStore from '../../../store/index';
import { getDictDataList } from '../../../api/dict';
import userStore from '../../../store/user'

const IndexHealthOnline = observer(() => {
    const navigate = useNavigate();
    const [activeNav, setActiveNav] = useState<string | null>(null);
    const [moduleType] = useState<number>(indexStore.menusState.moduleType);
    // 初始化字典数据列表，包含 "All" 和 "My favorites"
    const [dictDataList, setDictDataList] = useState<any[]>([
        { dictCode: null, dictLabel: 'All' },
        { dictCode: 'myFavorites', dictLabel: 'My favorites' }
    ]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // 显示模态框
    const showModal = () => {
        setIsModalOpen(true);
    };

    // 关闭模态框
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // 导航点击事件处理
    const navigateClick = (val: string) => {
        setActiveNav(val);
    };

    // 根据导航类型跳转
    const navigationTypeJump = (dictCode: number) => {
        navigate(`/healthOnline/addOrEdit?type=add&buildType=${dictCode}`);
    }
    // 通用获取数据函数
    const fetchData = useCallback(async (dictType: string, setDataFn: (data: any) => void, errorMsg: string) => {
        try {
            const res = await getDictDataList({ dictType });
            setDataFn([...res.data.rows]);
        } catch (error) {
            console.error(errorMsg, error);
        }
    }, []);

    // 获取文章类型数据
    const fetchArticleType = async () => {
        await fetchData('article_type', dictStore.setArticleType.bind(dictStore), '获取文章类型时出错:');
    };

    // 获取文章标签数据
    const fetchArticleTags = async () => {
        await fetchData('article_tags', dictStore.setArticleTags.bind(dictStore), '获取标签类型时出错:');
    };

    // 监听分类数据变化并更新字典数据列表
    useEffect(() => {
        if (dictStore.state.categoryData) {
            setDictDataList(prevList => [
                ...prevList.slice(0, 2),
                ...dictStore.state.categoryData
            ]);
        }
    }, [dictStore.state.categoryData]);

    // 页面初始化获取文章类型和标签数据
    useEffect(() => {
        fetchArticleType();
        fetchArticleTags();
    }, [fetchData]);

    // 根据模块类型获取分类数据
    useEffect(() => {
        const fetchCategoryData = async () => {
            const dictType = moduleType === 1 ? 'article_category' : 'online_category';
            await fetchData(dictType, dictStore.setCategoryData.bind(dictStore), '获取分类数据时出错:');
        };

        fetchCategoryData();
    }, [moduleType, fetchData]);
    useEffect(() => {
        const currentName = moduleType === 1 ? 'Health & wellness' : 'Online classes';
        HeadNavigation.setItem({ backTo: '/home', goName: 'Home', currentName });
    }, []);
    return (
        <div className="w-full h-full flex">
            <div className="w-80 h-full flex flex-col justify-between overflow-auto border-r border-[#D2D2D2] py-4 space-y-3">
                <div>
                    {dictDataList.map((item, index) => (
                        <div key={index}
                            className={`w-full h-16 border-r-4 hover:bg-[#F6F8F8] cursor-pointer flex items-center pl-16 space-x-2 
                                ${activeNav === item.dictCode ? 'border-[#275576] bg-[#F6F8F8] text-[#232323]' : 'border-white text-[#707070]'}`}
                            onClick={() => navigateClick(item.dictCode)}>
                            {
                                index > 8 ? (
                                    <img src={require(`../../../assets/images/${moduleType === 1 ? 'healthWellness' : 'onlineClasses'}/8.svg`)} alt={item.index} />
                                ) : (
                                    <img src={require(`../../../assets/images/${moduleType === 1 ? 'healthWellness' : 'onlineClasses'}/${index + 1}.svg`)} alt={item.index} />
                                )
                            }
                            <span className="text-xl font-normal ">{item.dictLabel}</span>
                        </div>
                    ))}
                </div>
                <div className='w-full pl-14 pr-6 pb-4 flex flex-col space-y-3'>
                    {/* class online 只有管理员可以新增 */}
                    {(moduleType === 1 || userStore.state.userInfo.userType === '00') &&
                        <Button shape="round"
                            style={{ background: '#275576', borderColor: '#275576' }}
                            className='w-full h-11 flex items-center justify-center space-x-2'
                            onClick={showModal}>
                            <img src={require('../../../assets/images/public/send-01.png')} alt='send' />
                            <span className='text-base font-medium text-white'>Submit new content</span>
                        </Button>
                    }
                    <Link to='/healthOnline/mySubmissions'>
                        <Button shape="round"
                            style={{ background: 'none', borderColor: '#275576' }}
                            className='w-full h-11 flex items-center justify-center space-x-2'>
                            <img src={require('../../../assets/images/public/file.png')} alt='file' />
                            <span className='text-base font-medium text-[#275576]'>My submissions</span>
                        </Button>
                    </Link>
                </div>
            </div>
            <div className="flex-1 h-full mt-4 ml-7 pb-5 mr-16 overflow-auto">
                <All category={activeNav} />
            </div>
            <Modal title="Which type of content you are going to submit?" centered open={isModalOpen}
                footer={null}
                width={700}
            >
                <div className=' grid grid-cols-3 justify-between my-7'>
                    {dictStore.state.article_type.map((tag_item: any, tag_index) => (
                        <div key={tag_index} className='flex flex-col items-center py-5  space-y-3 cursor-pointer'
                            onClick={() => navigationTypeJump(tag_item.dictCode)}
                            style={tag_index === 1 ? { borderRight: '1px solid #D2D2D2', borderLeft: '1px solid #D2D2D2' } : {}}>
                            <img className=' w-8 h-8' src={require(`../../../assets/images/healthWellness/${tag_item.dictLabel}.svg`)} alt={`${tag_item.dictLabel}`} />
                            <span className=' text-2xl font-normal text-[#232323]'>{tag_item.dictLabel}</span>
                        </div>
                    ))}
                </div>
                <Button shape="round"
                    style={{ background: '#E9EEF1' }}
                    className='w-full h-11'
                    onClick={handleCancel} >
                    <span className=' text-base font-medium text-[#275576] '>Cancel</span>
                </Button>
            </Modal>
        </div>
    )
})
export default IndexHealthOnline