import { useState, useEffect } from 'react';
import Comments from "../../../components/EventsActivities/Comments"
import dictStore from '../../../store/dict';
import { attendActivity, getActivityInfoDetails } from '../../../api/activity'
import { message } from 'antd';
import dayjs from 'dayjs'
import HeadNavigation from '../../../store/HeadNavigation';
import { useLocation } from 'react-router-dom';
import ShareWith from '../../../components/Public/ShareWith';

const Details = () => {
    const location = useLocation();
    // 详情数据
    const [itemDetails, setItemDetails] = useState<any>();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const [isAttend, setIsAttend] = useState<number>(itemDetails?.isAttend)
    // 通过dict获取标签的函数
    const getDictLabel = (type: number) => {
        const dictItem = dictStore.state.activity_category.find((item: any) => item.dictCode === type) || { dictLabel: '' };
        return dictItem.dictLabel;
    }
    // 通过dict获取标签的函数
    const getActivityTypeDictLabel = (type: number) => {
        const dictItem = dictStore.state.activity_type.find((item: any) => item.dictCode === type) || { dictLabel: '' };
        return dictItem.dictLabel;
    }
    // 参加/退出活动
    const attendActivityFn = async () => {
        try {
            const res = await attendActivity(itemDetails.id)
            if (res.data.code === 200) {
                isAttend === 1 ? setIsAttend(2) : setIsAttend(1)
                message.success(res.data.msg)
            }
        } catch (error) {
            console.log(error)
        }
    }
    // 获取详情
    const getActivityInfoDetailsFn = async () => {
        try {
            const res = await getActivityInfoDetails(Number(id))
            if (res.data.code === 200) {
                const {isAttend}=res.data.data
                setItemDetails(res.data.data)
                setIsAttend(isAttend)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getActivityInfoDetailsFn()
        HeadNavigation.setItem({ backTo: -1, goName:'Previous step', currentName: 'Events & Activities' });
    }, []);
    return (
        <div className='w-full h-full'>
            {itemDetails &&
                <div className="w-full h-full flex flex-col">

                    <div className="w-full h-16 bg-[#EFDA76] flex items-center space-x-5 px-14">
                        <span className='text-xl font-medium'>
                            {dayjs(itemDetails.startTime).format('dddd, MMMM D, YYYY h:mm A')}
                        </span>
                        {itemDetails.pcount - itemDetails.attendCount > 0 && (
                            <span className='text-[#EA5E5E]'>
                                {itemDetails.pcount - itemDetails.attendCount} spots left
                            </span>
                        )}
                    </div>
                    <div className="w-full flex-1 px-14 my-5 space-y-6 overflow-auto">
                        <div className="w-full h-[28rem] border border-[#ccc] rounded-md overflow-hidden">
                            <img className='w-full h-full object-cover'
                                src={itemDetails.bannerPath} alt="placeholder" />
                        </div>
                        <div className=" space-y-3">
                            <div className=' flex items-center justify-between'>
                                <span className="text-4xl font-medium text-[#232323]">{itemDetails.title}</span>
                             <div className='flex items-center space-x-12'>
                             <ShareWith />
                                {isAttend === 2 && <div className='bg-[#EA5E5E] flex items-center px-6 py-3 rounded-full space-x-2 cursor-pointer'
                                    onClick={attendActivityFn}
                                >
                                    <img src={require('../../../assets/images/public/globe.png')} alt='globe' />
                                    <span className='text-white text-base font-medium'>Attend</span>
                                </div>
                                }
                                {isAttend === 1 && <div className='border border-[#275576] flex items-center px-6 py-3 rounded-full space-x-2 cursor-pointer'
                                    onClick={attendActivityFn}
                                >
                                    <img src={require('../../../assets/images/public/calendar.png')} alt='calendar' />
                                    <span className='text-[#275576] text-base font-medium'>Remove from my calendar</span>
                                </div>
                                }
                             </div>
                            </div>
                            <div className="flex items-center space-x-1">
                                <img className='w-5 h-5' src={require('../../../assets/images/eventsActivities/marker.png')} alt='marker' />
                                <span className='text-xl font-normal text-[#707070]'>{itemDetails.address}</span>
                            </div>
                            <div className=" text-base font-medium text-[#232323] flex items-center space-x-3">
                                <span className=" px-3 py-1 border border-[#DEDEDE] rounded-2xl">
                                    {getDictLabel(itemDetails.category)}
                                </span>
                                {getActivityTypeDictLabel(itemDetails.type) && <span className=" px-3 py-1 border border-[#DEDEDE] rounded-2xl">
                                    {getActivityTypeDictLabel(itemDetails.type)}
                                </span>
                                }
                            </div>
                        </div>
                        <div className=" text-xl font-normal text-[#707070]">
                            {itemDetails.description}
                        </div>
                        <div className="w-full h-[30rem] overflow-hidden">
                            <Comments id={itemDetails.id} />
                        </div>
                    </div>

                </div >
            }
        </div>
    )
}
export default Details