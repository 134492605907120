const Culinary = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
            <mask id="mask0_983_8801" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                <rect x="0.5" y="0.199951" width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_983_8801)">
                <path d="M8.00002 17.7V15.2H2.16669V13.5333H8.00002C8.45835 13.5333 8.85072 13.6965 9.1771 14.0229C9.50349 14.3493 9.66669 14.7416 9.66669 15.2V17.7H8.00002ZM11.3334 17.7V15.2C11.3334 14.7416 11.4965 14.3493 11.8229 14.0229C12.1493 13.6965 12.5417 13.5333 13 13.5333H18.8334V15.2H13V17.7H11.3334ZM5.50002 12.7C4.80558 12.7 4.2153 12.4569 3.72919 11.9708C3.24308 11.4847 3.00002 10.8944 3.00002 10.2V6.86662H18V10.2C18 10.8944 17.757 11.4847 17.2709 11.9708C16.7847 12.4569 16.1945 12.7 15.5 12.7H5.50002ZM5.50002 11.0333H15.5C15.7361 11.0333 15.934 10.9534 16.0938 10.7937C16.2535 10.634 16.3334 10.4361 16.3334 10.2V8.53328H4.66669V10.2C4.66669 10.4361 4.74655 10.634 4.90627 10.7937C5.06599 10.9534 5.26391 11.0333 5.50002 11.0333ZM3.00002 6.03328V4.36662H8.00002V3.53328C8.00002 3.29717 8.07988 3.09926 8.2396 2.93953C8.39933 2.77981 8.59724 2.69995 8.83335 2.69995H12.1667C12.4028 2.69995 12.6007 2.77981 12.7604 2.93953C12.9202 3.09926 13 3.29717 13 3.53328V4.36662H18V6.03328H3.00002Z"
                fill="currentColor" />
            </g>
        </svg>
    )
}
export default Culinary