import request from "../composables/request";
/**
 * 分页获取游戏信息
 * @param current 页码
 * @param size 条数
 * @param classificationId 类型id，如果是全部类型则不传这个参数，传空也行
 * @param title 标题
 * @returns 
 */
export const getPagelmGame = (params: {
  current: number,
  size: number,
  classificationId?: number | null,
  title?: string,
}) => {
  return request.get('/api/personal/game/public/pageImGame', { params });
}
/**
 * 获取我的，所有游戏信息
 * type 类型：0：参与 1：收藏
 */
export const getListMyFavorites = (type: number = 1) => {
  return request.get(`/api/personal/game/private/listMyFavorites?type=${type}`);
}
/**
 * 获取我的，分页游戏信息
 * @param current 页码
 * @param size 条数
 * @param type 类型：0：参与 1：收藏
 * @param title 标题
 * @returns 
 */
export const getPageMyFavorites = (params: {
  current: number,
  size: number,
  type: number,
  title?: string,
}) => {
  return request.get('/api/personal/game/private/pageMyFavorites', { params });
}
/**
 *  收藏/取消收藏 游戏
 * @param gameId 游戏id
 * @returns 
 */
export const collectionGame = (gameId: number) => {
  return request.post(`/api/personal/game/private/collectionGame?gameId=${gameId}`);
}
/**
 * 获取游戏类型
 */
export const getListClassification = () => {
  return request.get('/api/personal/game/public/listClassification');
}
/**
 * 新增游戏
 * @param classificationId  类型id
 * @param content 内容 
 * @param description  描述
 * @param filePath 文件路径
 * @param participateInCount  参与次数
 * @param title  标题
 * @param params 
 * @returns 
 */
export const addGame = (params: {
  title: string,
  description: string,
  content: string,
  classificationId: number,
  participateInCount: number,
  filePath: string
}) => {
  return request.post('/api/personal/game/private/addGame', params);
}
/**
 * 修改游戏
 * @param id 
 * @param title 
 * @param description 
 * @param content 
 * @param classificationId 
 * @param participateInCount 
 * @param filePath 
 * @returns 
 */
export const updateGame = (params: {
  id: number,
  title: string,
  description: string,
  content: string,
  classificationId: number,
  participateInCount: number,
  filePath: string
}) => {
  return request.put('/api/personal/game/private/updateGame', params);
}
/**
 * 删除游戏
 * @param arr 
 * @returns 
 */
export const delGame = (id: number) => {
  return request.put(`/api/personal/game/private/delGame?id=${id}`);
}
/**
 * 获取游戏详情
 * @param id 
 * @returns 
 */
export const getGameDetails = (id: number) => {
  return request.get(`/api/personal/game/public/getById?id=${id}`);
}

/**
 * 添加游戏评论
 * @param content 评论内容
 * @param gameId 游戏ID
 * @returns 
 */
export const gameAddComment = (params: {
  content: string,
  gameId: number,
}) => {
  return request.post('/api/personal/game/private/postComments', params);
}
/**
* 游戏点赞/点赞取消
* @param gameId ID
* @returns 
*/
export const gameCommentStar = (gameId: number) => {
  return request.get(`/api/personal/game/public/listComments?gameId=${gameId}`);
}