import React, { useState, useEffect, useRef } from 'react';
import { Input, Spin, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import CardItem from './CardItem';
import { getActivityPage, getMyAttend } from '../../api/activity';
import dictStore from '../../store/dict';
import type { DatePickerProps } from 'antd';
interface GamingAllProps {
    category: number | null | string;
}
const HealthOnlineAll: React.FC<GamingAllProps> = ({ category }) => {
    // 状态声明
    const selectedTab = useRef<string | null>(null);
    const [searchValue, setSearchValue] = useState<string>(''); // 搜索关键词
    const searchTime = useRef<any>('');
    const [loading, setLoading] = useState<boolean>(false); // 加载状态
    const hasMore = useRef<boolean>(true); // 是否还有更多数据可加载
    const containerRef = useRef<HTMLDivElement>(null); // 容器的引用，用于监听滚动事件
    const currentPage = useRef<number>(1) //页码
    const dataList = useRef<any[]>([]) //数据列表
    const [articleType, setArticleType] = useState<any[]>([
        { dictCode: null, dictLabel: 'All' },
        ...dictStore.state.activity_type
    ]);
    // 获取活动列表数据
    const getActivityPageFn = async (title?: string) => {
        try {
            setLoading(true); // 开始加载数据
            const param: any = { current: currentPage.current, size: 20, title };
            if (category !== 'myCalendar') {
                param.category = category;
                param.type = selectedTab.current;
            } else { param.time = searchTime.current }

            const apiCall = category === 'myCalendar' ? getMyAttend : getActivityPage;
            const res = await apiCall(param);
            setLoading(false); // 数据加载完成
            if (res.data.code === 200) { // 如果请求成功
                dataList.current = [...dataList.current, ...res.data?.data.records]; // 更新数据列表
                currentPage.current = currentPage.current + 1; // 更新页码

                // 判断是否还有更多数据可加载
                if (res.data.data.total === dataList.current.length) {
                    hasMore.current = false; // 没有更多数据了
                }
            }
        } catch (error) {
            console.log(error, 'error');
            setLoading(false); // 停止加载状态
        }
    };
    // 时间更改
    const searchTimeChange: DatePickerProps['onChange'] = (date, dateString) => {
        searchTime.current = dateString
        getActivityPageFn(searchValue)
    };
    // 切换tab
    const handleTabClick = async (tab: any) => {
        selectedTab.current = tab
        searchTime.current = ''
        setSearchValue('')
        resetHandle()
        getActivityPageFn('');
    };
    // 处理滚动事件
    const handleScroll = () => {
        if (!containerRef.current) return; // 如果容器不存在，直接返回
        const { scrollTop, clientHeight, scrollHeight } = containerRef.current; // 获取容器的滚动位置信息
        // 当滚动到底部且还有更多数据时，加载更多信息
        if (scrollHeight - scrollTop === clientHeight && hasMore.current && currentPage.current !== 1) {
            getActivityPageFn(searchValue); // 加载更多信息
        }
    }

    // 重置
    const resetHandle = async () => {
        currentPage.current = 1; // 搜索时重置页码为1
        hasMore.current = true; // 重置是否有更多数据的状态
        dataList.current = []; // 清空当前数据列表
    }

    // 根据标题搜索 回车搜索
    const handleSearch = () => {
        resetHandle()
        getActivityPageFn(searchValue); // 根据搜索关键词获取信息
    };

    // 当category改变时重新设置标签类型和获取活动数据
    useEffect(() => {
        const fetchData = async () => {
            await resetHandle()
            await setSearchValue('')
            selectedTab.current = null; // 重置选中标签为null
            // 根据category设置标签类型
            if (category === 'myCalendar') {
                setArticleType([
                    { dictCode: null, dictLabel: 'All classes' }, // “全部课程”标签
                ]);
            } else {
                setArticleType([
                    { dictCode: null, dictLabel: 'All' }, // “全部”标签
                    ...dictStore.state.activity_type // 其他从字典存储中获取的标签类型
                ]);
            }
            getActivityPageFn(''); // 获取所有信息
        };
        fetchData();
    }, [category]); // 依赖于category和获取活动数据的回调函数

    // 当字典存储中的标签类型改变时更新articleType
    useEffect(() => {
        setArticleType([
            { dictCode: null, dictLabel: 'All' }, // “全部”标签
            ...dictStore.state.activity_type // 其他从字典存储中获取的标签类型
        ]);
    }, [dictStore.state.activity_type]); // 依赖于字典存储中的标签类型
    return (
        <div className="w-full h-full flex flex-col space-y-6 overflow-hidden">
            <div className="flex justify-between items-center">
                <ul className="flex items-center space-x-7 text-xl font-normal text-[#707070]">
                    {articleType.map((tab, index) => (
                        <li
                            key={index}
                            onClick={() => handleTabClick(tab.dictCode)}
                            className={`pb-5 cursor-pointer border-b-2 duration-500 ${selectedTab.current === tab.dictCode
                                ? 'border-[#232323] text-[#232323]'
                                : 'border-white'
                                }`}
                        >
                            {tab.dictLabel}
                        </li>
                    ))}
                </ul>
                <div className=' flex space-x-2'>
                    {/* 暂时保留按日期搜索 */}
                    {selectedTab.current === 'dayView' &&
                        <DatePicker className=' rounded-full w-36 h-12 ' format="YYYY-MM-DD"
                            onChange={searchTimeChange} />
                    }
                    {/* Search input */}
                    <Input
                        className="w-64 h-12 rounded-full bg-[#F1F1F1]"
                        placeholder="Search events"
                        suffix={<SearchOutlined style={{ color: '#232323', fontSize: '1.25rem' }} />}
                        value={searchValue}
                        onPressEnter={() => handleSearch()}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                </div>
            </div>

            <div className="w-full flex-1 overflow-auto space-y-6" ref={containerRef} onScroll={handleScroll}>
                <p className="text-2xl font-normal text-[#707070]">
                    {category === 'myCalendar' ?
                        'lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                        : 'Unlock Joy & Vitality: Your Gateway to Elderly Events and Activities!'}
                </p>
                <div>
                    {/* Data listing */}
                    {dataList.current && dataList.current.length > 0 ? (
                        <div className="w-full flex-1 pb-5 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5">
                            {dataList.current.map((item) => (
                                <CardItem
                                    onUpdate={() => handleSearch()}
                                    item={item} key={item.id} />
                            ))}
                        </div>
                    ) : (<div className="h-10 leading-10 text-center text-[#707070]">No data available</div>)}
                    {/* 加载状态 */}
                    <div className='flex justify-center'><Spin spinning={loading} delay={500}></Spin></div>
                </div>

            </div>
        </div>
    );
};

export default HealthOnlineAll;
