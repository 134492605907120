import { makeAutoObservable, autorun } from 'mobx';
class indexStore {
    // 菜单导航初始位置
    menusState = {
        category: null,//左侧分类
        type: null,//类型 音频，视频，文章分类
        moduleType: 1 //模块分类（模块类型（1：Health 2：online））
    }
    constructor() {
        makeAutoObservable(this);
        const storedItem = sessionStorage.getItem('menusState');
        if (storedItem) {
            this.menusState = JSON.parse(storedItem);
        }
        autorun(() => {
            sessionStorage.setItem('menusState', JSON.stringify(this.menusState));
        });
    }
    setCategory(val: any) {
        this.menusState.category = val;
    }

    setType(val: any) {
        this.menusState.type = val;
    }
    setModuleType(val: any) {
        this.menusState.moduleType = val;
    }
    clearData() {
        this.menusState = {
            category: null,//左侧分类
            type: null,//类型 音频，视频，文章分类
            moduleType: 1 //模块分类（模块类型（1：Health 2：online））
        }
    }
}

export default new indexStore();
