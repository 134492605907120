import React, { useEffect, useRef } from 'react';
import { message } from 'antd';
import wangEditor from 'wangeditor';
import { uploadFileAws } from '../../utils/aws';

interface WangEditorProps {
  initialValue?: string;
  onChange?: (content: string) => void;
  readOnly: boolean;
  keyPrefix?: string; // 新增 keyPrefix 属性
}

const WangEditorComponent: React.FC<WangEditorProps> = ({ initialValue, onChange, readOnly, keyPrefix }) => {
  const editorRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!editorRef.current) return;
    const newEditor = new wangEditor(editorRef.current);

    // 富文本编辑器是否只读 功能
    newEditor.config.menus = !readOnly
      ? [
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        'fontName', // 字体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        //'link',  // 插入链接
        'list', // 列表
        'justify', // 对齐方式
        'quote', // 引用
        'emoticon', // 表情
        'image', // 插入图片
        'table', // 表格
        //'video',  // 插入视频
        //'code',  // 插入代码
        'undo', // 撤销
        'redo' // 重复
      ]
      : [];

    // 自定义上传图片
    newEditor.config.uploadImgHooks = {
      success: function (xhr, editor, result) {
        console.log(xhr, editor, result, 'xhr, editor, result');
      },
      fail: function () {
        message.error('Upload successful, insertion failed');
      },
      error: function () {
        message.error('Upload failed, please try again');
      },
      timeout: function () {
        message.error('Upload timeout!');
      }
    };

    newEditor.config.pasteIgnoreImg = true;
    newEditor.config.customUploadImg = async (files: any, insertImgCallback: any) => {
      const file = files[0];
      const fileName = file?.name;
      const key = `${keyPrefix}/${fileName}`;
      try {
        // 调用上传文件至 Amazon S3 的函数，并传入进度更新回调
        await uploadFileAws(key, file, (progress) => {
          console.log(progress, 'progress')
          // setProgress(progress); // 更新上传进度
        });

        insertImgCallback(`https://tiksz.s3.${process.env.REACT_APP_REGION}.amazonaws.com/${key}`);
      } catch (err) {
        console.error('Upload error:', err);
      }
    };

    newEditor.create();
    newEditor.$textElem.attr('contenteditable', `${!readOnly}`);
    newEditor.txt.html(initialValue);
    if (onChange) {
      newEditor.config.onchange = (html: string) => {
        onChange(html)
      };
    }
    return () => {
      newEditor.destroy();
    };
  }, []);

  return <div ref={editorRef} className='editorEl_box w-full h-full flex flex-col' />;


};

export default WangEditorComponent;