import request from "../composables/request";
/**
 * 活动列表-分页
 * @param current 当前页 
 * @param size 页大小
 * @param category 类别
 * @param type 类型
 * @param title 标题
 * @returns 
 */
export const getActivityPage = (params: {
    current?: number,
    size?: number,
    category?: number | string | null,
    type?: number | null,
    title?: string,
}) => {
    return request.get('/api/personal/activity/public/activityPage', { params });
}
/**
 * 添加活动
 * @param title 标题
 * @param category 活动类目 字典：activity_category （左侧类目，Art & culture等）
 * @param type 活动类别 字典：activity_type （上面的类别，Online等）
 * @param filePath 文件地址
 * @param bannerPath banner路径
 * @param description 描述
 * @param address 地址
 * @param startTime 开始时间
 * @param endTime 结束时间
 * @param commentStatus 评论状态 1：显示 2：隐藏 3：上锁
 * @param status  活动状态 1：draft，2：under view, 3,：ready，4：published
 * @param pcount 设置参与人数
 * @returns 
 */
export const addActivity = (params: {
    title: string,
    category: number,
    type: number,
    filePath?: string,
    bannerPath: string,
    description: number,
    address: string,
    startTime: string,
    endTime: string,
    commentStatus: number,
    status: number,
    pcount:number
}) => {
    return request.post('/api/personal/activity/private/addActivity', params);
}
/**
 * 添加活动
 * @param id id
 * @param title 标题
 * @param category 活动类目 字典：activity_category （左侧类目，Art & culture等）
 * @param type 活动类别 字典：activity_type （上面的类别，Online等）
 * @param filePath 文件地址
 * @param bannerPath banner路径
 * @param description 描述
 * @param address 地址
 * @param startTime 开始时间
 * @param endTime 结束时间
 * @param commentStatus 评论状态 1：显示 2：隐藏 3：上锁
 * @param status  活动状态 1：draft，2：under view, 3,：ready，4：published
 * @param pcount 设置参与人数
 * @returns 
 */
export const updateActivity = (params: {
    id:number,
    title: string,
    category: number,
    type: number,
    filePath?: string,
    bannerPath: string,
    description: number,
    address: string,
    startTime: string,
    endTime: string,
    commentStatus: number,
    status: number,
    pcount:number
}) => {
    return request.put('/api/personal/activity/private/updateActivity', params);
}

/**
* 删除活动
* @param arr 
* @returns 
*/
export const delActivity = (arr: any) => {
    return request.delete(`/api/personal/activity/private/delActivity`, { data: arr });
}
/**
 * 评论列表（分页）
 * @param activityId 
 * @returns 
 */
export const commentPage = (activityId: number) => {
    return request.get(`/api/personal/activity/public/commentPage?activityId=${activityId}`);
}
/**
 * 添加活动评论
 * @param comment 评论内容
 * @param activityId 活动ID
 * @returns 
 */
export const activityAddComment = (params: {
    comment: string,
    activityId: number,
}) => {
    return request.post('/api/personal/activity/private/addComment', params);
}
/**
 * 活动点赞/点赞取消
 * @param activityId 活动ID
 * @returns 
 */
export const commentStar = (commentId: number) => {
    return request.put('/api/personal/activity/private/commentStar', { commentId });
}
/**
* 参加/退出活动
* @param activityId 活动ID
* @returns 
*/
export const attendActivity = (activityId: number) => {
    return request.put('/api/personal/activity/private/attendActivity', { activityId });
}
/**
 * 我参与的活动
 * @param current 当前页 
 * @param size 页大小
 * @param time 活动得时间
 * @param title 标题
 * @returns 
 */
export const getMyAttend = (params: {
    current?: number,
    size?: number,
    time?: string,
    title?: string,
}) => {
    return request.get('/api/personal/activity/private/myAttend', { params });
}


/**
 * 通过ID查询活动（用于分享）
 * @param id 
 * @returns 
 */
export const getActivityInfoDetails = (id: number) => {
    return request.get(`/api/personal/activity/public/activityInfo/${id}`);
  }
  /**
   * 今天要开始的活动
   * @returns 
   */
  export const activityInfoToday =() => {
    return request.get('/api/personal/activity/public/activityInfo/today');
  }
  