// UploadType.js
import { makeAutoObservable } from 'mobx';
// 文件上传类型
class UploadType {
  type = '';

  constructor() {
    makeAutoObservable(this);
    this.loadType(); // 加载已保存的上传类型
  }

  setType(type:string) {
    this.type = type;
    this.saveType(); // 设置上传类型时保存到 sessionStorage
  }

  saveType() {
    sessionStorage.setItem('uploadType', this.type); // 将上传类型保存到 sessionStorage
  }

  loadType() {
    const savedType = sessionStorage.getItem('uploadType');
    if (savedType) {
      this.type = savedType; // 从 sessionStorage 加载上传类型
    }
  }
}

export default new UploadType();
