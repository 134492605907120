import FaceUtils from "./FaceUtils";
// v-html 模版字符
export const parseContent = (content: string): string => {
    // Supported HTML tags
    const html = (end?: string): RegExp => {
        return new RegExp(
            `\\n*\\[${end || ""}(code|pre|div|span|p|table|thead|th|tbody|tr|td|ul|li|ol|dl|dt|dd|h2|h3|h4|h5)([\\s\\S]*?)]\\n*`,
            "g"
        );
    };

    const fa = FaceUtils.faces(); // Assuming FaceUtils is imported or defined elsewhere

    if (content) {
        content = content
            .replace(/&(?!#?[a-zA-Z0-9]+;)/g, "&amp;") // Escape &
            .replace(/</g, "&lt;") // Escape <
            .replace(/>/g, "&gt;") // Escape >
            .replace(/'/g, "&#39;") // Escape '
            .replace(/"/g, "&quot;") // Escape "
            .replace(/@(\S+)(\s+?|$)/g, '@<a href="javascript:;">$1</a>$2') // Convert @mentions to links

            .replace(/face\[([^\s\\[\]]+?)]/g, (face: string) => {
                // Replace [face...] with corresponding image tag
                const alt = face.replace(/^face/g, "");
                return `<img className="w-5 h-5 mx-2" alt="${fa.get(alt)}" title="${fa.get(alt)}" src="${fa.get(alt)}">`;
            })

            .replace(/img\[([^\s]+?)]/g, (img: string) => {
                // Replace [img...] with image tag
                const href = img.replace(/(^img\[)|(]$)/g, "");
                return `<img class="message-img" src="${href}" alt="消息图片不能加载">`;
            })

            .replace(/file\([\s\S]+?\)\[[\s\S]*?]/g, (str: string) => {
                // Replace [file(...)[...]] with file link
                const href = (str.match(/file\(([\s\S]+?)\)\[/) || [])[1];
                const text = (str.match(/\)\[([\s\S]*?)]/) || [])[1];
                if (!href) return str;
                return `<a class="message-file" href="${href}"><i class="el-icon"><svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-029747aa=""><path fill="currentColor" d="M160 832h704a32 32 0 1 1 0 64H160a32 32 0 1 1 0-64zm384-253.696 236.288-236.352 45.248 45.248L508.8 704 192 387.2l45.248-45.248L480 584.704V128h64v450.304z"></path></svg></i>${text || href}</a>`;
            })

            .replace(/(https?:\/\/[^\s]+)/g, (match, href, target, rel) => {
                return  `<a class="underline" style="color:blue" href=${content}  >${content}</a>`;
            })
            .replace(html(), "<$1 $2>") // Convert supported HTML tags opening
            .replace(html("/"), "</$1>") // Convert supported HTML tags closing
            .replace(/\n/g, "<br>"); // Convert newline to <br> tag

        // Split content by double newlines to get paragraphs
        const paragraphs = content.split(/\n\n+/).map(paragraph => `${paragraph}`);
        content = paragraphs.join('');

    }

    return content;
};

/**
 * 模板字符串转换
 */
export const getVHtml = (content: string) => {
    return { __html: parseContent(content) };
}
