const Music = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
            <mask id="mask0_983_8791" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                <rect x="0.5" y="0.199951" width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_983_8791)">
                <path d="M9.45837 16.0334C9.98615 16.0334 10.4306 15.8528 10.7917 15.4917C11.1528 15.1306 11.3334 14.6861 11.3334 14.1584V11.0334H13.8334V9.3667H10.5V12.5959C10.3473 12.4848 10.1841 12.4049 10.0105 12.3563C9.83685 12.3077 9.65282 12.2834 9.45837 12.2834C8.9306 12.2834 8.48615 12.4639 8.12504 12.825C7.76393 13.1861 7.58337 13.6306 7.58337 14.1584C7.58337 14.6861 7.76393 15.1306 8.12504 15.4917C8.48615 15.8528 8.9306 16.0334 9.45837 16.0334ZM5.50004 18.5334C5.04171 18.5334 4.64935 18.3702 4.32296 18.0438C3.99657 17.7174 3.83337 17.325 3.83337 16.8667V3.53337C3.83337 3.07503 3.99657 2.68267 4.32296 2.35628C4.64935 2.02989 5.04171 1.8667 5.50004 1.8667H12.1667L17.1667 6.8667V16.8667C17.1667 17.325 17.0035 17.7174 16.6771 18.0438C16.3507 18.3702 15.9584 18.5334 15.5 18.5334H5.50004ZM11.3334 7.70003V3.53337H5.50004V16.8667H15.5V7.70003H11.3334Z"
                fill="currentColor"/>
            </g>
        </svg>
    )
}
export default Music