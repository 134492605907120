import { Button } from 'antd';
import { useState, useEffect } from 'react';
import Comments from '../../../components/HealthOnline/Comments';
import detailsStore from '../../../store/Details';
import WangEditorComponent from '../../../components/Public/WangEditorView';
import dictStore from '../../../store/dict';
import ShareWith from '../../../components/Public/ShareWith';
import dayjs from 'dayjs'
import { getVideoDetails } from "../../../api/healthOnline"
import { useLocation  } from 'react-router-dom';
import indexStore from '../../../store/index'
import HeadNavigation from '../../../store/HeadNavigation';
// 详情
const Details = () => {
  const location = useLocation();
  const [itemDetails, setItemDetails] = useState<any>();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const [moduleType] = useState<number>(indexStore.menusState.moduleType); //区别管理员/用户模块

  // 获取文章类型
  const getDictLabel = (type: number) => {
    const dictItem =
      dictStore.state.article_type.find((item: any) => item.dictCode === type) ||
      { dictLabel: '' };
    return dictItem.dictLabel;
  };
  //获取 article_tags类型数据label   根据code获取label
  const getCategoryType = (tagCode: number) => {
    const dictItem = dictStore.state.article_tags.find((item: any) => item.dictCode === Number(tagCode)) || { dictLabel: '' };
    return dictItem.dictLabel;
  }
  // 获取详情
  const getVideoDetailsFn = async () => {
   try {
    if (id) { // 添加条件判断
      const res = await getVideoDetails(Number(id));
      if (res.data.code === 200) {
        setItemDetails(res.data.data);
      }
    }else{
      setItemDetails(detailsStore.Details);
    }
   } catch (error) {
    console.log(error)
   }
  };
  useEffect(() => {
    const currentName = moduleType === 1 ? 'Health & wellness' : 'Online classes';
    HeadNavigation.setItem({ backTo: -1, goName:'Previous step', currentName });
    getVideoDetailsFn();
}, []);
  return (
    <div className="h-full overflow-hidden py-5 px-14 flex justify-between space-x-5">
      <div className="flex-1 h-full overflow-auto">
        <div className="w-full h-[25rem] bg-[#ccc] rounded-md overflow-hidden">
          <img
            className="w-full h-full object-cover"
            src={itemDetails?.bannerPath}
            alt={itemDetails?.bannerPath}
          />
        </div>
        <div className="flex items-center justify-between mt-4">
          <span className="text-4xl font-medium text-[#232323]">
            {itemDetails?.title}
          </span>
          <div className=' flex items-center space-x-12'>
            <ShareWith />
            <Button shape={'round'}>
              <div className="h-full flex items-center justify-center space-x-2">
                {getDictLabel(itemDetails?.type) && (
                  <img
                    className="w-6 h-6"
                    src={require(`../../../assets/images/healthWellness/${getDictLabel(
                      itemDetails?.type,
                    )}.svg`)}
                    alt={`${itemDetails?.type}`}
                  />
                )}
                <span className="text-base font-medium text-[#232323]">
                  Viewed by: {itemDetails?.viewingQuantity || 0}
                </span>
              </div>
            </Button>
          </div>
        </div>
        <div className="border-b border-[#E9EEF1] space-y-2 py-6">
          <p className="text-xl font-normal text-[#707070]">
            {itemDetails?.subtitle}
          </p>
          <div className='flex items-center space-x-2 text-base font-medium'>
            <span>Published on:{dayjs(itemDetails?.createTime).format('dddd, MMMM D, YYYY h:mm A')}</span>
            {itemDetails?.contentTags && itemDetails.contentTags.split(',').map((_item: any, index: number) => (
              <div className='flex items-center space-x-2' key={index}>
                <div className='w-[1px] h-[14px] bg-[#232323]'></div>
                <span>
                  {getCategoryType(_item)}
                </span>
              </div>
            ))}
          </div>
        </div>
        {itemDetails?.type!==102 && <p className="text-base font-medium py-6">{itemDetails?.description}</p>}
        {itemDetails?.type === 102 &&  itemDetails?.description && (
          <div className='is_show_border mt-2'>
            <WangEditorComponent
              initialValue={itemDetails?.description}
              readOnly={true}
            />
          </div>
        )}
        {itemDetails?.type === 101 && itemDetails?.filePath && (
          <audio controls className='w-full'>
            <source src={itemDetails?.filePath} />
            Your browser does not support the audio tag.
          </audio>
        )}
        {itemDetails?.type === 100 && itemDetails?.filePath && (
          <video controls className='w-full h-full'>
            <source src={itemDetails?.filePath} />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      <div className="w-1/3 h-full">
        {itemDetails && <Comments articleId={itemDetails?.id} />}
      </div>
    </div>
  );
};

export default Details;
