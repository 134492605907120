import { Input } from 'antd';
import { useState, useEffect } from 'react';
import { gameAddComment, gameCommentStar } from "../../api/gaming"
import throttle from '../../utils/throttle';
import dayjs from 'dayjs' // 导入插件
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import AvatarImg from '../Public/AvatarImg';
import userStore from "../../store/user"
// 评论组件
interface CommentsProps {
    id: number
}

// 评论
const Comments: React.FC<CommentsProps> = ({ id }) => {
    const [viewCommentData, setViewCommentData] = useState<any>([]);
    const [content, setContent] = useState<string>("");

    // 获取评论
    const getCommentPageFn = async () => {
        try {
            if (id) {
                const res = await gameCommentStar(id)
                if (res.data.code === 200 && res.data.data) {
                    // 发送消息时间转换
                    dayjs.extend(utc)
                    dayjs.extend(relativeTime)
                    const viewCommentList = res.data.data.map((item: any) => ({
                        ...item,
                        createTime: dayjs(item.createTime).fromNow(),
                    }));
                    setViewCommentData(viewCommentList)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    // 添加评论
    const addCommentFn = async () => {
        try {
            if (id && content.trim() !== '') {
                const res = await gameAddComment({
                    content,
                    gameId: id
                })
                if (res.data.code === 200) {
                    getCommentPageFn();
                    setContent(""); // 清空输入框内容
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getCommentPageFn()
    }, [id]);

    return (
        <div className="w-full h-full flex flex-col border border-[#D2D2D2] rounded-md overflow-hidden ">
            <div className="w-full h-16 px-5 leading-[4rem] bg-[#F6F8F8] text-xl font-medium border-b border-[#D2D2D2]">Comments</div>
            <div className='w-full flex-1 overflow-hidden flex flex-col justify-end' >
                <div className='space-y-3 overflow-auto px-5'>
                    {viewCommentData.map((item: any, index: number) => (
                        <div className='w-full flex items-center' key={index}>
                            <div className='w-full flex items-center justify-between space-x-4 '>
                                <div className='w-12 h-12 bg-[#ccc] rounded-full overflow-hidden'>
                                    <AvatarImg id={item.userId} />
                                </div>
                                <div
                                    className={`${index !== 1 && 'border-b border-[#DEDEDE]'} flex-1 flex items-center justify-between py-3`}
                                >
                                    <div>
                                        <div className='text-base font-normal text-[#959595] space-x-1'>
                                            <span> {item.nickName}</span>
                                            <span> {item.createTime}</span>
                                        </div>
                                        <p className='text-xl font-normal'>{item.content}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='w-full flex items-center justify-between bg-[#F6F8F8] space-x-5 p-5'>
                <div className='w-12 h-12 bg-[#ccc] rounded-full overflow-hidden'>
                    <AvatarImg id={userStore.state?.userInfo?.id} />
                </div>
                <Input
                    value={content}
                    onChange={(e) => setContent(e.target.value)} // 绑定输入框的变化
                    onPressEnter={() => throttle(addCommentFn)} // 绑定回车键事件
                    className="flex-1 h-12 rounded-full bg-[#FFFFFF]"
                    placeholder="Write a comment....."
                />
                <div className='w-12 h-12 flex items-center justify-center bg-[#3D6683] rounded-full cursor-pointer'
                    onClick={() => throttle(addCommentFn)}
                >
                    <img src={require(`../../assets/images/public/send-01.png`)} alt='send-01' />
                </div>
            </div>
        </div>
    )
}

export default Comments;
