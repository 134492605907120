import React from 'react';
import { Button, Input, Form } from 'antd';
import type { FormProps } from 'antd';
import { Link } from 'react-router-dom';
import { getCode } from "../../api/login"
// 定义 nextStep 参数的类型
type nextStepFunction = () => void;
type FieldType = {
    username: string;
    nickName: string;
    firstName: string;
    lastName: string;
    uuid: string;
};

// 使用 PrevStepFunction 类型声明 nextStep 参数
const FirstStep: React.FC<{ nextStep: nextStepFunction, updateUserData: (userData: FieldType) => void }> = ({ nextStep, updateUserData }) => {
    // 自定义验证函数 校验有效的邮箱地址
    const validateEmailOrPhone = async (_: any, value: any) => {
        const emailOrPhoneRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (value && !emailOrPhoneRegex.test(value)) {
            return Promise.reject(new Error('Please enter a valid email address!'));
        }
        return Promise.resolve();
    };
   
    // 获取验证码
    const handleSignupClick: FormProps<FieldType>['onFinish'] = async (values) => {

        const { username } = values
        // 获取验证码请求
      try {
        const res = await getCode({ username,type:'register'})
        if (res.data.code === 200) {
            const { uuid } = res.data || {}
            const updatedUserData = { ...values, uuid };
            updateUserData(updatedUserData);
            nextStep();
        } 
      } catch (error) {
        console.log(error)
      }
    };
    return (
        <div className='space-y-8'>
            <div className='space-y-8'>
                <div className=" space-y-5 text-center px-16">
                    <p className=" text-4xl font-medium text-[#232323]">Create a new account</p>
                    <p className=" text-xl font-normal text-[#959595]">Empowering seniors, Enriching lives.</p>
                </div>
            </div>
            <Form
                style={{ maxWidth: '100%' }}
                initialValues={{ remember: true }}
                onFinish={handleSignupClick}

            >
                <div className='w-full space-y-5'>
                    <Form.Item<FieldType>
                        className='w-full h-14'
                        name="username"
                        rules={[
                            { required: true, message: 'Please input your Email address!' },
                            { validator: validateEmailOrPhone },
                        ]}>
                        <Input placeholder="Email address"
                            style={{ height: '56px', width: '100%' }} />
                    </Form.Item>
                    <div className='w-full flex justify-between space-x-5 h-14'>
                        <Form.Item<FieldType>
                            className='w-full h-14'
                            name="firstName"
                            rules={[{ required: true, message: 'Please input your First name!' }]}>
                            <Input placeholder="First name"
                                style={{ height: '56px', width: '100%' }} />
                        </Form.Item>
                        <Form.Item<FieldType>
                            className='w-full h-14'
                            name="lastName"
                            rules={[{ required: true, message: 'Please input your  Last name!' }]}>
                            <Input placeholder="Last name"
                                style={{ height: '56px', width: '100%' }} />
                        </Form.Item>
                    </div>
                    <Form.Item<FieldType>
                        className='w-full h-14'
                        name="nickName"
                        rules={[{ required: true, message: 'Please input your  Display name!' }]}>
                        <Input placeholder="Display name"
                            style={{ height: '56px', width: '100%' }} />
                    </Form.Item>
                    <div className=' text-base font-medium  text-[#959595]'>
                        By clicking Sign Up, you agree to our <a href='/' className='text-[#232323] underline underline-offset-4 mr-2'>Terms, </a>
                        <a href='/' className='text-[#232323] underline underline-offset-4'>Privacy Policy</a> and<br />
                        <a href='/' className='text-[#232323] underline underline-offset-4'>Cookies Policy</a>. You may receive SMS notifications from us and<br />
                        can opt out at any time.
                    </div>
                    <div className='w-full h-[2.875rem]'>

                        <Button shape={'round'} style={{ height: '100%', width: '100%', background: '#275576' }}
                            htmlType="submit"
                        >
                            <span className='text-base font-medium text-white'>Signup</span>
                        </Button>
                    </div>
                </div>
            </Form>
            <div className='w-full h-[2.875rem]'>
                <Link to='/login'>
                    <Button shape={'round'} style={{ height: '100%', width: '100%', borderColor: '#275576' }} >
                        <span className='text-base font-medium text-[#275576]'>Login with an existing account</span>
                    </Button>
                </Link>
            </div>
        </div>
    )
}
export default FirstStep