import React, { useRef, useState, useEffect } from 'react';
import { Popover } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import FaceUtils from "../../utils/FaceUtils";
import AvatarImg from '../Public/AvatarImg';
import userStore from "../../store/user"
// 用div模拟自定义input
interface TextAndImageEditorProps {
    onContentChange: (content: string) => void;
}
const TextAndImageEditor: React.FC<TextAndImageEditorProps> = ({ onContentChange }) => {
    const contentEditableRef = useRef<HTMLDivElement>(null);
    const savedSelectionRef = useRef<Range | null>(null);
    const [faceList] = useState<string[]>(FaceUtils.alt);

    // 自动聚焦到可编辑内容区域
    useEffect(() => {
        if (contentEditableRef.current) {
            contentEditableRef.current.focus();
        }
    }, []);

    // 保存当前选择范围
    const saveSelection = () => {
        const sel = window.getSelection();
        if (sel && sel.rangeCount > 0) {
            savedSelectionRef.current = sel.getRangeAt(0);
        }
    };

    // 恢复之前保存的选择范围
    const restoreSelection = () => {
        const sel = window.getSelection();
        if (savedSelectionRef.current && sel) {
            sel.removeAllRanges();
            sel.addRange(savedSelectionRef.current);
        }
    };

    // 用于在光标位置插入HTML的函数
    const insertAtCursor = (html: string) => {
        const sel = window.getSelection();
        if (sel && sel.rangeCount > 0) {
            const range = sel.getRangeAt(0);
            range.deleteContents();

            const div = document.createElement('div');
            div.innerHTML = html;
            const frag = document.createDocumentFragment();
            let node: ChildNode | null;
            let lastNode: ChildNode | null = null;
            while ((node = div.firstChild)) {
                lastNode = frag.appendChild(node);
            }
            range.insertNode(frag);

            // 将所选内容移动到插入的节点之后
            if (lastNode) {
                range.setStartAfter(lastNode);
                range.setEndAfter(lastNode);
                sel.removeAllRanges();
                sel.addRange(range);
            }

            // 更新保存的选择范围
            saveSelection();
        }
    };
    // 更新内容并传递给父组件
    const handleContentChange = () => {
        const content = contentEditableRef.current?.innerHTML || '';

        onContentChange(content);
        if (contentEditableRef.current) {
            contentEditableRef.current.innerHTML = ''; // 更新内容
            contentEditableRef.current.focus();
        }
    };
    // 选择表情发送
    const selectEmojiList = (item: string) => {
        const imgTag = `<img src="${FaceUtils.faces().get(item)}" alt="face${item}" style="width:20px; height: 20px;margin:0 2px" />`;
        if (contentEditableRef.current) {
            contentEditableRef.current.focus(); // 确保 contentEditable 获得焦点
            restoreSelection(); // 恢复选择范围
            insertAtCursor(imgTag);
        }
    };
    // 回车发送消息
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // 阻止默认行为
            handleContentChange()
        }
    }
    // 表情列表选择
    const emojiList = (
        <div className='w-[18rem] grid grid-cols-10 gap-2'>
            {faceList.map((item: string, index: number) => (
                <img
                    className='cursor-pointer'
                    key={index}
                    src={FaceUtils.faces().get(item)}
                    alt={item}
                    onClick={() => selectEmojiList(item)}
                />
            ))}
        </div>
    );
    return (
        <div className=' w-full  py-5 border-t border-[#D2D2D2] pl-8 pr-14 flex items-center space-x-5'>
            <div className='w-12 h-12 bg-[#F1F1F1] rounded-full overflow-hidden'>
                <AvatarImg id={userStore.state?.userInfo?.id} />
            </div>
            <div className='flex-1 bg-[#F1F1F1] border border-[#D2D2D2] rounded-2xl
                            flex items-center justify-between space-x-2 p-2 overflow-hidden'>
                <div className='flex-1 h-full overflow-auto max-h-24'  >
                    <div
                        ref={contentEditableRef}
                        contentEditable
                        onMouseUp={saveSelection} // 在鼠标抬起时保存选择范围
                        onKeyUp={saveSelection} // 在键盘按键抬起时保存选择范围
                        onKeyDown={handleKeyDown} // 捕获按键事件
                        className="flex flex-wrap break-all items-start p-2"
                        style={{ outline: 'none' }}
                    ></div>
                </div>
                <Popover content={emojiList} title="" trigger="click">
                    <SmileOutlined className=' cursor-pointer' style={{ height: '24px', color: '#275576', fontSize: '24px', outline: 'none' }} />
                </Popover>
            </div>

            <div className=' w-12 h-12 bg-[#3D6683] cursor-pointer rounded-full flex items-center justify-center text-white'
                onClick={handleContentChange}>
                <img src={require(`../../assets/images/public/send-01.png`)} alt='send-01' />
            </div>
        </div>

    );
};

export default TextAndImageEditor;
