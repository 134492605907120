import request from "../composables/request";
/**
 * 字典类型列表
 * @returns 
 */
export const getDictTypeList = () => {
    return request.get('/system/dict/type/list');
}
/**
* 字典数据接口
* @returns 
*/
export const getDictDataList = async (params: {
    pageNum?: number;
    pageSize?: number;
    dictType: string;
}) => {
    return await request.get('/system/dict/data/list', { params })
};
/**
 * 删除字典接口
 * @param dictCodes 
 * @returns 
 */
export const delDictDataItem = async (dictCodes:number | string) => {
    return await request.delete(`/system/dict/data/${dictCodes}`)
};
/**
 * 新增字典接口
 * @param params 
 * @returns 
 */
export const addDictData = (params: {
    dictLabel: string,
    dictValue: string,
    dictType: string
  }) => {
    return request.post('/system/dict/data', params);
  }
  /**
   * 编辑字典
   * @param params 
   * @returns 
   */
  export const editDictData = (params: {
    dictCode: null,
    dictLabel: string,
    dictValue: string,
    dictType: string
  }) => {
    return request.put('/system/dict/data', params);
  }