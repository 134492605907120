import React, { useState, useEffect, useRef } from 'react';
import { Input, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import CardItem from './CardItem';
import { getPageVideoImgInfo, getViewFavorites } from '../../api/healthOnline';
import dictStore from '../../store/dict';
import indexStore from '../../store/index'
interface GamingAllProps {
    category: string | null;
}
const HealthOnlineAll: React.FC<GamingAllProps> = ({ category }) => {
    // 状态声明
    const [selectedTab, setSelectedTab] = useState<string | null | number>(null);
    const [searchValue, setSearchValue] = useState<string>(''); // 搜索关键词
    const [loading, setLoading] = useState<boolean>(false); // 加载状态
    const hasMore = useRef<boolean>(true); // 是否还有更多数据可加载
    const containerRef = useRef<HTMLDivElement>(null); // 容器的引用，用于监听滚动事件
    const currentPage = useRef<number>(1)
    const dataList = useRef<any[]>([]) //数据列表
    const [articleType, setArticleType] = useState<any[]>([
        { dictCode: null, dictLabel: 'All' }]);

    // 基于搜索和所选选项卡获取数据的函数
    const getPageVideoImgInfoFn = async (tab: any, title?: string) => {
        try {
            setLoading(true); // 开始加载数据
            const param: any = {
                current: currentPage.current,
                size: 20,
                title
            };
            if (tab) param.type = Number(tab);
            const moduleType = indexStore.menusState.moduleType
            if (category === 'myFavorites') {
                param.favorites = moduleType
            } else {
                param.moduleType = moduleType
                if (category !== null) param.category = Number(category)
            }
            const res = await (category === 'myFavorites' ? getViewFavorites({ ...param }) : getPageVideoImgInfo({ ...param }));
            setLoading(false); // 数据加载完成

            if (res.data.code === 200) { // 如果请求成功
                dataList.current = [...dataList.current, ...res.data?.data.records]; // 更新数据列表
                currentPage.current = currentPage.current + 1 // 更新页码
                // 判断是否还有更多数据可加载
                if (res.data.data.total === dataList.current.length) {
                    hasMore.current = false; // 没有更多数据了
                }
            }
        } catch (error) {
            console.log(error, 'error');
            setLoading(false); // 停止加载状态
        }
    };
    // 处理滚动事件
    const handleScroll = () => {
        if (!containerRef.current) return; // 如果容器不存在，直接返回
        const { scrollTop, clientHeight, scrollHeight } = containerRef.current; // 获取容器的滚动位置信息
        // 当滚动到底部且还有更多数据时，加载更多信息
        if (scrollHeight - scrollTop === clientHeight && hasMore.current && currentPage.current !== 1) {
            getPageVideoImgInfoFn(selectedTab, searchValue); // 加载更多信息
        }
    }

    // 重置
    const resetHandle = async () => {
        currentPage.current = 1; // 搜索时重置页码为1
        hasMore.current = true; // 重置是否有更多数据的状态
        dataList.current = []; // 清空当前数据列表

    }
    // 处理搜索事件
    const handleSearch = () => {
        resetHandle()
        getPageVideoImgInfoFn(selectedTab, searchValue); // 根据搜索关键词获取信息
    };
    // 切换tab
    const handleTabClick = async (tab: string | null | number) => {
        await setSelectedTab(tab);
        setSearchValue('');
        resetHandle()
        getPageVideoImgInfoFn(tab, '');
    };
    // category  类型更改时重新获取数据
    useEffect(() => {
        const fetchData = async () => {
            await setSelectedTab(null);
            await setSearchValue('');
            await resetHandle()
            getPageVideoImgInfoFn(null, '');
        };
        fetchData();
        // useCallback 确保 getActivityPageFn 引用稳定
    }, [category]);

    useEffect(() => {
        setArticleType(prevList => [
            ...prevList.slice(0, 1), // 保留前1个初始值
            ...dictStore.state.article_type
        ]);
    }, [dictStore.state.article_type]);
    return (
        <div className="w-full h-full flex flex-col space-y-6 overflow-hidden">
            <div className="flex justify-between items-center">
                <ul className="flex items-center space-x-7 text-xl font-normal text-[#707070]">
                    {articleType.map((tab, index) => (
                        <li
                            key={index}
                            onClick={() => handleTabClick(tab.dictCode)}
                            className={`pb-5 cursor-pointer border-b-2 duration-500 ${selectedTab === tab.dictCode
                                ? 'border-[#232323] text-[#232323]'
                                : 'border-white'
                                }`}
                        >
                            {tab.dictLabel}
                        </li>
                    ))}
                </ul>

                {/* Search input */}
                <Input
                    className="w-64 h-12 rounded-full bg-[#F1F1F1]"
                    placeholder="Search content"
                    suffix={<SearchOutlined style={{ color: '#232323', fontSize: '1.25rem' }} />}
                    value={searchValue}
                    onPressEnter={() => handleSearch()}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
            </div>

            <div className="w-full flex-1 overflow-auto space-y-6" ref={containerRef} onScroll={handleScroll}>
                <p className="text-2xl font-normal text-[#707070]">
                    Unlock your path to thriving in your golden years with our Senior Health & Wellness Center.
                    From antiaging secrets to nutrition tips, exercise guides, sleep solutions, and more.
                </p>
                <div>
                    {/* Data listing */}
                    {dataList.current && dataList.current.length > 0 ? (
                        <div className="w-full flex-1 pb-5 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5">
                            {dataList?.current.map((item) => (
                                <CardItem isShowHeard={category === 'myFavorites' ? true : false}
                                    item={item} key={item.id} onUpdate={() => handleSearch()} />
                            ))}
                        </div>
                    ) : (<div className="h-10 leading-10 text-center text-[#707070]">No data available</div>)}
                    <div className='flex justify-center'><Spin spinning={loading} delay={500}></Spin></div>
                </div>

            </div>
        </div>
    );
};

export default HealthOnlineAll;
