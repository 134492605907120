import { Popover, Spin } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { logout } from "../../api/user"
import { clearAllData } from '../../store/clearAllData';
import chartStore from "../../store/chart"
import userStore from "../../store/user"
import { closeWebSocket } from '../../utils/websocketManager';
import HeadNavigation from '../../store/HeadNavigation';
import { saveLastMessageFn } from '../../utils/readingMessages'
import { observer } from 'mobx-react-lite'; // 使用 observer 包裹组件
import { useLocation } from 'react-router-dom';
import { useState,useEffect } from 'react';
import AvatarImg from './AvatarImg';
// 登录信息
interface LoginInfoProps {
    color?: string;
    bgColor?: string;
}
const LoginInfo: React.FC<LoginInfoProps> = observer(({ color = 'white', bgColor = '#275576' }) => {
    // 定义
    const location = useLocation();
    const navigate = useNavigate();
    const [refreshCount, setRefreshCount] = useState(0); //更新数据
    // 加载状态
    const [loadings, setLoadings] = useState<boolean>(false);
    // 跳转
    const navigationTypeJump = () => {
        // 标记读过消息了
        const { backTo, goName, currentName } = HeadNavigation.item
        HeadNavigation.setItem({ backTo, goName, currentName });
        navigate('/notification');
    }
    // 退出登录
    const logoutFn = async () => {
        try {
            setLoadings(true)
            // 保留最后一条聊天记录与未读数量
            await saveLastMessageFn()
            const res = await logout()
            if (res.data.code === 200) {
                // 清空存入在store中的数据
                clearAllData()
                // 关闭ws心跳测试
                closeWebSocket()
                navigate('/');
            }
            setLoadings(false)
        } catch (error) {
            setLoadings(false)
            console.log(error)
        }
    }
    // 跳转到个人中心
    const openPersonalCenter = async () => {
        navigate('/personalCenter')
    }
        // 在组件挂载后调用
        useEffect(() => {
             // 强制更新数据
             setRefreshCount(prevCount => prevCount + 1);
        }, [userStore.state.userInfo.prevCount]);
    // 退出登录
    const userList = (
        <Spin spinning={loadings} delay={100}>
            <div className='px-2 cursor-pointer' onClick={openPersonalCenter}> Personal Center </div>
            <div className='px-2 cursor-pointer' onClick={logoutFn}> Log out and log in </div>
        </Spin>
    );
    return (
        <div className='flex space-x-5'>
            <div className={`relative w-12 h-12 border cursor-pointer border-[${color}] 
                 rounded-full text-white text-xl font-bold text-center leading-[3rem]`}
                onClick={navigationTypeJump} >
                <span className={`text-[${color}]`}> <BellOutlined /></span>
                <div className={` p-[.125rem]  absolute top-1 -right-1 flex items-center justify-center`}
                    style={{ background: `${bgColor}` }}>
                    {location.pathname !== '/notification' && !chartStore.chartsState.hasItBeenRead &&
                        <div className='w-3 h-3 bg-red-500 rounded-full'></div>}
                </div>
            </div>
            <Popover content={userList} title="" trigger="click">
                <div key={refreshCount} className=' w-12 h-12 bg-[#ccc] rounded-full cursor-pointer overflow-hidden'>
                    <AvatarImg id={userStore.state?.userInfo?.id}/>
                </div>
            </Popover>

        </div>
    )
})
export default LoginInfo