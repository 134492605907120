const SendCode = {
  //心跳
  PING: "0",
  //准备
  READY: "1",
  //消息
  MESSAGE: "2",
  //其他设备登录
  OTHER_LOGIN: "4",
  //读取消息
  READ: "3",
  //单聊
  SINGLECHAT:'0',
  // 群聊
  GROUPCHAT:'1',
  // 聊天
  CHARTTYPE:'1',
};
export default SendCode;
