import chartStore from './chart'
import detailsStore from './Details'
import dictStore from './dict'
import headNavigation from './HeadNavigation'
import user from './user'
// 退出有清除仓库中的所有数据
export const clearAllData=()=>{
    chartStore.clearData()
    detailsStore.clearData()
    dictStore.clearData()
    headNavigation.clearData()
    user.clearData()
}