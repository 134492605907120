
import { useState, useEffect } from 'react';
import { Button } from 'antd';
import Animal from '../Global/Interest/Animal';
import ArtCraft from '../Global/Interest/ArtCraft';
import Culinary from '../Global/Interest/Culinary';
import Exercise from '../Global/Interest/Exercise';
import Family from '../Global/Interest/Family';
import Gaming from '../Global/Interest/Gaming';
import Music from '../Global/Interest/Music';
import Outdoor from '../Global/Interest/Outdoor';
import Photography from '../Global/Interest/Photography';

// 兴趣爱好数据
const interests = [
    { title: 'Animal', component: Animal },
    { title: 'Photography', component: Photography },
    { title: 'Outdoor', component: Outdoor },
    { title: 'Exercise', component: Exercise },
    { title: 'Art & Craft', component: ArtCraft },
    { title: 'Music', component: Music },
    { title: 'Gaming', component: Gaming },
    { title: 'Culinary', component: Culinary },
    { title: 'Family', component: Family },
];

interface InterestProps {
    onInterestsSelect: (isSelected: boolean) => void;
}
const Interest: React.FC<InterestProps> = ({ onInterestsSelect }) => {
    const [selectedInterests, setSelectedInterests] = useState<string[]>([]);
    // 选择兴趣爱好
    const handleInterestClick = (interestTitle: string) => {
        // 判断兴趣爱好是否已经被选中
        const isInterestSelected = selectedInterests.includes(interestTitle);
        if (isInterestSelected) {
            // 如果兴趣爱好已经被选中，则从已选列表中移除
            setSelectedInterests(prevSelectedInterests => prevSelectedInterests.filter(item => item !== interestTitle));
        } else {
            // 如果兴趣爱好尚未被选中，则添加到已选列表中
            setSelectedInterests(prevSelectedInterests => [...prevSelectedInterests, interestTitle]);
        }
    };
    // 在 selectedInterests 发生变化后，通知父组件选择状态变化
    useEffect(() => {
        onInterestsSelect(selectedInterests.length > 0);
    }, [selectedInterests, onInterestsSelect]);

    return (
        <div className='w-[700px] flex flex-wrap justify-center space-x-5'>
            {interests.map((item, index) => (
                <Button  key={index} className='h-[50px] flex items-center mb-5'
                    onClick={() => handleInterestClick(item.title)}
                    style={{
                        background: selectedInterests.includes(item.title) ? '#275576' : 'none',
                        color: selectedInterests.includes(item.title) ? '#ffffff' : '#232323'
                    }}
                >
                    <item.component  />
                    <span className='text-base font-medium ml-2 '>{item.title}</span>
                </Button>
            ))}
        </div>
    );
}

export default Interest;
