import { useState } from 'react';
import Footer from "../components/Footer"
import Logo from "../components/Global/Logo"
import FirstStep from '../components/Register/FirstStep';
import SecondStep from '../components/Register/SecondStep';
import ThirdStep from '../components/Register/ThirdStep';
// 注册
const Register = () => {
    // 用于控制显示到第几步流程
    const [currentStep, setCurrentStep] = useState(1);
    const [userData, setUserData] = useState();

    // 更新userData 数据
    const updateUserData = (newUserData: any) => {
        setUserData(newUserData);
    };
    // 函数用于前进到下一步
    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    // 函数用于后退到上一步
    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };
    return (
        <div className="w-full h-screen flex flex-col">
            <div className="w-full flex-1 py-6  flex justify-center items-center">
                <div className="space-y-8 flex flex-col items-center ">
                    <Logo />
                    {/* 根据当前步骤显示不同内容 */}
                    {currentStep === 1 && <FirstStep nextStep={nextStep} updateUserData={updateUserData} />}
                    {currentStep === 2 && <SecondStep nextStep={nextStep} prevStep={prevStep} userData={userData} />}
                    {currentStep === 3 && <ThirdStep />}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Register;
