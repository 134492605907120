import { Outlet } from "react-router-dom";
// 定义组件
const Index = () => {
    // 当该组件被渲染时，将渲染匹配到的子路由组件
    return (
        <div className="w-full h-full"><Outlet /></div>
    );
}

// 输出组件以供其他模块使用
export default Index;
