import { Button, Modal, Form, Input, Rate } from 'antd';
import { useState } from 'react';
import type { FormProps } from 'antd';
import { addSysCommentPrivate } from "../../../api/user"
interface AddOrEditProps {
    visible?: boolean;
    onClose: () => void; //关闭弹框
    saveSuccess: () => void; //保存成功
}
type FieldType = {
    content: string,
    userScore: string,
};
// 新增编辑关于我们
const AddComments: React.FC<AddOrEditProps> = ({ visible = false, onClose, saveSuccess }) => {
    const [loading, setLoading] = useState<boolean>(false); // 加载状态
    const [form] = Form.useForm();
    // 保存 对系统进行评论
    const addSysCommentPrivateSave: FormProps<FieldType>['onFinish'] = async (values) => {
        try {
            const params: any = { ...values };
            setLoading(true)
            const formData:any = new FormData();
            formData.append('content', params.content);
            formData.append('userScore', params.userScore);
            const res = await addSysCommentPrivate(formData)
            setLoading(false)
            if (res.data.code === 200) {
                saveSuccess();
                onClose();
            }
        } catch (error) {
            // 处理异常情况
            console.error('保存失败:', error);
            setLoading(false)
        }
    };
    return (
        <Modal
            title="Add"
            width={600}
            centered
            open={visible}
            onCancel={() => onClose()}
            footer={null}
        >
            <Form
                style={{ maxWidth: '100%', maxHeight: '100%' }}
                onFinish={addSysCommentPrivateSave}
                form={form}
                layout="vertical"
                className="flex flex-col"
            >
                <Form.Item<FieldType>
                    name="content"
                    label="Content"
                    className='w-full'
                    rules={[{ required: true, message: 'Please input Content!' }]}>
                    <Input placeholder="Content" style={{ height: '56px', width: '100%' }} />
                </Form.Item>
                <Form.Item<FieldType>
                    name="userScore"
                    label="Score"
                    className='w-full'
                    rules={[{ required: true, message: 'Please choose a rating!' }]}>
                    < Rate allowHalf defaultValue={10} style={{ color: '#EA5E5E' }} />
                </Form.Item>
                <div className=' flex justify-between items-center space-x-5'>
                    <Button shape="round"
                        style={{ background: '#F1F1F1', borderColor: '#F1F1F1' }}
                        className='w-1/2 h-11  px-4 space-x-2  flex items-center justify-center  '
                        onClick={() => onClose()}
                    >
                        <img src={require('../../../assets/images/public/close.png')} alt='close' />
                        <span className='text-base font-medium text-[#275576] '> Cancel</span>
                    </Button>
                    <Button shape="round"
                        style={{ background: '#275576', borderColor: '#275576' }}
                        className='w-1/2 h-11  px-4 flex items-center justify-center space-x-2 '
                        htmlType="submit"
                        loading={loading}
                    >
                        <img src={require('../../../assets/images/public/send-01.png')} alt='send-02' />
                        <span className=' text-base font-medium text-white '> Save</span>
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}
export default AddComments