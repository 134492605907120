import SendCode from './SendCode'; // 引入发送代码枚举
import userStore from "../store/user"; // 引入用户存储
import chartStore from "../store/chart"; // 引入用户存储
import { processingMessages, saveLastMessageFn } from './readingMessages';
import debounce from './debounce';
let websocket: WebSocket | null = null;
let heartbeatInterval: any = null;
let reconnectTimeout: any = null;
const initializeWebSocket = () => {
    if (websocket && (websocket.readyState === WebSocket.OPEN || websocket.readyState === WebSocket.CONNECTING)) {
        return; // 如果 WebSocket 已经打开或正在连接，则直接返回
    }
    websocket = new WebSocket(`${process.env.REACT_APP_WS_URL}?token=${userStore.state.token}&client=pc`);
    // websocket = new WebSocket(`ws://483g59b249.goho.co:20184?token=${userStore.state.token}&client=pc`);
    websocket.onopen = () => {
        sendMessage(JSON.stringify({ code: SendCode.READY })); // 发送准备消息
        startHeartbeat(); // 连接成功后启动心跳
    };

    websocket.onclose = () => {
        attemptReconnect(); // 连接关闭时尝试重连
    };

    websocket.onerror = (error) => {
        // console.error("WebSocket Error: ", error);
        websocket?.close(); // 出现错误时关闭连接
    };
};
const sendMessage = (message: string) => {
    if (websocket?.readyState === WebSocket.OPEN) {
        websocket.send(message); // 如果 WebSocket 连接是打开状态，则发送消息
    }
};
const startHeartbeat = () => {
    if (!websocket) {
        return;
    }

    if (heartbeatInterval) {
        clearInterval(heartbeatInterval); // 清除现有的心跳定时器
    }

    heartbeatInterval = setInterval(() => {
        if (websocket?.readyState === WebSocket.OPEN) {
            websocket.send(JSON.stringify({ code: SendCode.PING })); // 发送心跳包
        } else {
            clearInterval(heartbeatInterval); // 如果连接不是打开状态，清除心跳定时器
        }
    }, 5000); // 心跳间隔 5 秒
};

const attemptReconnect = () => {
    if (reconnectTimeout) {
        clearTimeout(reconnectTimeout); // 确保没有重复的重连尝试
    }

    reconnectTimeout = setTimeout(() => {
        initializeWebSocket(); // 尝试重新初始化 WebSocket 连接
    }, 3000); // 重连间隔 5 秒
};
// 获取消息
const onmessage = (callback: (data?: any) => void) => {
    if (websocket) {
        websocket.onmessage = (event: MessageEvent) => {
            const parsedData = JSON.parse(event.data); // 解析收到的数据
            // 判断是否需要读取消息
            if (parsedData['code'] !== SendCode.READY && parsedData['code'] !== SendCode.PING) {
                callback(parsedData.message); // 调用回调函数处理数据
                processingMessages(parsedData.message)
                const { id } = userStore.state.userInfo
                const friendId = chartStore.chatObject?.id
                const { fromId } = parsedData['message']
                if (Number(fromId) !== Number(id)) {
                    const sendInfo = {
                        code: SendCode.READ, //读消息
                        message: { chatId: fromId, timestamp: new Date().getTime(), type: "0", userId: id }
                    };
                    // 通过ws 发送消息
                    sendMessage(JSON.stringify(sendInfo))
                    if (Number(fromId) !== Number(friendId)) {
                        // 使用 debounce 包装保存最后消息的操作
                        debounce(() => {
                            saveLastMessageFn(); // 在这里调用异步函数
                        }, 5000);
                    }
                }
            }
        };
    }
};
// 断开 WebSocket 连接并清理资源
const closeWebSocket = () => {
    if (heartbeatInterval) {
        clearInterval(heartbeatInterval); // 清除心跳定时器
        heartbeatInterval = null;
    }

    if (reconnectTimeout) {
        clearTimeout(reconnectTimeout); // 清除重连定时器
        reconnectTimeout = null;
    }

    if (websocket) {
        websocket.onclose = null; // 防止在调用 close 时触发重连
        websocket.close(); // 关闭 WebSocket 连接
        websocket = null;
    }
};
export {
    initializeWebSocket,
    sendMessage,
    onmessage,
    closeWebSocket
};
