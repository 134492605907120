import { useState, useEffect } from 'react';
import Footer from "../components/Footer"
import Logo from "../components/Global/Logo"
import FirstStep from '../components/Login/Steps/FirstStep';
import SecondStep from '../components/Login/Steps/SecondStep';
import { clearAllData } from '../store/clearAllData';
import { closeWebSocket} from '../utils/websocketManager';

// 登录
const Login = () => {
    // 用于控制显示到第几步流程
    const [showStep2, setShowStep2] = useState(false);
    // 账号数据
    const [accountParameData, setAccountParame] = useState();
    // 用于接收设置显示那步流程
    const handleSendCode = (value: boolean) => {
        setShowStep2(value);
    }
    // 获取登录的账号参数
    const getAccountParame = (data: any, step: boolean) => {
        setAccountParame(data);
        setShowStep2(step);
    };

    useEffect(() => {
        // 清除存缓存数据
        clearAllData()
        // 关闭ws心跳测试
        closeWebSocket()
    }, []);

    return (
        <div className="w-full h-screen flex flex-col">
            <div className="w-full flex-1 py-6  flex justify-center items-center">
                <div className="space-y-8 flex flex-col items-center ">
                    <Logo />
                    {/* 第一步 输入邮箱或电话发送验证码 */}
                    {!showStep2 && (
                        <FirstStep emailVerificationData={getAccountParame} />
                    )}
                    {/*第二步 输入验证码进行验证登录*/}
                    {showStep2 && (
                        <SecondStep handleRecheckEmail={handleSendCode} accountParameData={accountParameData} />
                    )}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Login;
