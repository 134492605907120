import { Drawer, Spin } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { sysCommentPublicPage } from "../../../api/user"
import TestimonialCard from './Card';
import Add from './Add';
import userStore from '../../../store/user';
interface HistoricalNewsProps {
    visible?: boolean;
    onClose: () => void;
}
// 历史消息记录
const SeeAll: React.FC<HistoricalNewsProps> = ({ visible = false, onClose }) => {
    const currentPage = useRef<number>(1) //页码
    const [dataList, setDataList] = useState<any[]>([]);
    const hasMore = useRef<boolean>(true); // 是否还有更多数据可加载
    const [loading, setLoading] = useState<boolean>(false); // 加载状态
    const containerRef = useRef<HTMLDivElement>(null); // 容器的引用，用于监听滚动事件
    // 获取数据
    const sysCommentPublicPageFn = async () => {
        try {
            setLoading(true); // 开始加载数据
            const res = await sysCommentPublicPage(20, currentPage.current)
            if (res.data.code === 200) {
                setDataList([...dataList, ...res.data.data.records]); // 更新数据列表
                currentPage.current = currentPage.current + 1
                // 判断是否还有更多数据可加载
                if (res.data.data.total <= dataList.length) {
                    hasMore.current = false; // 没有更多数据了
                }
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    // 处理滚动事件
    const handleScroll = () => {
        if (!containerRef.current) return; // 如果容器不存在，直接返回
        const { scrollTop, clientHeight, scrollHeight } = containerRef.current; // 获取容器的滚动位置信息
        // 当滚动到底部且还有更多数据时，加载更多信息
        if (scrollHeight - scrollTop === clientHeight && hasMore.current) {
            sysCommentPublicPageFn(); // 加载更多信息
        }
    };
    // 保存评论
    const saveSuccessFn = (newItem: any) => {
        const newDataList = [newItem, ...dataList];
        setDataList(newDataList);
    }
    // 重置
    const resetHandle = () => {
        currentPage.current = 1
        setDataList([])
    }
    // 组件加载时获取数据
    useEffect(() => {
        if (visible) {
            sysCommentPublicPageFn()
        } else {
            hasMore.current = true
        }

    }, [visible]); // 当分类ID变化时重新获取数据
    return (
        <Drawer width={700} title="Testimonial" onClose={() => { onClose(); resetHandle() }} open={visible}>
            <div className='w-full h-full flex flex-col overflow-hidden'>
                <div className='w-full flex-1 bg-[#F1F1F1] h-full px-12 py-6 custom_scrollbar  overflow-auto' ref={containerRef} onScroll={handleScroll}>
                    {dataList && dataList.length > 0 ? ( // 如果有游戏数据
                        <div className='space-y-5'>
                            {dataList.map((item: any, index: number) => (
                                <TestimonialCard item={item} key={index} />
                            ))
                            }
                        </div>
                    ) : (
                        <div className="h-10 leading-10 text-center text-[#707070]">No data available</div> // 没有数据时显示的信息
                    )}
                    <Spin spinning={loading} delay={500}> </Spin>
                </div>
                {userStore.state.token &&
                    <Add saveSuccess={(item) => { saveSuccessFn(item) }} />
                }
            </div>
        </Drawer>
    )
}
export default SeeAll