import { Input, Button, Modal, Popover, message } from 'antd';
import { useLocation } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { SearchOutlined, PlusOutlined, EllipsisOutlined, DeleteOutlined } from '@ant-design/icons'; //图表
import { getUsersMyFriends, friendsDelete } from "../../api/chat" //接口
import AddFriendModal from '../../components/ConversationalHub/AddFriend';
import chartStore from "../../store/chart"
import dayjs from 'dayjs' // 导入插件
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import { getVHtml } from "../../utils/parseContent"
import { observer } from 'mobx-react-lite'
import { searchStr } from '../../utils/index'
import { saveLastMessageFn } from '../../utils/readingMessages';
import AvatarImg from '../Public/AvatarImg';

interface LeftFriendListProps {
    onSelectChatObject: (item: any) => void;
    refresh: any;
    messageObj: any;
}
// 左侧朋友列表
const LeftFriendList: React.FC<LeftFriendListProps> = observer(({ onSelectChatObject, refresh, messageObj }) => {
    const location = useLocation();
    const isItFromSharing = location.state
    const [myFriendsList, setMyFriendsList] = useState<any[]>([]);//我的好友列表
    const myFriendsListTemp = useRef<any[]>([]);//我的好友列表 用于做模糊查询
    const [chatObject, setChatObject] = useState<any>(); //当前聊天对象
    const [isShowDel, setIsShowDel] = useState(false);//是否显示删除好友按钮
    const [delFriendsId, setDelFriendsId] = useState<string | number>();//删除好友id
    const [isAddFriendModalVisible, setIsAddFriendModalVisible] = useState(false);//添加朋友
    const [searchValue, setSearchValue] = useState<string>(''); // 搜索关键词
    // 发送消息时间转换
    dayjs.extend(utc)
    dayjs.extend(relativeTime)
    // 获取用户的所有好友
    const getUsersMyFriendsFn = async () => {
        try {
            const res = await getUsersMyFriends();
            if (res.data.code === 200 && res.data.data) {
                // 设置朋友列表数据
                await setMyFriendsList(res.data.data);

                // 更新消息和未读计数
                updateMessagesAndUnreadCount(res.data.data);

                // 为了筛选数据，深拷贝朋友列表到临时变量
                myFriendsListTemp.current = JSON.parse(JSON.stringify(res.data.data));
                const { charts } = chartStore.chartsState; // 获取聊天消息列表和好友请求列表
                // 遍历消息列表
                charts.forEach((message: any, index) => {
                    // 检查当前消息的 fromId 是否在 friendsArr 中的 id 中
                    const isFriend = myFriendsListTemp.current.some(friend => friend.id === message.fromId.toString());

                    if (!isFriend) {
                        charts.splice(index, 1);
                    }
                });
                // 更新仓库数据
                chartStore.setCharts(charts)
            }
        } catch (error) {
            console.log('获取朋友列表失败：', error);
        }
    };


    // 删除好友
    const delMyFriends = (e: React.MouseEvent<HTMLDivElement>) => {
        // 阻止事件冒泡
        e.stopPropagation();
        Modal.confirm({
            title: 'Delete',
            content: 'Are you sure you want to delete it.',
            okText: 'Confirm',
            cancelText: 'Cancel',
            centered: true,
            onOk: async () => {
                try {
                    const res = await friendsDelete(delFriendsId)
                    if (res.data.code === 200) {
                        message.success(res.data.msg)
                        getUsersMyFriendsFn()
                        onSelectChatObject('')
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }


    // 阻止点击三圆点是事件冒泡
    const preventBubbling = (id: string | number, event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setDelFriendsId(id)
    };
    // 打开添加朋友弹框
    const showAddFriendModal = () => {
        setIsAddFriendModalVisible(true);
    };
    // 关闭添加朋友弹框
    const closeAddFriendModal = () => {
        setIsAddFriendModalVisible(false);
    };
    // 自定义模糊查询
    const searchStrFn = () => {
        // 更新好友列表数据
        updateMessagesAndUnreadCount(searchStr({ name: searchValue }, myFriendsListTemp.current))
    }

    /**
     * 更新朋友列表中的未读消息数量和最新消息时间戳
     * @param myFriendsList 我的朋友列表
     * @param messageObj 最新的消息对象，包含 fromId, chatId, content, timestamp
     */
    const updateMessagesAndUnreadCount = (myFriendsList: any[]) => {
        if (!myFriendsList) return;

        const updatedFriendsList = [...myFriendsList];
        const chartsDataList: any[] = chartStore.chartsState.charts || [];
        if (messageObj) {
            // 更新最新消息内容和时间戳
            const { fromId, chatId, content, timestamp } = messageObj;
            const friendIndex = updatedFriendsList.findIndex(item =>
                Number(item.id) === Number(fromId) || Number(item.id) === Number(chatId)
            );

            if (friendIndex !== -1) {
                updatedFriendsList[friendIndex].msg = content;
                updatedFriendsList[friendIndex].timestamp = dayjs.utc(timestamp).fromNow(); // 使用 dayjs 格式化时间
            }
        }


        // 更新未读消息数量和时间戳
        updatedFriendsList.forEach(item => {
            const msgIndex = chartsDataList.findIndex((chart: any) =>
                Number(chart.fromId) === Number(item.id) || Number(chart.chatId) === Number(item.id)
            );

            if (msgIndex !== -1) {
                const chart = chartsDataList[msgIndex];
                item.unreadCount = chart.unreadCount;
                item.timestamp = dayjs.utc(chart.timestamp).fromNow(); // 使用 dayjs 格式化时间
            }
        });
        // 更新状态
        setMyFriendsList(updatedFriendsList);
    };

    // 选择聊天对象并更新消息数量
    const selectChatObject = (item: any) => {
        if (!item || !chartStore) return;

        const charsDataList: any[] = chartStore.chartsState['charts'] || [];
        const msgIndex = charsDataList.findIndex((chart: any) => Number(chart.fromId) === (Number(item.id)));

        if (msgIndex !== -1) {
            charsDataList[msgIndex]['hasItBeenRead'] = true;
            // 重新保存未读消息
            if (charsDataList[msgIndex]['unreadCount'] > 0) {
                saveLastMessageFn()
            }
            charsDataList[msgIndex]['unreadCount'] = 0;
        }

        chartStore.setCharts(charsDataList);
        updateMessagesAndUnreadCount(myFriendsList);
        onSelectChatObject(item);
        setChatObject(item)

    };

    // 在组件挂载后调用，获取朋友列表数据
    useEffect(() => {
        if (isItFromSharing && chartStore.chatObject) {
            selectChatObject(chartStore.chatObject);
        }
    }, [isItFromSharing, chartStore.chatObject]);

    // 更新最后消息
    useEffect(() => {
        if (myFriendsList) {
            updateMessagesAndUnreadCount(myFriendsList);
        }
    }, [messageObj, chartStore.chartsState.charts]);
    useEffect(() => {
        getUsersMyFriendsFn()
    }, [refresh]);


    // 三个圆点扩展功能
    const ThreeDotFunction = (
        <div className='w-[5rem] grid grid-cols-10 gap-2'>
            <div className='flex space-x-2 text-lg font-normal text-[#232323] cursor-pointer'
                onClick={(e) => delMyFriends(e)}>
                <DeleteOutlined />
                <span>Delete</span>
            </div>
        </div>
    );
    return (
        <div className="w-[34rem] h-full flex flex-col justify-between border-r border-[#D2D2D2] overflow-hidden ">
            <div className="w-full flex-1 overflow-auto">
                <div className="w-full pl-14 pr-5  py-5">
                    <Input
                        className='w-full h-12 rounded-full bg-[#F1F1F1]'
                        placeholder="Search name"
                        onPressEnter={searchStrFn}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        suffix={<SearchOutlined style={{ color: '#232323', fontSize: '1.25rem' }} />}
                    />
                </div>
                <div className='w-full space-y-5'>
                    <div className=' space-y-2' >
                        <span className='pl-14 pr-5 text-base font-medium text-[#232323]'>Pinned</span>
                        <div className='space-y-2'>
                            {myFriendsList.map((item) => (
                                <div key={item.id}
                                    className={`flex items-center justify-between bg-[${chatObject?.id === item.id ? '#F6F8F8' : ''}] 
                                    py-5 pl-14 pr-5 cursor-pointer hover:bg-[#F6F8F8]`}
                                    onClick={() => selectChatObject(item)}
                                    onMouseEnter={() => setIsShowDel(true)}
                                    onMouseLeave={() => setIsShowDel(false)}
                                >
                                    <div className='flex w-1/2items-center space-x-2'>
                                        <div className=' w-14 h-14 bg-[#F1F1F1] rounded-full overflow-hidden'>
                                            <AvatarImg id={item.id}/>
                                        </div>
                                        <div className='flex-1'>
                                            <p className=' text-lg font-medium text-[#275576]'>{item.name}</p>
                                            <div className='w-full h-[1.375rem] overflow-hidden flex flex-wrap break-all text-base font-normal text-[#707070] rounded-md'
                                                dangerouslySetInnerHTML={getVHtml(item.msg)}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex justify-end w-1/2'>
                                        <div className='flex items-center space-x-2 '>
                                            <span className='flex-1 u-line-1'> {item.timestamp}</span>
                                            {item.unreadCount > 0 &&
                                                <span className='bg-[#cdc18a] w-6 h-6 text-sm text-center leading-6 rounded-full '>
                                                    {item.unreadCount}
                                                </span>
                                            }
                                        </div>

                                        <Popover content={ThreeDotFunction} title="" trigger="click">
                                            <div className=' rotate-90 text-2xl cursor-pointer'
                                                style={{ opacity: isShowDel ? 1 : 0 }}
                                                onClick={(e) => preventBubbling(item.id, e)} >
                                                <EllipsisOutlined />
                                            </div>
                                        </Popover>
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full pl-14 pr-5 pb-7 mt-5">
                <Button shape="round"
                    style={{ background: '#275576', borderColor: '#275576' }}
                    className='w-full h-11 flex items-center justify-center space-x-2 text-white text-base'
                    onClick={showAddFriendModal}>
                    <PlusOutlined />
                    <span className=' text-base font-medium '> Add</span>
                </Button>
            </div>
            <AddFriendModal visible={isAddFriendModalVisible} onClose={closeAddFriendModal} />
        </div>
    )
})
export default LeftFriendList