// 兴趣爱好图标组件
const ArtCraft = () => {
    return (
        
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" >
            <mask id="mask0_983_8786" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                <rect x="0.5" y="0.199951" width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_983_8786)">
                <path d="M5.49996 17.7C4.87496 17.7 4.2569 17.5472 3.64579 17.2416C3.03468 16.9361 2.54163 16.5333 2.16663 16.0333C2.52774 16.0333 2.89579 15.8909 3.27079 15.6062C3.64579 15.3215 3.83329 14.9083 3.83329 14.3666C3.83329 13.6722 4.07635 13.0819 4.56246 12.5958C5.04857 12.1097 5.63885 11.8666 6.33329 11.8666C7.02774 11.8666 7.61801 12.1097 8.10413 12.5958C8.59024 13.0819 8.83329 13.6722 8.83329 14.3666C8.83329 15.2833 8.5069 16.068 7.85413 16.7208C7.20135 17.3736 6.41663 17.7 5.49996 17.7ZM5.49996 16.0333C5.95829 16.0333 6.35065 15.8701 6.67704 15.5437C7.00343 15.2173 7.16663 14.825 7.16663 14.3666C7.16663 14.1305 7.08676 13.9326 6.92704 13.7729C6.76732 13.6132 6.5694 13.5333 6.33329 13.5333C6.09718 13.5333 5.89926 13.6132 5.73954 13.7729C5.57982 13.9326 5.49996 14.1305 5.49996 14.3666C5.49996 14.6861 5.46176 14.9777 5.38538 15.2416C5.30899 15.5055 5.20829 15.7555 5.08329 15.9916C5.15274 16.0194 5.22218 16.0333 5.29163 16.0333H5.49996ZM10.2916 12.7L7.99996 10.4083L15.4583 2.94996C15.6111 2.79718 15.802 2.71732 16.0312 2.71038C16.2604 2.70343 16.4583 2.78329 16.625 2.94996L17.75 4.07496C17.9166 4.24163 18 4.43607 18 4.65829C18 4.88052 17.9166 5.07496 17.75 5.24163L10.2916 12.7Z" 
                 fill="currentColor" />
            </g>
        </svg>
    )
}
export default ArtCraft