

import { makeAutoObservable, autorun } from 'mobx';
// 头部跳转数据
class HeadNavigation {
    item = { backTo: '', goName: '', currentName: '',idType:'' }
    constructor() {
        makeAutoObservable(this);
        // 从 sessionStorage 加载数据
        const storedItem = sessionStorage.getItem('headNavigationItem');
        if (storedItem) {
            this.item = JSON.parse(storedItem);
        }
        // 监听 item 的变化，当发生变化时保存到 sessionStorage
        autorun(() => {
            sessionStorage.setItem('headNavigationItem', JSON.stringify(this.item));
        });
    }

    setItem(item: any) {
        this.item = item;
    }
    clearData() {
        this.item =  { backTo: '', goName: '', currentName: '',idType:'' }
    }
}

export default new HeadNavigation();
