import { makeAutoObservable, autorun } from 'mobx';
// 缓存字典数据
class dictStore {
    state = {
        article_type: [], //公告 文章类型
        categoryData: [], //公告左侧分类
        article_tags: [],//公告 tags类型
        activity_category: [],//字典:activity_category(左侧类目，Art & culture等)
        activity_type: [],//字典:activity_type(上面的类别，Online等)
    }
    constructor() {
        makeAutoObservable(this);
        const storedItem = sessionStorage.getItem('dictData');
        if (storedItem) {
            this.state = JSON.parse(storedItem);
        }
        autorun(() => {
            sessionStorage.setItem('dictData', JSON.stringify(this.state));
        });
    }
    setArticleType(val: any) {
        this.state.article_type = val;
    }
    setCategoryData(val: any) {
        this.state.categoryData = val;
    }
    setArticleTags(val: any) {
        this.state.article_tags = val;
    }
    setActivityCategory(val: any) {
        this.state.activity_category = val;
    }
    setActivityType(val: any) {
        this.state.activity_type = val;
    }
    clearData() {
        this.state = {
            article_type: [], //公告 文章类型
            categoryData: [], //公告左侧分类
            article_tags: [],//公告 tags类型
            activity_category: [],//字典:activity_category(左侧类目，Art & culture等)
            activity_type: [],//字典:activity_type(上面的类别，Online等)
        }
    }
}

export default new dictStore();
