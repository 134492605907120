import { Outlet } from "react-router-dom";
import { useEffect, useCallback } from 'react';
import { getDictDataList } from '../../../api/dict';
import dictStore from '../../../store/dict';
import { observer } from 'mobx-react-lite';
const EventsActivities = observer(() => {
    // 通用数据获取函数，包含错误处理
    const fetchData = useCallback(async (dictType:any, setDataFn:any, errorMsg:any) => {
        try {
            const res = await getDictDataList({ dictType }); // 获取字典数据
            setDataFn(res.data.rows); // 更新store数据
        } catch (error) {
            console.error(errorMsg, error); // 错误处理
        }
    }, []);

    // 获取左侧类目数据
    const fetchActivityCategory = useCallback(async () => {
        await fetchData('activity_category', dictStore.setActivityCategory.bind(dictStore), '获取左侧类目数据出错:');
    }, [fetchData]);

    // 获取上面的类别，Online等
    const fetchActivityType = useCallback(async () => {
        await fetchData('activity_type', dictStore.setActivityType.bind(dictStore), '获取标签类型时出错:');
    }, [fetchData]);

    // 获取文章类型数据
    useEffect(() => {
        fetchActivityCategory();
        fetchActivityType();
    }, [fetchActivityCategory, fetchActivityType]);
    
    return (<Outlet />);
});

export default EventsActivities;
