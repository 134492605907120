import React, { useEffect, useState } from 'react';
import { Button, message, Modal } from 'antd';
import { HeartOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import dictStore from '../../store/dict';
import indexStore from '../../store/index';
import detailsStore from '../../store/Details'
import userStore from '../../store/user'
import { addFavorites, delVideoImgInfo, countViewingQuantity } from '../../api/healthOnline'

// 定义类型
interface CardItemProps {
    item: any;
    onUpdate: () => void;
    isShowHeard?: boolean;//是否被收藏
}
// 列表卡片
const CardItem: React.FC<CardItemProps> = ({ item, onUpdate, isShowHeard = false }) => {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();
    const userInfo = userStore.state.userInfo//用户信息
    const [moduleType] = useState<number>(indexStore.menusState.moduleType); //类型
    const [typeLabe, setTypeLabe] = useState<string>('');
    const [isCurrentUser, setIsCurrentUser] = useState<boolean>(false);
    const [isFavorites, setIsFavorites] = useState<number>(item.isFavorites); //更改收藏的状态
    // 用于处理导航到详细信息页面的功能
    const navigationTypeJump = async () => {
        // 添加观看量
        await countViewingQuantity(item.id)
        navigate(`/healthOnline/details?id=${item.id}`);
    };

    // 通过dict获取标签的函数
    const getDictLabel = (type: number) => {
        const dictItem = dictStore.state.article_type.find((item: any) => item.dictCode === type) || { dictLabel: '' };
        return dictItem.dictLabel;
    }
    //获取 article_tags类型数据label 
    const getCategoryType = (tagCode: number) => {
        const dictItem = dictStore.state.article_tags.find((item: any) => item.dictCode === Number(tagCode)) || { dictLabel: '' };
        return dictItem.dictLabel;
    }
    // 添加到收藏夹
    const addFavoritesFn = async (articleId: string, type: number | null, e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();  // 阻止事件冒泡
        try {
            const res = await addFavorites({ articleId, type, favorites: indexStore.menusState.moduleType })
            if (res.data.code === 200) {
                message.success(res.data.msg);
                if (!isShowHeard) {
                    setIsFavorites(isFavorites === 1 ? 0 : 1)
                } else {
                    // 通知父组件更新数据
                    onUpdate();
                }

            }
        } catch (error) {
            console.log(error)
        }
    }
    // 删除视频音频信息
    const delVideoImgInfoFn = (id: number, e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();  // 阻止事件冒泡
        Modal.confirm({
            title: 'Delete',
            content: 'Are you sure you want to delete it.',
            okText: 'Confirm',
            cancelText: 'Cancel',
            centered: true,
            onOk: async () => {
                try {
                    const res = await delVideoImgInfo([id])
                    if (res.data.code === 200) {
                        message.success(res.data.msg);
                        // 通知父组件更新数据
                        onUpdate();
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }
    // 编辑游戏信息
    const editVideoImgInfoFn = (item: any, e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();  // 阻止事件冒泡
        detailsStore.setDetails({ ...item })
        navigate(`/healthOnline/addOrEdit?type=edit&id=${item.id}`);
    }

    // 更新标签
    useEffect(() => {
        const label = getDictLabel(item.type);
        setTypeLabe(label);
    }, [item.type, getDictLabel]);
    // 是否可以进行编辑/删除 管理员可以对所有模块进行增删改 class模块只能由管理员增删改
    useEffect(() => {
        if (moduleType === 1) {
            // 当 moduleType 为 1 时的条件
            const isCurrentUser = userInfo.userType === '00' || Number(userInfo.id) === Number(item.createBy);
            setIsCurrentUser(isCurrentUser)
        } else {
            // 当 moduleType 不为 1 时的条件
            const isCurrentUser = userInfo.userType === '00';
            setIsCurrentUser(isCurrentUser)
        }
    }, []);
    return (
        <div className='p-5 border border-[#D2D2D2] flex flex-col justify-between items-start rounded-xl space-y-2 cursor-pointer'
            onClick={navigationTypeJump}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <div className='w-full relative pb-[13.875rem] bg-[#CCCCCC] rounded-md overflow-hidden'>
                <img
                    className='w-full h-full object-cover absolute top-0 left-0 rounded-md'
                    src={item.bannerPath}
                    alt={`${item.id}`}
                />
                <div className='absolute z-10 bottom-2 left-2 flex space-x-2'>
                    {item.contentTags && item.contentTags.split(',').map((_item: any, index: number) => (
                        <span key={index} className="bg-white rounded-2xl py-2 px-3">
                            {getCategoryType(_item)}
                        </span>
                    ))}
                </div>
                <div
                    className={`w-10 h-10 absolute bottom-2 right-2 flex justify-center z-10 cursor-pointer rounded-full bg-white text-2xl 
                    ${isFavorites === 1 || isShowHeard ? 'text-[#EA5E5E]' : 'text-[#FFFFFF] bg-opacity-10 backdrop-blur-[6.099999904632568px]'} 
                     hover:text-[#EA5E5E] `}
                    onClick={(e) => addFavoritesFn(item.id, item.type, e)}>
                    <HeartOutlined />
                </div>
                {isHovered && isCurrentUser &&
                    <div className='absolute top-2 left-2 flex space-x-1'>
                        <div className={`w-10 h-10  flex justify-center z-10 cursor-pointer rounded-full
                    bg-white bg-opacity-10 text-2xl text-[#275576] backdrop-blur-[6.099999904632568px]`}
                            onClick={(e) => editVideoImgInfoFn(item, e)}>
                            <EditOutlined />
                        </div>
                        <div className={`w-10 h-10  flex justify-center z-10 cursor-pointer rounded-full
                    bg-white bg-opacity-10 text-2xl text-[#EA5E5E] backdrop-blur-[6.099999904632568px]`}
                            onClick={(e) => delVideoImgInfoFn(item.id, e)}>
                            <DeleteOutlined />
                        </div>
                    </div>
                }
            </div>
            <p className="text-2xl font-medium text-[#232323] u-line-1">{item.title}</p>
            <div className="text-xl font-normal text-[#707070] u-line-1">
                {item.subtitle}
            </div>
            <Button shape={'round'}>
                <div className='w-auto h-full flex items-center justify-center space-x-2'>
                    {typeLabe && (
                        <img
                            className='w-6 h-6'
                            src={require(`../../assets/images/healthWellness/${typeLabe}.svg`)}
                            alt={typeLabe}
                        />
                    )}
                    <div className='text-base font-medium text-[#232323]'>
                        {typeLabe === 'Video' ? 'Viewed by:' : 'Read by:'} {item.viewingQuantity}
                    </div>
                </div>
            </Button>
        </div>
    );
};

export default CardItem;
