const Outdoor = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
            <mask id="mask0_983_8776" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                <rect x="0.5" y="0.199951" width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_983_8776)">
                <path d="M4.66665 18.5334V16.8667H9.66665V13.5334H7.99998C6.8472 13.5334 5.86456 13.1271 5.05206 12.3146C4.23956 11.5021 3.83331 10.5195 3.83331 9.3667C3.83331 8.53337 4.06248 7.76601 4.52081 7.06462C4.97915 6.36323 5.5972 5.85281 6.37498 5.53337C6.49998 4.4917 6.95484 3.62017 7.73956 2.91878C8.52429 2.21739 9.44442 1.8667 10.5 1.8667C11.5555 1.8667 12.4757 2.21739 13.2604 2.91878C14.0451 3.62017 14.5 4.4917 14.625 5.53337C15.4028 5.85281 16.0208 6.36323 16.4791 7.06462C16.9375 7.76601 17.1666 8.53337 17.1666 9.3667C17.1666 10.5195 16.7604 11.5021 15.9479 12.3146C15.1354 13.1271 14.1528 13.5334 13 13.5334H11.3333V16.8667H16.3333V18.5334H4.66665ZM7.99998 11.8667H13C13.6944 11.8667 14.2847 11.6236 14.7708 11.1375C15.2569 10.6514 15.5 10.0611 15.5 9.3667C15.5 8.8667 15.3576 8.40837 15.0729 7.9917C14.7882 7.57503 14.4166 7.26948 13.9583 7.07503L13.0833 6.70003L12.9583 5.7417C12.875 5.1167 12.6007 4.59239 12.1354 4.16878C11.6701 3.74517 11.125 3.53337 10.5 3.53337C9.87498 3.53337 9.32984 3.74517 8.86456 4.16878C8.39928 4.59239 8.12498 5.1167 8.04165 5.7417L7.91665 6.70003L7.04165 7.07503C6.58331 7.26948 6.21179 7.57503 5.92706 7.9917C5.64234 8.40837 5.49998 8.8667 5.49998 9.3667C5.49998 10.0611 5.74304 10.6514 6.22915 11.1375C6.71526 11.6236 7.30554 11.8667 7.99998 11.8667Z" 
                fill="currentColor" />
            </g>
        </svg>
    )
}
export default Outdoor