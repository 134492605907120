import React, { useState } from 'react';
import { Input, Modal, message } from 'antd';
import { friendsAdd, usersSearch } from '../../api/chat';
import BasicSelect from '../Public/BasicSelect';

interface AddFriendModalProps {
    visible?: boolean;
    onClose: () => void;
}

interface UserValue {
    label: string;
    value: string;
}

const AddFriendModal: React.FC<AddFriendModalProps> = ({ visible = false, onClose }) => {
    const [friendAccount, setFriendAccount] = useState<string>('');
    const [friendAccountContent, setFriendAccountContent] = useState<string>('');
    const [resetTrigger, setResetTrigger] = useState<boolean>(false); // 控制重置的状态
    const [selectedValue, setSelectedValue] = useState<UserValue | null>(null); // 管理选中的值
    // 根据邮箱搜索账户
    const fetchUserList = async (username: string): Promise<UserValue[]> => {
        try {
            if (username.trim() !== '') {
                const res: any = await usersSearch(username);
                if (res.data.code === 200 && res.data.data) {
                    return res.data.data.map((user: any) => ({
                        label: user.email,
                        value: user.id,
                    }));
                } else {
                    return [];
                }
            } else {
                return []
            }
        } catch (error) {
            console.error('Error fetching user list:', error);
            return [];
        }
    };
    // 选择账户
    const handleSelectionChange = (item: UserValue | UserValue[]) => {
        if (!Array.isArray(item)) {
            setFriendAccount(item.value);
            setSelectedValue(item); // 更新选中的值
        }
    };
    // 保存添加
    const addFriendModalHandleOk = async () => {
        try {
            const res = await friendsAdd({ timestamp: new Date().getTime(), chatId: friendAccount, content: friendAccountContent });
            if (res.data.code === 200) {
                message.success(res.data.msg)
                handleClose();
            }
        } catch (error) {
            console.log(error)
        }
    };
    // 关闭弹框
    const handleClose = () => {
        setFriendAccount('');
        setFriendAccountContent('');
        setSelectedValue(null); // 重置选中的值
        setResetTrigger(!resetTrigger); // 切换 resetTrigger 状态以触发重置
        onClose();
    };

    return (
        <Modal
            title="Add friends"
            centered
            open={visible}
            onOk={addFriendModalHandleOk}
            onCancel={handleClose}
        >
            <div className='w-full space-y-2'>
                <div>
                    <span>Account number</span>
                    <BasicSelect
                        placeholder="Please enter account number"
                        fetchOptions={fetchUserList}
                        onSelectionChange={handleSelectionChange}
                        style={{ width: '100%', height: '56px' }}
                        resetTrigger={resetTrigger} // 传递 resetTrigger
                        value={selectedValue ?? undefined} // 如果 selectedValue 是 null，传递 undefined
                    />
                </div>
                <div>
                    <span>Content</span>
                    <Input
                        placeholder="Please enter content"
                        style={{ width: '100%', height: '56px' }}
                        value={friendAccountContent}
                        onChange={(e) => setFriendAccountContent(e.target.value)}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default AddFriendModal;
