import { getLastMessage, saveLastMessage } from '../api/chat'
import chartStore from "../store/chart"
import userStore from "../store/user"
/**
 * 获取聊天未读消息记录
 */
export const getLastMessageFn = async () => {
    try {
        const res = await getLastMessage();
        if (res.data.code === 200 && res.data.data) {
            const lastMsgData: any[] = res.data.data || []
            lastMsgData.map((item_lastMsgData: any) => {
                if (item_lastMsgData.type === '1') {
                    const charsDataList: any[] = chartStore.chartsState['charts']
                    // 查找是否已存在相同 id 的对象
                    let existingIndex = charsDataList.findIndex(item => Number(item.fromId) === Number(item_lastMsgData.chatId));
                    const mstTemp = {
                        hasItBeenRead: false, //消息未读
                        content: item_lastMsgData.lastMessage,
                        messageType: '0',
                        fromId: item_lastMsgData.chatId,
                        unreadCount: item_lastMsgData.messageCount || 0,
                        timestamp: item_lastMsgData.timestamp,
                        chatId: item_lastMsgData.userId,
                    }
                    if (existingIndex !== -1) {
                        // 如果找到了，更新数据并增加 unreadCount
                        charsDataList[existingIndex] = mstTemp;
                    } else {
                        // 如果未找到，添加新的数据到数组中
                        charsDataList.push(mstTemp);
                    }
                    chartStore.setCharts(charsDataList)
                } else {
                    const requestSDataList: any[] = chartStore.chartsState['requestS']
                    // 查找是否已存在相同 id 的对象
                    let existingIndex = requestSDataList.findIndex(item => Number(item.fromId) === Number(item.chatId));
                    const requestSDataListMsg = {
                        ...item_lastMsgData,
                        messageType: item_lastMsgData.type,
                        content: item_lastMsgData.lastMessage,
                        hasItBeenRead: false, //消息未读
                        unreadCount: item_lastMsgData.messageCount || 0,
                        fromId: item_lastMsgData.chatId,
                        chatId: item_lastMsgData.userId,
                        timestamp: item_lastMsgData.timestamp,
                    }
                    if (existingIndex !== -1) {
                        // 如果找到了，更新数据并增加 unreadCount
                        requestSDataList[existingIndex] = requestSDataListMsg;
                    } else {
                        // 如果未找到，添加新的数据到数组中
                        requestSDataList.push(requestSDataListMsg);
                    }
                    chartStore.setRequestS(requestSDataList)
                    chartStore.setHasItBeenRead(false) //将状态设置为未读
                }
            })

            // 处理获取到的数据
        }
    } catch (error) {
        console.error('Error fetching last message:', error);
    }
};
/**
 * 保留最后一条聊天记录与未读数量
 */
export const saveLastMessageFn = async () => {
    try {
        const dataList: any = [...chartStore.chartsState.charts, ...chartStore.chartsState.requestS];
        if (!dataList.length) {
            return;
        }

        const hasItBeenRead = chartStore.chartsState.hasItBeenRead;

        const newArray = dataList
            .filter((item: any) => {
                if (hasItBeenRead) {
                    return item.hasItBeenRead === false && !(item.messageType === '5');
                } else {
                    return true;
                }
            })
            .map((item: any) => ({
                userId: item.chatId,
                chatId: item.fromId,
                lastMessage: item.content,
                messageCount: item.unreadCount || 0,
                timestamp: item.timestamp,
                type: item.messageType === '0' || item.messageType === '2' ? '1' : item.messageType,
            }));

        // 调用保存最后一条消息的函数 saveLastMessage
        await saveLastMessage(newArray);
    } catch (error) {
        console.error('保存最后一条消息时出错:', error);
    }
};


/**
 * 处理接收到的消息，更新未读消息列表和好友请求列表
 * @param message 接收到的消息对象
 */
export const processingMessages = (message: any) => {
    const userInfo = userStore.state.userInfo; // 获取当前用户信息
    const { charts, requestS } = chartStore.chartsState; // 获取聊天消息列表和好友请求列表
    // 0 2 都是文字消息
    if (message.messageType === '0' || message.messageType === '2') {
        // 处理聊天消息
        processChatMessage(message, charts, userInfo);
    } else {
        // 处理其他类型的消息（如好友请求）
        processOtherMessage(message, requestS);
    }
};

/**
 * 处理聊天消息
 * @param message 接收到的消息对象
 * @param charts 聊天消息列表
 * @param userInfo 当前用户信息
 */
const processChatMessage = (message: any, charts: any[], userInfo: any) => {
    const chatObject = chartStore.chatObject; // 获取当前聊天对象
    const existingIndex = findMessageIndex(charts, message); // 查找消息在聊天列表中的索引

    if (existingIndex !== -1) {
        // 更新已存在的聊天记录
        updateExistingChat(charts, existingIndex, message, chatObject, userInfo);
    } else {
        // 添加新的聊天记录
        addNewChat(charts, message, userInfo);
    }

    // 更新聊天消息列表状态
    chartStore.setCharts([...charts]);
};

/**
 * 查找消息在聊天列表中的索引
 * @param charts 聊天消息列表
 * @param message 接收到的消息对象
 * @returns 消息在聊天列表中的索引，如果不存在返回 -1
 */
const findMessageIndex = (charts: any[], message: any) => {
    return charts.findIndex(item => Number(item.fromId) === Number(message.fromId) || Number(item.fromId) === Number(message.chatId));
};

/**
 * 更新已存在的聊天记录
 * @param charts 聊天消息列表
 * @param existingIndex 已存在聊天记录的索引
 * @param message 接收到的消息对象
 * @param chatObject 当前聊天对象
 * @param userInfo 当前用户信息
 */
const updateExistingChat = (charts: any[], existingIndex: number, message: any, chatObject: any, userInfo: any) => {
    const timesTemp = charts[existingIndex].timesTemp || [];
    const timestampExists = timesTemp.includes(message.timestamp);
    if (!timestampExists) {
        let unreadCount = charts[existingIndex].unreadCount || 0;
        if (!(chatObject && Number(chatObject.id) === Number(message.fromId))) {
            unreadCount = Number(userInfo.id) === Number(message.fromId) ? unreadCount : unreadCount + 1;
        }
        charts[existingIndex] = {
            ...charts[existingIndex],
            content: message['content'],
            timestamp: message['timestamp'],
            unreadCount,
            timesTemp: [...timesTemp, message.timestamp],
            hasItBeenRead: false,
        };
    }
};

/**
 * 添加新的聊天记录
 * @param charts 聊天消息列表
 * @param message 接收到的消息对象
 * @param userInfo 当前用户信息
 */
const addNewChat = (charts: any[], message: any, userInfo: any) => {
    const chatObject = chartStore.chatObject; // 获取当前聊天对象
    let unreadCount = (Number(userInfo.id) === Number(message.fromId) || chatObject?.id === message.fromId) ? 0 : 1;
    charts.push({
        ...message,
        hasItBeenRead: false,
        unreadCount,
        timesTemp: [message.timestamp],
    });
};

/**
 * 处理非聊天消息（如好友请求）
 * @param message 接收到的消息对象
 * @param requestS 好友请求列表
 */
const processOtherMessage = (message: any, requestS: any[]) => {
    const userInfo = userStore.state.userInfo; // 获取当前用户信息
    // 不处理自己发送的消息
    if (Number(userInfo.id) === Number(message.fromId)) return
    const existingIndex = requestS.findIndex(item => Number(item.fromId) === Number(message.fromId) && item.messageType === message.messageType);

    if (existingIndex !== -1) {
        // 更新已存在的请求记录
        requestS[existingIndex] = {
            ...requestS[existingIndex],
            content: message['content'],
            timestamp: message['timestamp'],
            hasItBeenRead: false,
        };
    } else {
        // 添加新的请求记录
        requestS.push({
            ...message,
            hasItBeenRead: false,
        });
    }

    // 更新好友请求列表状态
    chartStore.setHasItBeenRead(false)
    chartStore.setRequestS([...requestS]);
};
