import { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import indexStore from '../../store/index'
import * as utilsAws from '../../utils/aws';
import userStore from "../../store/user"

interface NavigationData {
    title: string;
    src: string;
    path: string;
    btnTitle: string;
}
// 首页卡片
const HomeCard = ({ navigationData_item, index }: { navigationData_item: NavigationData, index: number }) => {
    const isTokenAvailable = userStore.state.token;
    const [imageUrls, setImageUrls] = useState<any>('');
    const navigate = useNavigate();
    // 跳转
    const navigationTypeJump = (item: any) => {
        if (item.type) {
            indexStore.setModuleType(item.type)
        }
        navigate(item.path);
    }
    // 获取图片地址
    useEffect(() => {
        const fetchImageUrls = async () => {
            try {
                const utilsAwsRes = await utilsAws.getAwsImgUrl(`home/${navigationData_item.src}.png`)
                setImageUrls(utilsAwsRes);
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };
        fetchImageUrls();
    }, [navigationData_item.src]);

    return (
        <div className='px-5 py-5 border border-[#D2D2D2] rounded-xl overflow-hidden'>
            <div className='relative pb-44'>
                <img
                    className='w-full h-full object-cover absolute top-0 left-0 rounded-md'
                    style={{ opacity: isTokenAvailable || index < 2 ? '1' : '0.5' }}
                    src={imageUrls}
                    alt={navigationData_item.title}
                />
                {(!isTokenAvailable && index > 1) && (
                    <div className='w-10 h-10 bg-white bg-opacity-20 rounded-full absolute right-2 bottom-2 text-white text-center leading-10 text-xl'>
                        <LockOutlined />
                    </div>
                )}
            </div>
            <p className='text-2xl font-medium text-[#232323] mt-6 mb-2'>{navigationData_item.title}</p>
            <Button
                shape="round"
                className='w-full flex justify-center items-center bg-[#E9EEF1] py-5 space-x-1'
                onClick={() => navigationTypeJump(navigationData_item)}
                disabled={isTokenAvailable || index < 2 ? false : true}
                style={{ opacity: isTokenAvailable || index < 2 ? '1' : '0.5' }}
            >
                <img src={require('../../assets/images/home/send.png')} alt='send' className='w-5 h-5' />
                <span className='text-base font-medium text-[#275576]' >{navigationData_item.btnTitle}</span>
            </Button>
        </div>
    )
}

export default HomeCard;
