import { Modal, Button, Input, Radio } from 'antd';
import { useState, useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { getUsersMyFriends } from "../../api/chat" //接口
import { sendMessage } from '../../utils/websocketManager';
import userStore from "../../store/user"
import SendCode from '../../utils/SendCode'// 发送消息状态
import { useNavigate } from 'react-router-dom';
import CopyTextButton from './CopyTextButton';
import { searchStr } from '../../utils/index'
import chartStore from '../../store/chart'
import HeadNavigation from '../../store/HeadNavigation';
import type { RadioChangeEvent } from 'antd';
import AvatarImg from './AvatarImg';
// 分享
const ShareWith = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [myFriendsList, setMyFriendsList] = useState<any[]>([]);//我的好友列表
    const myFriendsListTemp = useRef<any[]>([]);//我的好友列表 用于做模糊查询
    const [userInfo] = useState<any>(userStore.state.userInfo) //用户信息
    const [chatItem, setChatItem] = useState<any>() //分享到好友的id
    const [searchValue, setSearchValue] = useState<string>(''); // 搜索关键词
    const navigate = useNavigate();
    // 获取用户的所有好友
    const getUsersMyFriendsFn = async () => {
        try {
            const res = await getUsersMyFriends();
            if (res.data.code === 200 && res.data.data) {
                setMyFriendsList(res.data.data)
                myFriendsListTemp.current = JSON.parse(JSON.stringify(res.data.data))
            }
        } catch (error) {
            console.log(error)
        }
    }
    // 显示弹框
    const showModal = () => {
        setIsModalOpen(true);
        // 获取朋友列表数据
        getUsersMyFriendsFn();

    };
    // 关闭弹框
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // 选择分享的好友
    const onChange = (e: RadioChangeEvent) => {
        setChatItem(e.target.value)
    };
    // 获取消息组件传递过来的值 发送消息
    const sendMessagehandle = () => {
        const linkAddress = window.location.href;
        const msg: any = {
            messageType: '0',
            chatId: chatItem.id,
            fromId: userInfo.id,
            mine: true,
            timestamp: new Date().getTime(),
            content: linkAddress,
            type: SendCode.SINGLECHAT, // 单聊
        };
        const sendInfo = {
            code: SendCode.MESSAGE, //发送消息
            message: msg,
        };
        // 通过ws 发送消息
        sendMessage(JSON.stringify(sendInfo))
        Modal.confirm({
            title: 'Sharing successful',
            okText: 'Go to the chat page',
            cancelText: 'Stay on current page',
            centered: true,
            onOk: async () => {
                chartStore.setChatObject(chatItem)
                const { backTo, goName, currentName } = HeadNavigation.item
                HeadNavigation.setItem({ backTo, goName, currentName });
                // 进入好友聊天页面
                navigate('/conversationalHub/home', { state: { isItFromSharing: true } })
            }
        });


    }
    // 自定义模糊查询
    const searchStrFn = () => {
        setMyFriendsList(searchStr({ name: searchValue }, myFriendsListTemp.current))
    }
    return (
        <div>
            {userStore.state.token &&
                <div className="flex items-center cursor-pointer space-x-2 text-base font-medium text-[#275576]"
                    onClick={showModal}
                >
                    <img src={require('../../assets/images/public/share.png')} alt='share' />
                    <span>Share</span>

                </div>
            }
            <Modal title="" centered open={isModalOpen}
                footer={null}
                width={700}
            >
                <div className='flex flex-col space-y-7 max-h-[43.75rem] overflow-hidden custom_scrollbar'>
                    <div className='flex items-center justify-between'>
                        <span className=' text-2xl font-medium text-[#232323]'>Share with</span>
                        <CopyTextButton textToCopy={window.location.href} />
                    </div>
                    <Input
                        className='w-full h-12 rounded-full bg-[#F1F1F1]'
                        placeholder="Search by name"
                        onPressEnter={searchStrFn}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        suffix={<SearchOutlined style={{ color: '#232323', fontSize: '1.25rem' }} />}
                    />
                    <Radio.Group className=' w-full flex-1 overflow-auto' onChange={onChange}>
                        <div className='w-full h-full'>
                            {myFriendsList.map((item) => (
                                <div key={item.id} className='flex items-center justify-between border-b border-[#DEDEDE] p-5'>
                                    <div className='flex items-center space-x-4 '>
                                        <div className=' w-12 h-12 bg-[#F1F1F1] rounded-full overflow-hidden'>
                                            <AvatarImg id={item.id} />
                                        </div>
                                        <p className=' text-base font-medium text-[#232323]'>{item.name}</p>
                                    </div>
                                    <div>
                                        <Radio value={item}></Radio>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Radio.Group>

                    <div className='flex space-x-5 px-10'>
                        <Button shape="round"
                            style={{ background: '#E9EEF1' }}
                            className='w-full h-11'
                            onClick={handleCancel} >
                            <span className=' text-base font-medium text-[#275576] '>Cancel</span>
                        </Button>
                        <Button
                            shape="round"
                            className='w-full flex justify-center items-center bg-[#E9EEF1] py-5 space-x-1'
                            disabled={!chatItem ? true : false}
                            onClick={sendMessagehandle}
                        >
                            <img src={require('../../assets/images/home/send.png')} alt='send' className='w-5 h-5' />
                            <span className='text-base font-medium text-[#275576]' >Send</span>
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export default ShareWith