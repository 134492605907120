import { Button, Modal, Form, Input } from 'antd';
import { useState, useEffect } from 'react';
import type { FormProps } from 'antd';
import { editDictData, addDictData } from '../../api/dict';
interface AddOrEditProps {
    visible?: boolean;
    onClose: () => void; //关闭弹框
    saveSuccess: () => void; //保存成功
    dictItem?: any
}
type FieldType = {
    dictLabel: string,
    dictValue: string,
};
// 新增编辑关于我们
const AddOrEdit: React.FC<AddOrEditProps> = ({ visible = false, onClose, dictItem, saveSuccess }) => {
    const [initialValues] = useState<any>(dictItem || {}); //初始表单值
    const [loading, setLoading] = useState<boolean>(false); // 加载状态
    const [form] = Form.useForm();
    // 保存
    const saveUserInfo: FormProps<FieldType>['onFinish'] = async (values) => {
        try {
            const params = {
                ...values,
                dictType: 'connect_with_us',
                dictCode: dictItem ? dictItem.dictCode : undefined
            };

            let res;
            setLoading(true)
            if (dictItem.dictCode) {
                res = await editDictData(params);
            } else {
                res = await addDictData(params);
            }
            setLoading(false)
            if (res.data.code === 200) {
                saveSuccess();
                onClose();
            }
        } catch (error) {
            // 处理异常情况
            console.error('保存失败:', error);
            setLoading(false)
        }
    };
    useEffect(() => {
        if (dictItem) {
            form.setFieldsValue(dictItem); // 使用 form.setFieldsValue 设置初始值
        }
    }, [dictItem, form]);
    return (
        <Modal
            title="Connect with us"
            width={600}
            centered
            open={visible}
            onCancel={() => onClose()}
            footer={null}
        >
            <Form
                style={{ maxWidth: '100%', maxHeight: '100%' }}
                onFinish={saveUserInfo}
                form={form}
                layout="vertical"
                className="flex flex-col"
                initialValues={initialValues}
            >
                <Form.Item<FieldType>
                    name="dictLabel"
                    label="Title"
                    className='w-full'
                    initialValue={initialValues?.dictLabel} // 确保正确设置初始值
                    rules={[{ required: true, message: 'Please input Title!' }]}>
                    <Input placeholder="Title" style={{ height: '56px', width: '100%' }} />
                </Form.Item>
                <Form.Item<FieldType>
                    name="dictValue"
                    label="Link"
                    className='w-full'
                    initialValue={initialValues?.dictValue}
                    rules={[{ required: true, message: 'Please input Link!' }]}>
                    <Input placeholder="Link"
                        style={{ height: '56px', width: '100%' }} />
                </Form.Item>
                <div className=' flex justify-between items-center space-x-5'>
                    <Button shape="round"
                        style={{ background: '#F1F1F1', borderColor: '#F1F1F1' }}
                        className='w-1/2 h-11  px-4 space-x-2  flex items-center justify-center  '
                        onClick={() => onClose()}
                    >
                        <img src={require('../../assets/images/public/close.png')} alt='close' />
                        <span className='text-base font-medium text-[#275576] '> Cancel</span>
                    </Button>
                    <Button shape="round"
                        style={{ background: '#275576', borderColor: '#275576' }}
                        className='w-1/2 h-11  px-4 flex items-center justify-center space-x-2 '
                        htmlType="submit"
                        loading={loading}
                    >
                        <img src={require('../../assets/images/public/send-01.png')} alt='send-02' />
                        <span className=' text-base font-medium text-white '> Save</span>
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}
export default AddOrEdit