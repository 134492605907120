import React from 'react';
import { BlockOutlined } from '@ant-design/icons';
import { message } from 'antd';
interface typeProps {
    textToCopy: string;
}
// 复制文本

const CopyTextButton: React.FC<typeProps> = ({ textToCopy }) => {
    const handleCopy = () => {
        // 创建一个临时的 textarea 元素
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;
        textarea.setAttribute('readonly', '');
        textarea.style.position = 'absolute';
        textarea.style.left = '-9999px'; // 移出屏幕外
        document.body.appendChild(textarea);

        // 选择并复制文本
        textarea.select();
        document.execCommand('copy');

        // 清理
        document.body.removeChild(textarea);
        message.success('Copy successful')
    };

    return (
        <div className='space-x-2  text-base font-medium text-[#275576] cursor-pointer'>
            <span onClick={handleCopy}>Copy link</span>
            <BlockOutlined />
        </div>

    );
};

export default CopyTextButton;
