import React from 'react';
// 定义类型
interface CustomSVGProps {
    width?: number;
    height?: number;
    color?: string;
}
//logo图标组件
const Logo: React.FC<CustomSVGProps> = ({ width = 150, height = 48, color = '#275576' }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 150 48">
        <path d="M0 32.3438C0 30.75 1.33488 29.3437 2.95582 29.3437H3.24186V6H2.95582C1.33488 6 0 4.6875 0 3.09375C0 1.5 1.33488 0.09375 2.95582 0.09375H10.9651C20.7861 0.09375 28.8907 7.96875 28.8907 17.7187C28.8907 27.4687 20.8814 35.3438 10.9651 35.3438H2.95582C1.33488 35.25 0 33.9375 0 32.3438ZM9.34419 29.3437H10.9651C17.5442 29.3437 22.7884 24.0937 22.7884 17.7187C22.7884 11.3437 17.5442 6 10.9651 6H9.34419V29.3437Z" />
        <path d="M41.3813 35.5313C34.993 35.5313 29.7488 30.4688 29.7488 24.0938C29.7488 17.8125 34.8976 12.6562 41.3813 12.6562C47.7697 12.6562 53.0139 17.7188 53.0139 24.0938C53.0139 30.4688 47.8651 35.5313 41.3813 35.5313ZM35.6604 24.1875C35.6604 27.2812 38.2348 29.8125 41.3813 29.8125C44.5279 29.8125 47.1023 27.2812 47.1023 24.1875C47.1023 21.0937 44.5279 18.5625 41.3813 18.5625C38.2348 18.5625 35.6604 21 35.6604 24.1875Z" />
        <path d="M67.2211 35.5312C66.363 35.5312 65.4095 35.1562 64.742 34.5C63.6932 33.1875 62.6444 32.0625 61.7862 30.9375C60.8327 32.0625 59.8792 33.2812 58.8304 34.5C58.2583 35.1562 57.2095 35.5312 56.3513 35.5312C54.7304 35.5312 53.3955 34.5 53.3955 33.1875C53.3955 32.8125 53.4909 32.3437 53.9676 31.875C55.6839 29.8125 57.2095 27.9375 58.449 26.0625C56.3513 22.4062 55.3025 17.9062 55.3025 13.0312C55.3025 5.25 58.5444 0 61.7862 0C65.0281 0 68.2699 5.25 68.2699 13.0312C68.2699 17.9062 67.1258 22.4062 65.1234 26.0625C66.363 27.9375 67.8885 29.8125 69.6048 31.875C69.8909 32.3437 70.1769 32.8125 70.1769 33.1875C70.1769 34.5 68.842 35.5312 67.2211 35.5312ZM61.7862 4.875C61.0234 4.875 60.4513 7.96875 60.4513 12.75C60.4513 15.4687 61.2141 20.7187 61.7862 22.9687C62.549 20.7187 63.1211 15.4687 63.1211 12.75C63.2164 7.96875 62.7397 4.875 61.7862 4.875Z" />
        <path d="M84.0023 35.5312C81.9046 35.5312 79.9976 34.9687 78.2814 34.0312V48H72.4651V12.375L77.1372 15C78.6628 13.7813 81.2372 12.75 84.0977 12.75C90.486 12.75 95.7302 17.8125 95.7302 24.1875C95.6349 30.4688 90.3907 35.5312 84.0023 35.5312ZM84.0023 18.4688C80.8558 18.4688 78.2814 21 78.2814 24.0938C78.2814 27.1875 80.8558 29.7188 84.0023 29.7188C87.1488 29.7188 89.7232 27.1875 89.7232 24.0938C89.7232 21 87.1488 18.4688 84.0023 18.4688Z" />
        <path d="M99.1628 35.25V0.09375H104.979V13.3125C105.933 13.0312 106.886 12.75 107.935 12.75C112.798 12.75 116.707 16.5937 116.707 21.375V32.7188C116.707 34.3125 115.372 35.625 113.751 35.625C112.13 35.625 110.795 34.3125 110.795 32.7188V21.375C110.795 19.7812 109.461 18.4688 107.84 18.4688C106.219 18.4688 104.884 19.7812 104.884 21.375V35.25H99.1628Z" />
        <path d="M127.291 29.5312V35.25H121.475V29.5312V14.625C121.475 13.0313 122.809 11.7188 124.43 11.7188C126.051 11.7188 127.386 13.0313 127.386 14.625L127.291 29.5312Z" />
        <path d="M132.249 35.25V12.4688L135.873 14.5312C136.54 13.9687 138.542 12.8437 141.021 12.8437C145.884 12.8437 149.793 16.6875 149.793 21.4687V32.8125C149.793 34.4062 148.459 35.7187 146.838 35.7187C145.217 35.7187 143.882 34.4062 143.882 32.8125V21.375C143.882 19.7812 142.547 18.4687 140.926 18.4687C139.305 18.4687 137.97 19.7812 137.97 21.375V35.25H132.249Z" />
        <path d="M124.24 9.84375C126.346 9.84375 128.054 8.16482 128.054 6.09375C128.054 4.02268 126.346 2.34375 124.24 2.34375C122.133 2.34375 120.426 4.02268 120.426 6.09375C120.426 8.16482 122.133 9.84375 124.24 9.84375Z" />
    </svg>
);

export default Logo;