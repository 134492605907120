import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Table, Input } from 'antd';
import type { TableProps } from 'antd';
import { getMySubmissions } from "../../../api/healthOnline";
import dictStore from '../../../store/dict'
import type { TablePaginationConfig } from 'antd/lib/table';
import HeadNavigation from '../../../store/HeadNavigation';
import indexStore from '../../../store/index'

// 类型声明
interface DataType {
    key: string;
    title: string;
    contentTags: string;
    status: string;
    bannerPath: string;
    createTime: string;
}
type StateMap = {
    [key: number]: [string, string]
};
// 我的提交
const MySubmissions = () => {
  const [moduleType] = useState<number>(indexStore.menusState.moduleType); //区别管理员/用户模块
    const [data, setData] = useState<DataType[]>([]); // 存储表格数据
    const [searchValue, setSearchValue] = useState<string>(''); // 搜索关键词
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 20,
        total: 0,
    });
    // 根据code 获取对应的label值
    const getLabels = (contentTags: string) => {
        const dictCodes = contentTags.split(',')
        const labels = dictCodes.map(code => {
            const item = dictStore.state.article_tags.find((d: any) => d.dictCode === Number(code)) as any;
            return item ? item.dictLabel : null;
        }).filter(label => label !== null).join(', ');
        return labels
    }

    const urgencyStateName = (state: number) => {
        const stateMap: StateMap = {
            1: ['draft', 'none'],
            2: ['under view', '#D3DDE4'],
            3: ['ready', '#89CFE5'],
            4: ['published', '#EFDA76']
        };

        return stateMap[state] || '';
    }
    // 获取我的提交数据
    const getMySubmissionsFn = async (current: any, size: any,title?:string) => {
        try {
            const res = await getMySubmissions({ current, size,title });
            if (res.data.code === 200) {
                const recordsWithKey = await Promise.all(res.data.data.records.map(async (record: DataType, index: number) => {
                    return {
                        ...record,
                        key: `${index}`, // 为每个记录添加一个唯一的key属性
                        contentTags: getLabels(record.contentTags)
                    };
                }));
                setData(recordsWithKey); // 将获取的数据存储在状态中
                setPagination((prevPagination) => ({
                    ...prevPagination,
                    total: res.data.data.total,
                }));
            }
        } catch (error) {
            console.error("Failed to fetch submissions:", error);
        }
    };

    // 表格结构
    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'Title',
            dataIndex: 'title',
            render: (_, item) => (
                <div className='flex items-center space-x-7'>
                    <div className='w-24 h-14 overflow-hidden rounded-md bg-[#ccc]'>
                        <img className='w-full h-full' src={item.bannerPath} alt={item.key} />
                    </div>
                    <div className='flex-1 u-line-1 text-xl font-normal text-[#232323]'>{item.title}</div>
                </div>
            ),
        },
        {
            title: 'Category',
            dataIndex: 'contentTags',
            render: (_, item) => (
                <div className='text-xl font-normal text-[#232323]'>{item.contentTags}</div>
            ),
        },
        {
            title: 'Last modified',
            dataIndex: 'createTime',
            render: (_, item) => (
                <div className='text-xl font-normal text-[#232323]'>{item.createTime}</div>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (_, item) => (
                <span className={`
                px-5 py-2 text-center  border border-[#DEDEDE]
                 rounded-3xl text-base font-medium text-[#232323]
                `}
                    style={{ background: urgencyStateName(Number(item.status))[1] }}
                >
                    {urgencyStateName(Number(item.status))[0]}
                </span>
            ),
        },
    ];
    // 点击分页
    const handleTableChange = (newPagination: TablePaginationConfig) => {
        setPagination(newPagination);
        getMySubmissionsFn(newPagination.current, newPagination.pageSize);
    };
    useEffect(() => {
        getMySubmissionsFn(pagination.current, pagination.pageSize);
        const currentName = moduleType === 1 ? 'Health & wellness' : 'Online classes';
        HeadNavigation.setItem({ backTo: '/healthOnline/home', goName:currentName, currentName });
    }, []);
    //根据标题搜索
const searchStrFn=()=>{
    getMySubmissionsFn(pagination.current, pagination.pageSize,searchValue);
}
    return (
        <div className="py-5 px-14 space-y-7">
            <div className="flex items-center justify-between">
                <div className="text-4xl font-medium text-[#232323]">My submission</div>
                <Input
                    className="w-64 h-12 rounded-full bg-[#F1F1F1]"
                    placeholder="Search"
                    suffix={<SearchOutlined style={{ color: '#232323', fontSize: '1.25rem' }}  />}
             
                    onPressEnter={searchStrFn}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                
                />
            </div>
            <div className="border border-[#D2D2D2] rounded-md">
                <Table
                    columns={columns}
                    dataSource={data}
                    scroll={{ y: 550 }}
                    pagination={pagination}
                    onChange={handleTableChange}
                />
            </div>
        </div>
    );
};

export default MySubmissions;
