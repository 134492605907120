
import { useState } from 'react';
import { Button } from 'antd';
import Interest from './Interest';
import { useNavigate } from 'react-router-dom';
const ThirdStep = () => {
    const navigate = useNavigate();
    // 是否可点击去下一步
    const [isButtonActive, setIsButtonActive] = useState(false);
    // 处理点击“Lets go!”按钮的函数
    const handleLetsGoClick = () => {
        if (isButtonActive) {
            // 跳转到首页
            navigate('/');
        }
    };
    // 推荐的兴趣选择
    const handleInterestsSelect = (isSelected: boolean) => {
        setIsButtonActive(isSelected);
    };
    return (
        <div className="">
            <div className="space-y-5 text-center mb-8">
                <p className=" text-4xl font-medium">Hello! John Doe</p>
                <div className=" text-xl font-normal text-[#959595]">
                    Welcome to <span className="text-[#275576]">Dolphin</span> 🎉 Personalize your experience. Select interests<br />
                    preferences below for tailored content. Let's make your time here enriching<br />
                    and enjoyable!
                </div>
            </div>
            {/* 选择兴趣爱好推荐 组件 */}
            <Interest onInterestsSelect={handleInterestsSelect} />
            <div className='w-full space-y-5 mt-3'>
                <Button shape={'round'}
                    style={{
                        height: '46px', width: '100%', background: isButtonActive ? '#275576' : '#F1F1F1',
                        borderColor: isButtonActive ? '#275576' : '#F1F1F1',
                        color: isButtonActive ? '#ffffff' : '#959595'
                    }}
                    disabled={!isButtonActive}
                    onClick={handleLetsGoClick}>
                    <span className='text-base font-medium '>Lets go!</span>
                </Button>
                <Button shape={'round'} style={{ height: '46px', width: '100%', borderColor: '#275576' }} onClick={() => navigate('/home')}>
                    <span className='text-base font-medium text-[#275576]'>Skip</span>
                </Button>
            </div>
        </div>
    );
}

export default ThirdStep;
