import {Drawer, Spin } from 'antd';
import { messagesPage } from "../../api/chat"
import React, { useState, useEffect, useRef } from 'react';
import chartStore from "../../store/chart"
import userStore from "../../store/user"
import { getVHtml } from "../../utils/parseContent"
import dayjs from 'dayjs' // 导入插件
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import AvatarImg from '../Public/AvatarImg';
interface HistoricalNewsProps {
    visible?: boolean;
    onClose: () => void;
}
// 历史消息记录
const HistoricalNews: React.FC<HistoricalNewsProps> = ({ visible = false, onClose }) => {
    const [userInfo] = useState<any>(userStore.state.userInfo) //用户信息\
    const currentPage = useRef<number>(1) //页码
    const dataList = useRef<any[]>([]) //数据列表
    const hasMore = useRef<boolean>(true); // 是否还有更多数据可加载
    const [loading, setLoading] = useState<boolean>(false); // 加载状态
    const containerRef = useRef<HTMLDivElement>(null); // 容器的引用，用于监听滚动事件
    // 获取历史消息
    const messagesPageFn = async () => {
        try {
            setLoading(true); // 开始加载数据
            const params = {
                chatId: chartStore.chatObject.id,
                fromId: userInfo.id,
                chatType: 0,
                current: currentPage.current,
                size: 20,
            }
            const res = await messagesPage(params)
            if (res.data.code === 200 && res.data?.data.records) { // 如果请求成功
                // 发送消息时间转换
                dayjs.extend(utc)
                dayjs.extend(relativeTime)
                const processedMessagesList = res.data.data.records.map((msg: any) => ({
                    ...msg,
                    relativeTime: dayjs.utc(msg.timestamp).fromNow(),
                }));
                dataList.current = [...dataList.current, ...processedMessagesList]; // 更新数据列表
                currentPage.current = currentPage.current + 1
                // 判断是否还有更多数据可加载
                if (res.data.data.total === dataList.current.length) {
                    hasMore.current = false; // 没有更多数据了
                }
            }
            console.log(res, 'resresres')
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    // 处理滚动事件
    const handleScroll = () => {
        console.log(1111)
        if (!containerRef.current) return; // 如果容器不存在，直接返回
        const { scrollTop, clientHeight, scrollHeight } = containerRef.current; // 获取容器的滚动位置信息
        // 当滚动到底部且还有更多数据时，加载更多信息
        if (scrollHeight - scrollTop === clientHeight && hasMore.current) {
            messagesPageFn(); // 加载更多信息
        }
    };
    // 关闭历史弹框
    const onCloseDrawer = () => {
        onClose()
    };
    // 组件加载时获取数据
    useEffect(() => {
        if (visible) {
            messagesPageFn()
        }
    }, [visible]); // 当分类ID变化时重新获取数据
    return (
        <Drawer width={600} title="Historical news" onClose={onCloseDrawer} open={visible}>
            <div className='w-full bg-[#F1F1F1] h-full px-12 py-6 custom_scrollbar  overflow-auto' ref={containerRef} onScroll={handleScroll}>
                {dataList.current && dataList.current.length > 0 ? ( // 如果有游戏数据
                    <div className='space-y-5'>
                        {dataList.current.map((msg: any, index: number) => (
                            <div className='flex flex-col space-y-1' key={index}>
                                <div className={`w-full flex ${Number(chartStore.chatObject.id) === Number(msg.chatId) ? 'justify-end' : 'flex-row-reverse'}`}>
                                    <div className={`flex-1 flex mx-2 ${Number(chartStore.chatObject.id) === Number(msg.chatId) ? 'justify-end pl-14 ' : 'pr-14 '}`}>
                                        <div className='px-5 py-4 flex flex-wrap break-all bg-white rounded-md'
                                            dangerouslySetInnerHTML={getVHtml(msg.content)}
                                        />
                                    </div>
                                    <div className='w-14 h-14 bg-[#D2D2D2] rounded-full overflow-hidden'>
                                        <AvatarImg id={msg.fromId}/>
                                    </div>
                                </div>
                                <div className={`flex text-sm font-normal text-[#959595] ${Number(chartStore.chatObject.id) === Number(msg.chatId) ? 'justify-end mr-10 ' : 'ml-10'}`}>
                                    {msg.relativeTime}
                                </div>
                            </div>
                        ))
                        }
                    </div>
                ) : (
                    <div className="h-10 leading-10 text-center text-[#707070]">No data available</div> // 没有数据时显示的信息
                )}
                <Spin spinning={loading} delay={500}> </Spin>
            </div>
        </Drawer>
    )
}
export default HistoricalNews