import { Rate } from 'antd';
import AvatarImg from '../../Public/AvatarImg';
interface CardProps {
    item: any
}
const Card: React.FC<CardProps> = ({ item }) => {
    return (
        <div className=' space-y-1 border-b border-[#D2D2D2] py-2'>
            <div className='flex items-center justify-end '>
                <Rate allowHalf disabled value={item.userScore} defaultValue={10} style={{ color: '#EA5E5E' }} />
            </div>
            <div className='flex-1 flex items-center space-x-1'>
                <div className='w-12 h-12 bg-[#ccc] rounded-full cursor-pointer overflow-hidden'>
                    <AvatarImg id={item.createBy} />
                </div>
                <div className='flex-1'>
                    <p className='text-xl font-medium text-[#232323]'>{item.userName}</p>
                    <p className='text-lg font-normal text-[#707070] u-line-2'>{item.content}</p>
                </div>
            </div>

        </div>
    )
}
export default Card