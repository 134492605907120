import { makeAutoObservable, autorun } from 'mobx';

// 聊天未读消息存入
class chartStore {
    // 状态数据
    chartsState = {
        index: true,//是否已读
        charts: [],//未读聊天消息
        requestS: [],//未读好友请求消息
        hasItBeenRead: true //是否已读
    }
    chatObject: any //当前聊天对象
    constructor() {
        makeAutoObservable(this);
        const storedItem = sessionStorage.getItem('chartsState');
        if (storedItem) {
            this.chartsState = JSON.parse(storedItem);
        }
        autorun(() => {
            sessionStorage.setItem('chartsState', JSON.stringify(this.chartsState));
            sessionStorage.setItem('chatObject', JSON.stringify(this.chatObject));
        });
    }
    setCharts(arr: any) {
        this.chartsState.charts = arr;

    }
    setRequestS(arr: any) {
        this.chartsState.requestS = arr;
    }
    setChartsIndex(bool: boolean) {
        this.chartsState.index = bool;
    }
    //设置未读状态
    setHasItBeenRead(hasItBeenRead: boolean) {
        this.chartsState.hasItBeenRead = hasItBeenRead;
    }
    setChatObject(item: any) {
        this.chatObject = item;
    }
    clearData() {
        this.chartsState = {
            index: true,
            charts: [],
            requestS: [],
            hasItBeenRead: true
        }
    }
}

export default new chartStore();
