import { makeAutoObservable, autorun } from 'mobx';
class userStore {
    // 状态数据
    state = {
        userInfo :{} as any ,//用户信息
        token:''
    }
    constructor() {
        makeAutoObservable(this);
        const storedItem = sessionStorage.getItem('userInfo');
        const token = sessionStorage.getItem('token');

        if (storedItem) {
            this.state.userInfo = JSON.parse(storedItem);
        }
        if (token) {
            this.state.token = JSON.parse(token);
        }
        autorun(() => {
            sessionStorage.setItem('userInfo', JSON.stringify(this.state.userInfo));
            sessionStorage.setItem('token', JSON.stringify(this.state.token));
        });
    }
    setUserInfo(val: any) {
        this.state.userInfo = val;
    }
    setToken(val: any) {
        this.state.token = val;
    }
    clearData() {
        this.state.userInfo={}
        this.state.token=''
    }
}

export default new userStore();
