import { useEffect, useState } from 'react';
import { Input, Button, Form, Radio, message } from 'antd';
import type { FormProps } from 'antd';
import { getUsersInfo, sysUsersupdate, getUsersMyInfo } from '../../api/user'
import userStore from "../../store/user"
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import UploadProfilePicture from '../../components/Modal/UploadProfilePicture';
import HeadNavigation from '../../store/HeadNavigation';
import AvatarImg from '../../components/Public/AvatarImg';
type FieldType = {
    id: number | string,
    username: string,
    remark: string,
    name: string,
    firstName: string,
    lastName: string,
    avatar: number, // 头像
    mobile: number, // 手机号
    sex: string //性别
};
// 个人中心
const PersonalCenter = () => {
    const [form] = Form.useForm();
    // 定义
    const navigate = useNavigate();
    const [userInfo] = useState<any>(userStore.state.userInfo) //用户信息
    const [initialValues, setInitialValues] = useState<any>(); //初始表单
    const [uploadPictureVisible, setUploadPictureVisible] = useState(false);//头像弹框
    const [avatarPath, setAvatarPath] = useState('');//头像路径
    const [loading, setLoading] = useState<boolean>(false); // 加载状态
    const [refreshCount, setRefreshCount] = useState(0); //更新数据
    // 保存用户数据
    const saveUserInfo: FormProps<FieldType>['onFinish'] = async (values) => {
        try {

            const param: any = {
                ...values,
                id: userInfo.id,
                avatar: avatarPath
            }
            setLoading(true)
            const res = await sysUsersupdate(param)
            setLoading(false)
            if (res.data.code === 200) {
                message.success(res.data.msg)
                getUsersInfoFn() //更新用户数据
                getUsersMyInfoFn()
            }
        } catch (error) {
            setLoading(false)
            console.dir(error)
        }
    }
    // 获取用户信息
    const getUsersMyInfoFn = async () => {
        const userRes = await getUsersMyInfo()
        // 获取用户信息
        if (userRes.data.code === 200) {
            userStore.setUserInfo({ ...userStore.state.userInfo, ...userRes.data.data })
        }
    }
    // 获取用户数据
    const getUsersInfoFn = async () => {
        if (userInfo.id) {
            try {
                const res = await getUsersInfo(userInfo.id);
                if (res.data.code === 200) {
                    setInitialValues(res.data.data)
                }
            } catch (error) {
                console.log(error)
            }
        }
    }
    // 关闭头像弹框
    const closeUploadPictureVisible = () => {
        setUploadPictureVisible(false)
    }
    // 头像地址
    const uploadPicturePathHandle = (path: any) => {
        if (path) {
            setAvatarPath(path)
            // 强制更新数据
            setRefreshCount(prevCount => prevCount + 1);
            userStore.setUserInfo({ ...userStore.state.userInfo, prevCount: refreshCount })
        }
    }
    useEffect(() => {
        // 弹框打开 获取用户数据
        getUsersInfoFn()
        HeadNavigation.setItem({ backTo: -1, goName: 'Previous step', currentName: 'Personal Center' });
    }, [])

    return (
        <div className='w-full h-full px-14 py-6'>
            {initialValues &&
                <Form
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                    form={form}
                    onFinish={saveUserInfo}
                    layout="vertical"
                    className="h-full flex flex-col space-y-5"
                >
                    <div className='flex items-center justify-between'>
                        <div className='flex items-center space-x-5'>
                            <div key={refreshCount} className=' w-14 h-14 bg-[#F1F1F1] rounded-full overflow-hidden'>
                                <AvatarImg id={initialValues?.id} />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <span className=' text-xl font-medium text-[#232323]'>
                                    {initialValues?.firstName} {initialValues?.lastName}({initialValues?.name})
                                </span>
                                {initialValues?.remark && <span>{initialValues?.remark}</span>}
                            </div>
                            <div >
                                <Button shape={'round'} style={{ height: '60px', background: '#E9EEF1' }}
                                    onClick={() => setUploadPictureVisible(true)}
                                >
                                    <span className='text-base font-medium text-[#275576]'>Change profile picture</span>
                                </Button>
                            </div>
                        </div>
                        <div className=' flex items-center space-x-5'>
                            <Button shape="round"
                                style={{ background: '#F1F1F1', borderColor: '#F1F1F1' }}
                                className='h-11  px-4 space-x-2  flex items-center  '
                                onClick={() => navigate(-1)}
                            >
                                <img src={require('../../assets/images/public/close.png')} alt='close' />
                                <span className='text-base font-medium text-[#275576] '> Cancel</span>
                            </Button>
                            <Button shape="round"
                                style={{ background: '#275576', borderColor: '#275576' }}
                                className='h-11  px-4 flex items-center justify-center space-x-2 '
                                htmlType="submit"
                                loading={loading}
                            >
                                <img src={require('../../assets/images/public/send-01.png')} alt='send-02' />
                                <span className=' text-base font-medium text-white '> Save</span>
                            </Button>
                        </div>
                    </div>
                    <div className="w-full flex flex-col border border-[#D2D2D2] rounded-md overflow-hidden">
                        <div className="w-full h-16 px-5 leading-[4rem] bg-[#F6F8F8] text-xl font-medium border-b border-[#D2D2D2]">
                            Basic details
                        </div>
                        <div className='w-full px-5 pt-5'>
                            <div className='w-full flex justify-between space-x-5'>
                                <Form.Item<FieldType>
                                    name="firstName"
                                    label="First name"
                                     className='w-full h-14'
                                    initialValue={initialValues?.firstName}
                                    rules={[{ required: true, message: 'Please input your First name!' }]}>
                                    <Input placeholder="First name"
                                        style={{ height: '56px', width: '100%' }} />
                                </Form.Item>
                                <Form.Item<FieldType>
                                    name="lastName"
                                    label="Last name"
                                     className='w-full h-14'
                                    initialValue={initialValues?.lastName}
                                    rules={[{ required: true, message: 'Please input your  Last name!' }]}>
                                    <Input placeholder="Last name"
                                        style={{ height: '56px', width: '100%' }} />
                                </Form.Item>
                            </div>
                            <Form.Item<FieldType>
                                className='w-full my-5'
                                name="name"
                                label="Display name"
                                initialValue={initialValues?.name}
                                rules={[{ required: true, message: 'Please input your Display name!' }]}
                            >
                                <Input placeholder="Email address"
                                    style={{ height: '56px', width: '100%' }} />
                            </Form.Item>
                            <Form.Item<FieldType>
                                className='w-full'
                                name="mobile"
                                label="phone number"
                                initialValue={initialValues?.mobile}
                            >
                                <Input placeholder="phone number"
                                    style={{ height: '56px', width: '100%' }} />
                            </Form.Item>
                            <div className='w-full flex items-center space-x-5'>
                                <Form.Item<FieldType>
                                    className=' w-2/3 h-full '
                                    name="remark"
                                    label="What is your profession?"
                                    initialValue={initialValues?.remark}
                                >
                                    <Input placeholder="Search and select"
                                        style={{ height: '56px', width: '100%' }} />
                                </Form.Item>

                                <Form.Item<FieldType>
                                    name="sex"
                                    label="Gender"
                                    initialValue={initialValues?.sex || '0'}
                                    className='w-1/3 h-full'
                                >
                                    <Radio.Group optionType="button" className='w-full flex h-14'>
                                        <Radio value={'0'} className='w-1/2 h-full flex justify-center items-center text-sm space-x-2' >
                                            <UserOutlined />
                                            <span>Man</span>
                                        </Radio>
                                        <Radio value={'1'} className='w-1/2 h-full flex justify-center items-center text-sm text-[red] space-x-2'>
                                            <UserOutlined />
                                            <span>Woman</span>
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </Form>
            }
            <UploadProfilePicture visible={uploadPictureVisible} id={userInfo.id} onClose={closeUploadPictureVisible} onChange={uploadPicturePathHandle} />
        </div>

    )
}
export default PersonalCenter