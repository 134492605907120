const Photography = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
            <mask id="mask0_983_8771" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                <rect x="0.5" y="0.199951" width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_983_8771)">
                <path d="M10.5 14.7833C11.5416 14.7833 12.427 14.4187 13.1562 13.6895C13.8854 12.9604 14.25 12.075 14.25 11.0333C14.25 9.99162 13.8854 9.1062 13.1562 8.37703C12.427 7.64787 11.5416 7.28328 10.5 7.28328C9.45829 7.28328 8.57288 7.64787 7.84371 8.37703C7.11454 9.1062 6.74996 9.99162 6.74996 11.0333C6.74996 12.075 7.11454 12.9604 7.84371 13.6895C8.57288 14.4187 9.45829 14.7833 10.5 14.7833ZM10.5 13.1166C9.91663 13.1166 9.42357 12.9152 9.02079 12.5125C8.61801 12.1097 8.41663 11.6166 8.41663 11.0333C8.41663 10.45 8.61801 9.9569 9.02079 9.55412C9.42357 9.15134 9.91663 8.94995 10.5 8.94995C11.0833 8.94995 11.5763 9.15134 11.9791 9.55412C12.3819 9.9569 12.5833 10.45 12.5833 11.0333C12.5833 11.6166 12.3819 12.1097 11.9791 12.5125C11.5763 12.9152 11.0833 13.1166 10.5 13.1166ZM3.83329 17.7C3.37496 17.7 2.9826 17.5368 2.65621 17.2104C2.32982 16.884 2.16663 16.4916 2.16663 16.0333V6.03328C2.16663 5.57495 2.32982 5.18259 2.65621 4.8562C2.9826 4.52981 3.37496 4.36662 3.83329 4.36662H6.45829L7.99996 2.69995H13L14.5416 4.36662H17.1666C17.625 4.36662 18.0173 4.52981 18.3437 4.8562C18.6701 5.18259 18.8333 5.57495 18.8333 6.03328V16.0333C18.8333 16.4916 18.6701 16.884 18.3437 17.2104C18.0173 17.5368 17.625 17.7 17.1666 17.7H3.83329ZM3.83329 16.0333H17.1666V6.03328H13.7916L12.2708 4.36662H8.72913L7.20829 6.03328H3.83329V16.0333Z" 
                fill="currentColor" />
            </g>
        </svg>
    )
}
export default Photography