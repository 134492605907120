import { Button, Input, Rate } from 'antd';
import AvatarImg from '../../Public/AvatarImg';
import userStore from "../../../store/user"
import React, { useState } from 'react';
import { addSysCommentPrivate } from "../../../api/user"
import throttle from '../../../utils/throttle';
interface AddOrEditProps {
    saveSuccess: (item: any) => void; //保存成功
}
// 新增评论
const Add: React.FC<AddOrEditProps> = ({ saveSuccess }) => {
    const [loading, setLoading] = useState<boolean>(false); // 加载状态
    const [content, setContent] = useState<string>("");
    const [userScore, setUserScore] = useState<number>(10);
    // 保存 对系统进行评论
    const addSysCommentPrivateSave = async () => {
        if (content.trim() !== '') {
            try {
                setLoading(true)
                const formData: any = new FormData();
                formData.append('content', content);
                formData.append('userScore', userScore);
                const res = await addSysCommentPrivate(formData)
                setLoading(false)
                if (res.data.code === 200) {
                    saveSuccess({
                        createBy: userStore.state?.userInfo?.id,
                        userName: userStore.state?.userInfo?.name,
                        content,
                        userScore,
                    });
                    setUserScore(10)
                    setContent('')
                }
            } catch (error) {
                // 处理异常情况
                console.error('保存失败:', error);
                setLoading(false)
            }
        }
    };
    return (
        <div className='flex items-center py-6 px-12 justify-between  space-x-2'>
            <div className='w-14 h-14 bg-[#F1F1F1] rounded-full overflow-hidden'>
                <AvatarImg id={userStore.state?.userInfo?.id} />
            </div>
            <Input value={content}
                onChange={(e) => setContent(e.target.value)}
                onPressEnter={() => throttle(addSysCommentPrivateSave)} // 绑定回车键事件
                className='flex-1' placeholder="Content" style={{ height: '56px' }} />
            <Rate value={userScore} onChange={(value: number) => setUserScore(value)} allowHalf defaultValue={10} style={{ color: '#EA5E5E' }} />
            <Button shape="round"
                style={{ background: '#275576', borderColor: '#275576' }}
                className='h-11  px-4 flex items-center justify-center space-x-2 '
                onClick={() => addSysCommentPrivateSave()}
                loading={loading}>
                <img src={require('../../../assets/images/public/send-01.png')} alt='send-02' />
                <span className=' text-base font-medium text-white '> Send</span>
            </Button>
        </div>
    )
}
export default Add