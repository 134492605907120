import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from '../../components/Public/Header';
// 定义组件
const Index = () => {
    return (
        <div className='w-full h-full overflow-hidden'>
            <div className='w-full h-screen flex flex-col'>
                {/* 头部 */}
                <Header />
                {/* 内容区 */}
                <div className='w-full flex-1 custom_scrollbar overflow-hidden'>
                    <Outlet />
                </div>
            </div>
            {/* 底部 */}
            <Footer />
        </div>
    );
};

export default Index;
