import AWS from "aws-sdk";

// 配置 AWS SDK
const configureAws = () => {
    AWS.config.update({
        region: process.env.REACT_APP_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });
};

// 获取 S3 实例
const getS3Instance = () => {
    configureAws();
    return new AWS.S3();
};

// 根据 key 从 Amazon S3 获取图片地址
export const getAwsImgUrl = async (pathKey: string): Promise<string> => {
    const s3 = getS3Instance();
    const params = {
        Bucket: 'tiksz',
        Key: pathKey,
    };

    return new Promise((resolve, reject) => {
        s3.getSignedUrl('getObject', params, (err, url) => {
            if (err) {
                console.error('Failed to get signed URL:', err);
                reject(err);
            } else {
                resolve(url as string);
            }
        });
    });
};

/**
 * 上传文件至 Amazon S3
 * @param key - 文件的目标键
 * @param file - 要上传的文件
 * @param onProgress - 上传进度回调函数
 */
export const uploadFileAws = async (key: string, file: File, onProgress: (progress: number) => void): Promise<void> => {
    const s3 = getS3Instance();
    const params = {
        Bucket: 'tiksz',
        Key: key,
        Body: file,
        ContentType: file.type,
    };
    try {
        const upload = s3.upload(params);
        // 监听上传进度事件
        upload.on('httpUploadProgress', (event) => {
            const progress = Math.round((event.loaded / event.total) * 100);
            onProgress(progress); // 调用进度回调函数
        });

        await upload.promise();
        console.log(`File uploaded successfully at ${key}`);
    } catch (error) {
        console.error('Failed to upload the file:', error);
        throw error;
    }
};
