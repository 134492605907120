// 尾部样式
import './Footer.scss'
// logo组件
import Logo from '../Global/Logo';
import { PlusSquareOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons';
import AddOrEdit from './AddOrEdit';
import { useEffect, useState } from 'react';
import { getDictDataList, delDictDataItem } from '../../api/dict';
import { Modal } from 'antd';
import userStore from '../../store/user'
// 尾部组件
const Footer: React.FC = () => {
    const [addOrEditVisible, setAddOrEditVisible] = useState(false);//头像弹框
    const [connectWithUs, setConnectWithUs] = useState<any[]>([])
    const [displayedCategories, setDisplayedCategories] = useState<number | string>('')
    const [dictItem, setDictItem] = useState<any>()
    const userInfo = userStore.state.userInfo//用户信息
    //获取字典数据  connect_with_us
    const getDictDataListFn = async () => {
        try {
            const res = await getDictDataList({ dictType: 'connect_with_us' });
            if (res.data.code === 200) {
                setConnectWithUs(res.data.rows)
            }
        } catch (error) {
            console.error(error); // 错误处理
        }
    };
    // 删除 
    const delDictDataItemFn = (dictCode: number, e: any) => {
        e.stopPropagation();  // 阻止事件冒泡
        Modal.confirm({
            title: 'Delete',
            content: 'Are you sure you want to delete it.',
            okText: 'Confirm',
            cancelText: 'Cancel',
            centered: true,
            onOk: async () => {
                try {
                    const res = await delDictDataItem(dictCode)
                    if (res.data.code === 200) {
                        getDictDataListFn()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });
    }
    // 跳转
    const handleClick = (dictValue: any) => {
        window.open(dictValue, '_blank');
    };
    useEffect(() => {
        getDictDataListFn()
    }, []);

    return (
        <div className='w-full py-[3.125rem] px-[3.75rem] bg-[#F5F1EC] border-t border-[#D2D2D2]'>
            <div className='space-y-4'>
                <div className='flex justify-between  flex-wrap'>
                    <div>  <Logo /> </div>
                </div>
                <div className='lg:text-xl font-normal text-[#232323] md:text-xs'>
                    <div>Empowering Elderly Wellness: Where Every Step Counts!</div>
                    {/* 分割线 */}
                    <div className='w-full border-t border-[rgb(168,187,200)] border-dashed my-[1.875rem]'></div>
                    <div className='flex justify-between flex-wrap '>
                        <div className='space-x-10 flex flex-wrap'>
                            <span className='font-medium'>Connect with us:</span>
                            {connectWithUs?.map((item, index) => (
                                <div className='relative cursor-pointer hover:underline underline-offset-4 hover:text-[#275576]' key={index}
                                    onMouseEnter={() => setDisplayedCategories(item.dictCode)}
                                    onMouseLeave={() => setDisplayedCategories('')}
                                >
                                    <span onClick={() => handleClick(item.dictValue)}>{item.dictLabel}</span>
                                    {displayedCategories === item.dictCode && userInfo.userType === '00' &&
                                        <div className='absolute -top-4 right-0 flex space-x-2 items-center'>
                                            <FormOutlined className='text-[#275576] text-base' onClick={() => { setDictItem(item); setAddOrEditVisible(true) }} />
                                            <DeleteOutlined className='text-[#EA5E5E] text-base' onClick={(e) => delDictDataItemFn(item.dictCode, e)} />
                                        </div>
                                    }
                                </div>
                            ))}
                            {userInfo.userType === '00' &&
                                <span className='text-[#275576] cursor-pointer'
                                    onClick={() => { setDictItem({ dictLabel: '', dictValue: '' }); setAddOrEditVisible(true) }}>
                                    <PlusSquareOutlined />
                                </span>
                            }
                        </div>
                        <div className='space-x-10'>
                            <span>Cookies</span>
                            <span>Privacy and policy</span>
                        </div>
                    </div>
                </div>
            </div>
            <AddOrEdit visible={addOrEditVisible} dictItem={dictItem} onClose={() => setAddOrEditVisible(false)} saveSuccess={() => getDictDataListFn()} />
        </div>
    );
}

export default Footer;
