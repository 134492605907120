import request from "../composables/request";
/**
 * 我的用户信息
 * @returns 
 */
export const getUsersMyInfo = () => {
    return request.get('/api/sys/users/my');
}
/**
 * 用户信息 
 * @returns 
 */
export const getUsersInfo = (id: number) => {
    return request.get(`/api/sys/users/${id}`);
}

/**
 * 更新个人资料
 * @param id 
 * @param name 姓名
 * @param firstName 
 * @param lastName 
 * @param avatar 头像
 * @param mobile 手机号
 * @param email 邮箱
 * @param sex  性别 0 男 1 女
 * @param remark 
 * @returns 
 */
export const sysUsersupdate = (
    params: {
        id: number | string,
        name: string,
        firstName: string,
        lastName: string,
        avatar: number,
        mobile: number,
        email: string
        sex: string,
        remark:string
    }
) => {
    return request.put('/api/sys/users/update', params);
}

/**
 * 退出登录
 */
export const logout = () => {
    return request.get('/logout');
}
/**
 * 获取系统评论
 * @returns 
 */
export const sysCommentPublic = (size?: number) => {
    return request.get(`/api/personal/sysComment/public/list?size=${size}`);
}
/**
 * 分页获取系统评论
 * @param size 页面显示条数
 * @param current 页码
 * @returns 
 */
export const sysCommentPublicPage = (size: number,current:number) => {
    return request.get(`/api/personal/sysComment/public/page?size=${size}&current=${current}`);
}
/**
 * 对系统进行评论
 * @param content 论内容
 * @param userScore 评分
 * @returns 
 */
export const addSysCommentPrivate = (params:any) => {
    return request.post('/api/personal/sysComment/private/save', params);
  }
/**
 * 查询最近要开始的活动（按照开始时间排序）
 * @param count 
 * @returns 
 */
export const activityInfoLastCount = (count?: number) => {
    return request.get(`/api/personal/activity/public/activityInfo/last?count=${count}`);
}
