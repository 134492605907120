import { Button, message, Modal } from 'antd';
import { useState } from 'react';
import { HeartOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import detailsStore from '../../store/Details';
import { collectionGame, delGame } from '../../api/gaming';
import userStore from '../../store/user'

interface CardItemProps {
    item: any;
    onUpdate: () => void;
    isShowHeard: boolean
}

// 卡片组件
const CardItem: React.FC<CardItemProps> = ({ item, isShowHeard, onUpdate }) => {
    // 是否显示删除
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();
    const [isShowMore, setIsShowMore] = useState<boolean>(false);
    const [collection, setCollection] = useState<number>(item.collection); //更改收藏的状态
    const userInfo = userStore.state.userInfo//用户信息

    // 跳转
    const navigationTypeJump = () => {
        navigate(`/gaming/details?id=${item.id}`);
    }
    // 收藏/取消收藏 游戏
    const collectionGameFn = async (gameId: number, e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();  // 阻止事件冒泡
        try {
            const res = await collectionGame(gameId)
            if (res.data.code === 200) {
                if (!isShowHeard) {
                    setCollection(collection === 1 ? 0 : 1)
                } else {
                    onUpdate();
                }
                message.success(res.data.msg)
                // 通知父组件更新数据
            }
        } catch (error) {
            console.log(error)
        }
    }

    // 删除游戏
    const delGameFn = async (id: number, e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();  // 阻止事件冒泡
        Modal.confirm({
            title: 'Delete',
            content: 'Are you sure you want to delete it.',
            okText: 'Confirm',
            cancelText: 'Cancel',
            centered: true,
            onOk: async () => {
                try {
                    const res = await delGame(item.id)
                    if (res.data.code === 200) {
                        message.success(res.data.msg);
                        // 通知父组件更新数据
                        onUpdate();
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        });

    }
    // 编辑游戏信息
    const editGameFn = (item: any, e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();  // 阻止事件冒泡
        detailsStore.setDetails({ ...item })
        navigate(`/gaming/addOrEdit?type=edit&id=${item.id}`)
    }
    // 是否显示更多
    const setIsShowMoreHandle = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();  // 阻止事件冒泡
        setIsShowMore(!isShowMore)
    }
    return (
        <div className=' p-5 border border-[#D2D2D2] rounded-xl space-y-4 cursor-pointer'
            onClick={navigationTypeJump}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className=' relative pb-[13.875rem] bg-[#CCCCCC] rounded-md overflow-hidden'>
                <img className=' w-full h-full object-cover absolute top-0 left-0 rounded-md'
                    src={item.filePath} alt={item.filePath} />
                <span className=" absolute z-10 bottom-2 left-2 bg-white rounded-2xl py-2 px-3 w">
                    {item.classification}
                </span>
                <div
                    className={`w-10 h-10 absolute bottom-2 right-2 flex justify-center z-10 cursor-pointer rounded-full bg-white text-2xl 
                    ${collection === 1 || isShowHeard ? 'text-[#EA5E5E]' : 'text-[#FFFFFF] bg-opacity-10 backdrop-blur-[6.099999904632568px]'} 
                     hover:text-[#EA5E5E] `}
                    onClick={(e) => collectionGameFn(item.id, e)}>
                    <HeartOutlined />
                </div>
                {isHovered && (userInfo.userType === '00' || Number(userInfo.id) === Number(item.createBy)) &&
                    <div className='absolute top-2 left-2 flex space-x-1'>
                        <div className={`w-10 h-10  flex justify-center z-10 cursor-pointer rounded-full
                    bg-white bg-opacity-10 text-2xl text-[#275576] backdrop-blur-[6.099999904632568px]`}
                            onClick={(e) => editGameFn(item, e)}>
                            <EditOutlined />
                        </div>
                        <div className={`w-10 h-10  flex justify-center z-10 cursor-pointer rounded-full
                    bg-white bg-opacity-10 text-2xl text-[#EA5E5E] backdrop-blur-[6.099999904632568px]`}
                            onClick={(e) => delGameFn(item.id, e)}>
                            <DeleteOutlined />
                        </div>
                    </div>
                }
            </div>
            <p className=" text-2xl font-medium text-[#232323]  u-line-1">{item.title}</p>
            <div className="flex items-start justify-between space-x-1">
                <div className={`flex-1 text-xl font-normal text-[#707070] u-line-${!isShowMore ? 1 : 3}`}>
                    {item.description}
                </div>
                {item.description && item.description.length > 20 && <div className='underline underline-offset-2 text-xl font-normal text-[#959595] cursor-pointer'
                    onClick={(e) => setIsShowMoreHandle(e)}>
                    {!isShowMore ? 'Read More' : 'Hide'}
                </div>}
            </div>
            <Button shape={'round'} >
                <div className=' h-full flex items-center   justify-center space-x-2'>
                    <img className=' w-6 h-6' src={require(`../../assets/images/public/play.png`)} alt='Favorites' />
                    <span className='text-base font-medium text-[#232323]'> Played by: {item.participateInCount}</span>
                </div>
            </Button>
        </div>
    )
}
export default CardItem