
import request from "../composables/request";
/**
 * 获取用户的所有好友
 * @returns 
 */
export const getUsersMyFriends = () => {
    return request.get('/api/sys/friends?state=0');
}
/**
 * 获取和朋友得聊天记录
 * @param chatId 朋友id
 * @param fromId  用户id
 * @param type 
 * @param pageSize 
 * @param searchText  消息字段
 * @returns 
 */
export const getMyFriendMessages = (params: { searchText?: string, chatId: number, fromId: number, type?: number | string, pageSize?: number }) => {
    return request.get(`/api/sys/messages`, { params });
}
/**
 * 添加好友
 * @param timestamp 当前时间戳
 * @param chatId 添加用户的id
 * @param content 添加好友消息内容
 * @returns 
 */
export const friendsAdd = (params: { timestamp: number, chatId: string, content: string }) => {
    return request.post('/api/sys/friends/add', params);
}
/**
 * 同意好友接口
 * @param timestamp 当前时间戳
 * @param chatId 同意的好友id
 * @returns 
 */
export const friendsAgree = (params: { timestamp: number, chatId: string }) => {
    return request.post('/api/sys/friends/agree', params);
}
/**
 * 拒绝好友接口
 * @param timestamp 当前时间戳
 * @param chatId 同意的好友id
 * @returns 
 */
export const friendsRefuse = (params: { timestamp: number, chatId: string }) => {
    return request.post('/api/sys/friends/refuse', params);
}
/**
 * 删除好友
 * @param id 
 * @returns 
 */
export const friendsDelete = (id?: number | string) => {
    return request.delete('/api/sys/friends/delete', { data: id });
}

/**
 * 根据账号搜索成员
 * @param mobile 
 * @returns 
 */
export const usersSearch = (mobile: string) => {
    return request.get(`/api/sys/users/search?mobile=${mobile}`);
}

/**
 * 保留最后一条聊天记录与未读数量
 * @param userId 用户id
 * @param chatId 聊天对话用户id
 * @param lastMessage 最后一条消息内容
 * @param messageCount 未读消息数量
 * @param timestamp 消息时间戳
 * @param type 类型
 * @returns 
 */
export const saveLastMessage = (params: {
    userId: number,
    chatId: number,
    lastMessage: string,
    messageCount: number,
    timestamp: number,
    type: string
}) => {
    return request.post('/api/sys/messages/saveLastMessage', params);
}
/**
 * 获取聊天未读消息记录
 * @param 
 * @returns 
 */
export const getLastMessage = () => {
    return request.get('/api/sys/messages/getLastMessage');
}

/**
 * 根据用户id获取账户名称
 */

export const obtainNameBasedOnID = (ids: any) => {
    return request.post(`/api/sys/friends/getUserInfo`,ids);
}
/**
 * 获取聊天历史消息
 * @param chatId 
 * @param fromId 
 * @param chatType 
 * @param current 
 * @param size 
 * @returns 
 */
export const messagesPage = (params: {
    chatId:string |number,
    fromId:string |number,
    chatType:string |number,
    current: number,
    size: number,
  }) => {
    return request.get('/api/sys/messages/page', { params });
  }