import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Logo from '../../components/Global/Logo';
import HeadNavigation from '../../store/HeadNavigation'
import { observer } from 'mobx-react-lite'
import { useNavigate, useLocation } from 'react-router-dom';
import userStore from '../../store/user'
import LoginInfo from './LoginInfo';
// 头部
const Header = () => {
    // 跳转
    const navigate = useNavigate();
    const location = useLocation();

    // 如果当前页面是 /healthOnline/home，则返回到 /home 页面
    const handleBackClick = () => {
        const { backTo } = HeadNavigation.item
        navigate(backTo);

    };
    return (
        <div className="w-full h-[5.625rem] px-16 flex justify-between items-center bg-[#275576]">

            <Button shape="round" iconPosition='start' icon={<ArrowLeftOutlined />}
                style={{ background: 'none', borderColor: '#FFFFFF', color: '#ffffff' }}
                className='flex items-center  py-5 '
                onClick={() => handleBackClick()}>

                <span className=' text-base font-medium text-white'>
                    {`Back to ${location.pathname === HeadNavigation.item.backTo ? 'Home' : HeadNavigation.item.goName}`}
                </span>
            </Button>

            <span className=' text-3xl font-medium text-white'>
                {location.pathname === '/notification' ? 'Notification' : HeadNavigation.item.currentName ? HeadNavigation.item.currentName : 'Details'}
            </span>
            <div className='flex items-center space-x-5'>
                <Logo color={'#FFFFFF'} width={125} height={32} />
                {userStore.state.token &&
                    <LoginInfo />
                }
            </div>
        </div>
    )
}
export default observer(Header)