import { Input,Spin } from 'antd';
import { useState, useEffect, useRef } from 'react';
import {  SearchOutlined, EllipsisOutlined } from '@ant-design/icons'; //图表
import { getMyFriendMessages } from "../../../api/chat" //接口
import userStore from "../../../store/user"
import { getVHtml } from "../../../utils/parseContent"
import ChatMessageBox from "../../../components/ConversationalHub/ChatMessageBox"
import SendCode from '../../../utils/SendCode'// 发送消息状态
import dayjs from 'dayjs' // 导入插件
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import { sendMessage, onmessage } from '../../../utils/websocketManager'
import AddFriendModal from '../../../components/ConversationalHub/AddFriend';
import LeftFriendList from '../../../components/ConversationalHub/LeftFriendList';
import chartStore from "../../../store/chart"
import HeadNavigation from '../../../store/HeadNavigation';
import HistoricalNews from '../../../components/ConversationalHub/HistoricalNews';
import { observer } from 'mobx-react-lite'; // 使用 observer 包裹组件
import AvatarImg from '../../../components/Public/AvatarImg';

// 聊天
const ConversationalHub= observer(() => {
    const [messagesList, setMessagesList] = useState<any>([]);//当前聊天窗消息记录
    const [userInfo] = useState<any>(userStore.state.userInfo) //用户信息
    const messagesEndRef = useRef<any>(null); //设置锚点 消息滚动到底部
    const [isAddFriendModalVisible, setIsAddFriendModalVisible] = useState(false);//添加朋友
    const [searchValue, setSearchValue] = useState<string>(''); // 搜索关键词
    const [historicalNewsOpen, setHistoricalNewsOpen] = useState(false);
    const [refreshCount, setRefreshCount] = useState(0);
    const [messageObj, setMessageObj] = useState();
    const [loading, setLoading] = useState<boolean>(false); // 加载状态
    // 根据选择的聊天对象获取聊天消息
    const onSelectChatObject = async (item: any, searchText?: string) => {
        // 删除好友之后重置数据
        if (item === '') {
            chartStore.setChatObject('')
            setMessagesList([])
        } else {
            try {
                chartStore.setChatObject(item)
                setLoading(true)
                const res = await getMyFriendMessages({
                    searchText, chatId: item.id, fromId: userInfo.id,
                    type: SendCode.SINGLECHAT, pageSize: 100
                })
                setLoading(false)
                if (res.data.code === 200 && res.data.data) {
                    // 发送消息时间转换
                    dayjs.extend(utc)
                    dayjs.extend(relativeTime)
                    const processedMessagesList = res.data.data.map((msg: any) => ({
                        ...msg,
                        relativeTime: dayjs.utc(msg.timestamp).fromNow(),
                    }));
                    // 重新保留未读消息
                    setMessagesList(processedMessagesList)
                }
            } catch (error) {
                setLoading(false)
                console.log(error)
            }
        }
    }
    // 获取消息组件传递过来的值 发送消息
    const sendMessagehandle = (val: any) => {
        if (val.trim() !== '') {
            // 将img数据替换成alt的数据
            let result: string = val.replace(/<img[^>]*alt="([^"]+)"[^>]*>/g, (match: any, altText: any) => altText);
            const msg: any = {
                messageType: '0',
                chatId: chartStore.chatObject.id,
                fromId: userInfo.id,
                mine: true,
                timestamp: new Date().getTime(),
                content: result,
                type: SendCode.SINGLECHAT, // 单聊
            };
            const sendInfo = {
                code: SendCode.MESSAGE, //发送消息
                message: msg,
            };
            // 通过ws 发送消息
            sendMessage(JSON.stringify(sendInfo))
            // 重新获取消息
            onSelectChatObject(chartStore.chatObject)
        }
    }

    // 关闭添加朋友弹框
    const closeAddFriendModal = () => {
        setIsAddFriendModalVisible(false);
    };
    // 滚动到消息底部
    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }
    };
    // 接收消息
    const handleMessage = (msg: any) => {
        const currentChatObject = chartStore.chatObject;
        const { content, messageType, fromId, chatId } = msg;
        if (messageType === '0') {
            // 更新聊天消息
            if (currentChatObject && (currentChatObject?.id === fromId || currentChatObject.id===chatId)) {
                onSelectChatObject(currentChatObject);
                chartStore.setChatObject({ ...currentChatObject, msg: content });
            }
            setMessageObj(msg) //保存获取到的数据
        }
        // 处理消息类型为 '2' 的情况（已添加好友）
        if (messageType === '2') {
            // 刷新好友列表，这里需要使用 setRefreshCount 更新刷新计数
            setRefreshCount(prevCount => prevCount + 1);
        }
    };

    // 打开历史消息
    const showDrawer = () => {
        setHistoricalNewsOpen(true);
    };
    // 关闭历史弹框
    const onCloseDrawer = () => {
        setHistoricalNewsOpen(false);
    };
    useEffect(() => {
        // 注册消息处理回调函数
        onmessage(handleMessage)
    }, [onmessage]);
    useEffect(() => {
        HeadNavigation.setItem({ backTo: '/home', goName: 'Home', currentName: 'My connection' });

        // 返回一个清理函数
        return () => {
            // 在组件即将卸载时取消订阅或清理数据
            chartStore.setChatObject('')
        };

    }, []); // 空依赖数组表示仅在组件挂载和卸载时执行
    // 获取消息后，滚动到底部
    useEffect(() => {
        scrollToBottom();
    }, [messagesList]);
    // 自定义模糊查询
    const searchStrFn = () => {
        onSelectChatObject(chartStore.chatObject, searchValue)
    }

    return (
        <div className="w-full h-full flex justify-between custom_scrollbar overflow-hidden">
            {/* 左侧好友列表 */}
            <LeftFriendList onSelectChatObject={(item) => onSelectChatObject(item)} refresh={refreshCount} messageObj={messageObj} />
            {chartStore.chatObject &&
                <div className="flex-1 h-full flex flex-col">
                    <div className=' w-full h-[5.5rem] border-b border-[#D2D2D2] pl-8 pr-14 flex items-center justify-between'>
                        <div className='flex items-center space-x-4'>
                            <div className=' w-14 h-14 bg-[#F1F1F1] rounded-full  overflow-hidden'>
                                <AvatarImg id={chartStore.chatObject?.id}/>
                            </div>
                            <div>
                                <p className=' text-2xl font-medium text-[#232323]'>{chartStore.chatObject.name}</p>
                            </div>
                        </div>
                        <div className='flex items-center space-x-2'>
                            <Input
                                className=' w-52 h-12 rounded-full bg-[#F1F1F1]'
                                placeholder="Search"
                                onPressEnter={searchStrFn}
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                suffix={<SearchOutlined style={{ color: '#232323', fontSize: '1.25rem' }} />}
                            />
                            <span className='cursor-pointer' onClick={showDrawer}>
                                <EllipsisOutlined style={{ color: '#232323', fontSize: '28px' }} />
                            </span>
                        </div>
                    </div>
                    {/* 聊天内容 */}
                    <div className='w-full flex-1 bg-[#F1F1F1] overflow-auto pl-8 pr-14 py-7 space-y-5' >
                        {messagesList.map((msg: any, index: number) => (
                            <div className='flex flex-col space-y-1' key={index}>
                                <div className={`w-full flex ${Number(chartStore.chatObject.id) === Number(msg.chatId) ? 'justify-end' : 'flex-row-reverse'}`}>
                                    <div className={`flex-1 flex mx-2 ${Number(chartStore.chatObject.id) === Number(msg.chatId) ? 'justify-end pl-14 ' : 'pr-14 '}`}>
                                        <div className='px-5 py-4 flex flex-wrap break-all bg-white rounded-md'
                                            dangerouslySetInnerHTML={getVHtml(msg.content)}
                                        />
                                    </div>
                                    <div className='w-14 h-14 bg-[#D2D2D2] rounded-full overflow-hidden'>
                                        <AvatarImg id={msg.fromId}/>
                                    </div>
                                </div>
                                <div className={`flex text-sm font-normal text-[#959595] ${Number(chartStore.chatObject.id) === Number(msg.chatId) ? 'justify-end mr-10 ' : 'ml-10'}`}>
                                    {msg.relativeTime}
                                </div>
                            </div>
                        ))}
                        <div className='w-full flex justify-center items-center'><Spin spinning={loading} delay={500}></Spin></div>
                        <div ref={messagesEndRef}></div>
                    </div>
                    {/* 发送消息 */}
                    <ChatMessageBox onContentChange={sendMessagehandle} />
                </div>
            }
            {/* 添加朋友弹框 */}
            <AddFriendModal visible={isAddFriendModalVisible} onClose={closeAddFriendModal} />
            {/* 聊天历史记录 */}
            {chartStore.chatObject && <HistoricalNews visible={historicalNewsOpen} onClose={onCloseDrawer} />}

        </div>

    )
})
export default ConversationalHub