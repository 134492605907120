import React, { useRef, useState, ReactNode } from 'react';
import { uploadFileAws } from '../../utils/aws';
import { Progress } from 'antd';

interface UploadFilesProps {
    children: ReactNode;
    onFileUploadSuccess: (url: string) => void;
    keyPrefix: string; // 新增 keyPrefix 属性
    accept?: string; // 新增 accept 属性
    identification?: boolean; // 标识
}

const UploadFiles: React.FC<UploadFilesProps> = ({ children, onFileUploadSuccess, keyPrefix, identification = true, accept = 'image/*' }) => {
    const [error, setError] = useState<string | null>(null);
    const [progress, setProgress] = useState<number>(0);
    const uploadRef = useRef<any>(null);

    // 拖放区域的拖拽事件处理函数
    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    // 拖放区域的放置事件处理函数
    const handleDragDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const file = event.dataTransfer.files[0];
            handleFileUpload(file);
            event.dataTransfer.clearData();
        }
    };

    // 输入框选择文件的事件处理函数
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            handleFileUpload(file);
        }
    };

    // 处理文件上传
    const handleFileUpload = async (file: File) => {
        setError(null);
        const fileName = file.name;
        // const type:any = file.type;
        // const extension = type.match(/\/([a-zA-Z0-9]+)$/)[1];
        const key = identification ? `${keyPrefix}${identification}${fileName}` : `${keyPrefix}.png`;

        try {
            // 调用上传文件至 Amazon S3 的函数，并传入进度更新回调
            await uploadFileAws(key, file, (progress) => {
                setProgress(progress); // 更新上传进度
            });

            // 获取上传后的图片地址
            // const signedUrl = await getAwsImgUrl(key);
            const src = `https://tiksz.s3.${process.env.REACT_APP_REGION}.amazonaws.com/${key}`;
            // 调用文件上传成功回调函数，传递签名 URL 和键值
            onFileUploadSuccess(src);

            // 重置进度条
            setProgress(0);
        } catch (err) {
            console.error('Upload error:', err);
            setError('Failed to upload the file.');
            setProgress(0);
        }
    };
    return (
        <div className='w-full h-full'>
            {/* 可拖拽区域 */}
            <div
                className="w-full h-full relative overflow-hidden bg-[#F6F8F8] border border-dashed border-[#D2D2D2] rounded-xl"
                onDragOver={handleDragOver}
                onDrop={handleDragDrop} >
                {/* 隐藏的文件选择输入框 */}
                <input
                    type="file"
                    ref={uploadRef}
                    className="absolute opacity-0 top-0 left-0 w-full h-full  cursor-pointer z-50"
                    onChange={handleInputChange}
                    accept={accept} // 添加 accept 属性
                />
                {children}
                {error && <p className="text-red-600">{error}</p>}
            </div>
            {/* 显示上传进度条 */}
            {progress > 0 && <Progress className=' ' percent={progress} />}
        </div>
    );
};

export default UploadFiles;
