import { Image } from 'antd';
import { getAvatarUrl } from "../../utils/index"
interface AvatarValue {
    id: string | number;
}
// 头像组件
const AvatarImg: React.FC<AvatarValue> = ({ id }) => {
    return (
        <Image
            width={'100%'}
            height={'100%'}
            className='object-cover'
            src={getAvatarUrl(id)}
            preview={false}
            fallback={getAvatarUrl('default')}
        />
    )
}
export default AvatarImg