/**
 * 判空
 */
const isEmpty = (obj: any) => {
    if (typeof obj === "number" && !isNaN(obj)) {
        return false;
    }
    if (!obj) {
        return true;
    }
    return Object.keys(obj).length < 1;
};
/**
 * 判断数据是否为null
 * @param obj 
 * @returns 
 */
export const isNull = (obj: any) => {
    let flag = true;
    for (let key in obj) {
        if (!isEmpty(obj[key])) {
            flag = false;
            break;
        }
    }
    return flag;
};




/**
 * 自定义模糊查询
 * @param strObj 
 * @param arr 
 * @returns 
 */
export const searchStr = (strObj: any, arr: any) => {
    if (isNull(strObj)) {
        return arr;
    } else {
        let newList = [];
        // 要匹配字符串的首个字符
        for (let key in strObj) {
            // 要匹配字符串的字符长度
            console.log(strObj[key], 'strObj[key].')
            let strLength = strObj[key].length;
            if (strLength > 0) {
                newList = [];
                for (let i = 0; i < arr.length; i++) {
                    let obj = arr[i];
                    if (typeof obj[key] === "function") {
                        obj[key]();
                    } else {
                        let keyValue = "";
                        // 获取arr[i][key]的值
                        if (obj[key] !== null && typeof obj[key] === "string") {
                            keyValue = obj[key];
                        } else if (obj[key] !== null && typeof obj[key] !== "string") {
                            keyValue = JSON.stringify(obj[key]);
                        }
                        let regstr = strObj[key];
                        let specialCharacter = [
                            "\\",
                            "$",
                            "(",
                            ")",
                            "*",
                            "+",
                            ".",
                            "[",
                            "?",
                            "^",
                            "{",
                            "|",
                        ];
                        specialCharacter.map((v) => {
                            let reg = new RegExp("\\" + v, "gim");
                            regstr = regstr.replace(reg, "\\" + v);
                        });
                        regstr = ".*" + regstr + ".*";
                        let re = new RegExp(regstr, "i");
                        if (re.test(keyValue)) {
                            newList.push(obj);
                        }
                    }
                }
                arr = JSON.parse(JSON.stringify(newList));
            }
        }
        // 最后返回这个新数组
        return newList;
    }
}
/**
 * 拼接头像地址
 * @param id 
 * @returns 
 */
export const getAvatarUrl = (key: number | string) => {
    return  `https://tiksz.s3.ca-central-1.amazonaws.com/UploadProfilePicture/${key}.png`
}