import axios from 'axios';
import { message, Modal } from 'antd';
import userStore from '../store/user';

const axiosInstance = axios.create({
  baseURL: '/dev_api',
  timeout: 50000,
});

let is401AlertShown = false;
let activeErrorMessages = new Set();

const handle401Error = (navigate:any) => {
  if (!is401AlertShown) {
    is401AlertShown = true;
    Modal.confirm({
      title: 'System prompt',
      content: 'The login status has expired. You can continue to stay on this page or log in again.',
      okText: 'Log in again',
      cancelText: 'Cancel',
      centered: true,
      onOk: () => {
        is401AlertShown = false;
        navigate('/login');
      },
      onCancel: () => {
        is401AlertShown = false;
      },
    });
  }
};

export const setupInterceptors = (navigate:any) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = userStore.state.token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      activeErrorMessages.clear();
      if (response.data.code === 200) {
        return response;
      } else if (response.data.code === 401 || response.data.msg.includes('Exception in obtaining user information')) {
        handle401Error(navigate);
        return response
        // return Promise.reject(response); // 继续向外传递错误
      } else {
        const errorMessage = response.data.msg || response.data.message || 'Unknown error';
        if (!activeErrorMessages.has(errorMessage)) {
          activeErrorMessages.add(errorMessage);
          message.error(errorMessage);
        }
        return Promise.reject(response); // 继续向外传递错误
      }
    },
    (error) => {
      // 清除已经显示的错误消息
      activeErrorMessages.clear();
      console.error('Request failed:', error);
      return Promise.reject(error);
    }
  );
};

export default axiosInstance;
