import request from "../composables/request";
/**
 * 登录邮箱获取验证码
 * @param username  用户名
 * @returns 
 */
export const getCode = (params: { username: string,type:string }) => {
  return  request.get('/getCode', { params });
}
/**
 * 登录获取token
 * @param username 用户名
 * @param uuid id
 * @param code  验证码
 * @returns 
 */
export const loginByEmail = (params: {
    username: string,
    uuid: string,
    code: string
}) => {
    return request.post('/loginByEmail', { ...params })
}
