//logo图标组件 带小标题
const LogoSub=()=> (
    <svg xmlns="http://www.w3.org/2000/svg" width="172" height="78" viewBox="0 0 172 78" fill="none">
        <path d="M0 37.7345C0 35.8751 1.53278 34.2345 3.39402 34.2345H3.72247V7.00012H3.39402C1.53278 7.00012 0 5.46887 0 3.6095C0 1.75012 1.53278 0.109497 3.39402 0.109497H12.5907C23.8676 0.109497 33.1738 9.297 33.1738 20.672C33.1738 32.047 23.9771 41.2345 12.5907 41.2345H3.39402C1.53278 41.1251 0 39.5939 0 37.7345ZM10.7295 34.2345H12.5907C20.1451 34.2345 26.1668 28.1095 26.1668 20.672C26.1668 13.2345 20.1451 7.00012 12.5907 7.00012H10.7295V34.2345Z" fill="#275576" />
        <path d="M47.5163 41.4531C40.1808 41.4531 34.1592 35.5469 34.1592 28.1094C34.1592 20.7813 40.0713 14.7656 47.5163 14.7656C54.8517 14.7656 60.8734 20.6719 60.8734 28.1094C60.8734 35.5469 54.9612 41.4531 47.5163 41.4531ZM40.9472 28.2187C40.9472 31.8281 43.9033 34.7812 47.5163 34.7812C51.1293 34.7812 54.0853 31.8281 54.0853 28.2187C54.0853 24.6094 51.1293 21.6562 47.5163 21.6562C43.9033 21.6562 40.9472 24.5 40.9472 28.2187Z" fill="#275576" />
        <path d="M77.1865 41.4531C76.2012 41.4531 75.1063 41.0156 74.3399 40.25C73.1356 38.7188 71.9313 37.4062 70.9459 36.0938C69.8511 37.4062 68.7562 38.8281 67.5519 40.25C66.895 41.0156 65.6907 41.4531 64.7053 41.4531C62.8441 41.4531 61.3113 40.25 61.3113 38.7188C61.3113 38.2812 61.4208 37.7344 61.9682 37.1875C63.9389 34.7812 65.6907 32.5938 67.114 30.4062C64.7053 26.1406 63.501 20.8906 63.501 15.2031C63.501 6.125 67.2234 0 70.9459 0C74.6684 0 78.3908 6.125 78.3908 15.2031C78.3908 20.8906 77.077 26.1406 74.7779 30.4062C76.2012 32.5938 77.9529 34.7812 79.9236 37.1875C80.2521 37.7344 80.5805 38.2812 80.5805 38.7188C80.5805 40.25 79.0478 41.4531 77.1865 41.4531ZM70.9459 5.6875C70.07 5.6875 69.4131 9.29688 69.4131 14.875C69.4131 18.0469 70.289 24.1719 70.9459 26.7969C71.8218 24.1719 72.4787 18.0469 72.4787 14.875C72.5882 9.29688 72.0408 5.6875 70.9459 5.6875Z" fill="#275576" />
        <path d="M96.4557 41.4531C94.0471 41.4531 91.8574 40.7969 89.8867 39.7031V56H83.2081V14.4375L88.5729 17.5C90.3246 16.0781 93.2807 14.875 96.5652 14.875C103.901 14.875 109.922 20.7812 109.922 28.2187C109.813 35.5469 103.791 41.4531 96.4557 41.4531ZM96.4557 21.5469C92.8428 21.5469 89.8867 24.5 89.8867 28.1094C89.8867 31.7188 92.8428 34.6719 96.4557 34.6719C100.069 34.6719 103.025 31.7188 103.025 28.1094C103.025 24.5 100.069 21.5469 96.4557 21.5469Z" fill="#275576" />
        <path d="M113.864 41.1251V0.109497H120.542V15.5314C121.637 15.2032 122.732 14.8751 123.936 14.8751C129.52 14.8751 134.009 19.3595 134.009 24.9376V38.172C134.009 40.0314 132.476 41.5626 130.615 41.5626C128.754 41.5626 127.221 40.0314 127.221 38.172V24.9376C127.221 23.0782 125.688 21.547 123.827 21.547C121.966 21.547 120.433 23.0782 120.433 24.9376V41.1251H113.864Z" fill="#275576" />
        <path d="M146.162 34.4531V41.125H139.483V34.4531V17.0625C139.483 15.2031 141.016 13.6719 142.877 13.6719C144.738 13.6719 146.271 15.2031 146.271 17.0625L146.162 34.4531Z" fill="#275576" />
        <path d="M151.855 41.125V14.5469L156.015 16.9531C156.782 16.2969 159.081 14.9844 161.927 14.9844C167.511 14.9844 172 19.4687 172 25.0469V38.2812C172 40.1406 170.467 41.6719 168.606 41.6719C166.745 41.6719 165.212 40.1406 165.212 38.2812V24.9375C165.212 23.0781 163.679 21.5469 161.818 21.5469C159.957 21.5469 158.424 23.0781 158.424 24.9375V41.125H151.855Z" fill="#275576" />
        <path d="M142.658 11.4844C145.077 11.4844 147.038 9.52562 147.038 7.10938C147.038 4.69313 145.077 2.73438 142.658 2.73438C140.24 2.73438 138.279 4.69313 138.279 7.10938C138.279 9.52562 140.24 11.4844 142.658 11.4844Z" fill="#275576" />
        <path d="M23.1013 68.1306V77.2147H21.5685V68.1306H19.1599V66.6726H25.4005V68.1306H23.1013Z" fill="#275576" />
        <path d="M31.7505 72.7287L34.9256 77.2147H33.0643L30.1082 72.953H29.7798V77.2147H28.247V66.6726H30.1082C31.4221 66.6726 32.4074 66.8969 33.0643 67.4577C33.7212 68.0184 34.0497 68.8035 34.0497 69.8128C34.0497 70.5978 33.8307 71.1586 33.3928 71.7193C32.9548 72.2801 32.4074 72.6165 31.7505 72.7287ZM29.7798 71.495H30.3272C31.7505 71.495 32.5169 70.9343 32.5169 69.8128C32.5169 68.6913 31.7505 68.2427 30.3272 68.2427H29.7798V71.495Z" fill="#275576" />
        <path d="M43.9034 74.6353H39.524L38.4291 77.2147H36.7869L41.8232 65.9998L46.7499 77.2147H44.9982L43.9034 74.6353ZM43.2464 73.1773L41.7137 69.5885L40.0714 73.1773H43.2464Z" fill="#275576" />
        <path d="M50.9103 66.6726V77.2147H49.268V66.6726H50.9103Z" fill="#275576" />
        <path d="M54.7423 77.2147V65.9998L62.2967 74.0745V66.7848H63.8295V77.9998L56.2751 69.8128V77.2147H54.7423Z" fill="#275576" />
        <path d="M75.9823 68.1306V77.2147H74.4495V68.1306H72.0408V66.6726H78.2814V68.1306H75.9823Z" fill="#275576" />
        <path d="M82.6608 70.8221H87.1496V66.6726H88.6824V77.2147H87.1496V72.2801H82.6608V77.2147H81.128V66.6726H82.6608V70.8221Z" fill="#275576" />
        <path d="M98.0982 68.1306H93.9378V70.7101H97.9887V72.168H93.9378V75.6447H98.0982V77.1026H92.405V66.5605H98.0982V68.1306Z" fill="#275576" />
        <path d="M107.076 77.2147V66.6726H108.609C109.266 66.6726 109.813 66.6726 110.251 66.7848C110.689 66.8969 111.017 67.1212 111.346 67.3455C111.674 67.5698 111.893 67.9063 112.003 68.3548C112.222 68.8034 112.222 69.1399 112.222 69.5885C112.222 70.3735 111.893 70.9343 111.346 71.495C111.893 71.7193 112.331 72.0558 112.66 72.5044C112.988 72.953 113.098 73.5137 113.098 74.1866C113.098 74.9717 112.769 75.7567 112.222 76.3175C111.893 76.6539 111.455 76.8782 111.017 77.1025C110.579 77.2147 109.922 77.3268 109.266 77.3268H107.076V77.2147ZM108.718 71.1586H109.156C109.703 71.1586 110.141 71.0464 110.47 70.8221C110.689 70.5978 110.908 70.1492 110.908 69.7006C110.908 69.2521 110.798 68.8035 110.47 68.5792C110.141 68.3549 109.813 68.2427 109.266 68.2427H108.718V71.1586ZM108.718 75.7567H109.703C110.36 75.7567 110.908 75.6446 111.236 75.3081C111.565 74.9717 111.784 74.6352 111.784 74.1866C111.784 73.738 111.565 73.4016 111.236 73.0651C110.908 72.7287 110.36 72.6165 109.485 72.6165H108.718V75.7567Z" fill="#275576" />
        <path d="M119.995 72.7287L123.17 77.2147H121.309L118.353 72.953H118.024V77.2147H116.492V66.6726H118.353C119.667 66.6726 120.652 66.8969 121.309 67.4577C121.966 68.0184 122.294 68.8035 122.294 69.8128C122.294 70.5978 122.075 71.1586 121.637 71.7193C121.309 72.2801 120.761 72.6165 119.995 72.7287ZM118.134 71.495H118.681C120.105 71.495 120.871 70.9343 120.871 69.8128C120.871 68.6913 120.105 68.2427 118.681 68.2427H118.134V71.495Z" fill="#275576" />
        <path d="M132.148 74.6353H127.768L126.674 77.2147H125.031L130.068 65.9998L134.994 77.2147H133.243L132.148 74.6353ZM131.491 73.1773L129.958 69.5885L128.316 73.1773H131.491Z" fill="#275576" />
        <path d="M139.155 66.6726V77.2147H137.622V66.6726H139.155Z" fill="#275576" />
        <path d="M142.987 77.2147V65.9998L150.541 74.0745V66.7848H152.074V77.9998L144.519 69.8128V77.2147H142.987Z" fill="#275576" />
    </svg>
);

export default LogoSub;