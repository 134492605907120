// 个人中心

import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import UploadFiles from '../Public/UploadFiles';
interface UploadProfilePictureProps {
    visible?: boolean;
    onClose: () => void;
    onChange: (path: string) => void;
    id: number | string
}

const UploadProfilePicture: React.FC<UploadProfilePictureProps> = ({ visible = false, onClose, id, onChange }) => {
    const [uploadPicturePath, setUploadPicturePath] = useState<any>('');//头像路径
    // 关闭弹框
    const handleClose = () => {
        onClose();
        setUploadPicturePath('')
    };
    // 头像文件上传成功并获取到 signedUrl MP4视频地址地址 
    const handleFileUploadSuccess = (url: string) => {
        setUploadPicturePath(url)
    };
    // 保存
    const saveHandle = () => {
        onChange(uploadPicturePath)
        handleClose();

    }
    return (
        <Modal
            title="Change your profile picture"
            width={600}
            centered
            open={visible}
            onOk={saveHandle}
            onCancel={handleClose}
            footer={null}
        >
            <div className=' space-y-5'>
                {!uploadPicturePath &&
                    <div className='w-full h-40'>
                        <UploadFiles onFileUploadSuccess={handleFileUploadSuccess} identification={false} keyPrefix={`UploadProfilePicture/${id}`}>
                            <div className='h-full flex flex-col items-center justify-center'>
                                <img src={require(`../../assets/images/public/upload-01.png`)} alt='upload' />
                                <p className=" text-base font-normal text-[#275576]">Choose file to upload</p>
                            </div>
                        </UploadFiles>
                    </div>
                }
                {uploadPicturePath &&
                    <div className='w-44 h-44 mx-auto  relative flex items-center justify-center'>
                        <div className='absolute top-6 -right-4 w-10 h-10 bg-white rounded-full text-center leading-10 text-lg cursor-pointer'
                            onClick={() => setUploadPicturePath('')}
                        >
                            <CloseOutlined />
                        </div>
                        <div className='w-full h-full bg-[#F1F1F1] rounded-full overflow-hidden'>
                            <img className='w-full h-full   object-cover' src={uploadPicturePath} alt='imgSrc' />
                        </div>

                    </div>
                }
                <div className='w--full flex items-center justify-between space-x-5'>
                    <Button shape="round"
                        style={{ background: '#F1F1F1', borderColor: '#F1F1F1' }}
                        className='w-1/2 h-11  px-4 space-x-2  flex items-center justify-center  '
                        onClick={handleClose}
                    >
                        <img src={require('../../assets/images/public/close.png')} alt='close' />
                        <span className='text-base font-medium text-[#275576] '> Cancel</span>
                    </Button>
                    <Button shape="round"
                        style={{ background: '#275576', borderColor: '#275576' }}
                        className='w-1/2 h-11  px-4 flex items-center justify-center space-x-2 '
                        htmlType="submit"
                        onClick={saveHandle}
                    >
                        <img src={require('../../assets/images/public/send-01.png')} alt='send-02' />
                        <span className=' text-base font-medium text-white '> Save</span>
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default UploadProfilePicture;
